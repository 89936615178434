import { Spin } from "antd"
import { useState } from "react"

const InvestorTable = ({ dt, index, invitationHandler, singleSelectedHandler }) => {
    const [submit, setSubmit] = useState(false)
    const submitHandler = (name, email) => {
        setSubmit(true)
        invitationHandler(name, email)
        setTimeout(() => { setSubmit(false) }, 2000);
    }
    return (
        <tr>
            <td className="text-center">
                {dt.invite_type=='invite' &&
                <div class="form-check">
                {dt.isCheck ?
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckIndeterminate" checked onChange={(e)=>singleSelectedHandler(e, dt.email)}/>:
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckIndeterminate" onChange={(e)=>singleSelectedHandler(e, dt.email)}/>}
                </div>}
            </td>
            <td>{index + 1}</td>
            <td className="text-center">{dt.investor_name}</td>
            <td className="text-center">{dt.phone_number}</td>
            <td className="text-center">{dt.email}</td>
            <td className="text-center">{dt.address}</td>
            <td className="text-center">{dt.country}</td>
            <td className="text-center">
                {submit ? <button className="invite-btn text-capitalize text-mute">inviting..</button> :
                    dt.invite_type == "invited" ?
                        <button className="invite-btn bg-success text-capitalize">invited</button> :
                        <button className="invite-btn text-capitalize" onClick={() => submitHandler(dt.investor_name, dt.email)}>Invite</button>}
            </td>
        </tr>
    )
}

export default InvestorTable