// This is PublicUserContext For Public Url Page

import React, { useState } from 'react'
import PublicUserContext from './PublicUserContext'
import PublicUrlPage from './NewPublicUrlPage';

function PublicUrlMain() {
    const [dealUser, setDealUser] = useState('');
    const [dealIid,set_DealId] = useState('')

  return (
    <PublicUserContext.Provider value={{ dealUser, setDealUser,dealIid,set_DealId }}>
      <PublicUrlPage />
    </PublicUserContext.Provider>
  )
}

export default PublicUrlMain