import { fontSize } from "@mui/system";
import DoughnutChart from "./DoughnutChart";
import DemoPie from "./PieChart";
import MainClient from "../../utilities/MainClient";
import { useEffect, useState } from "react";
import numberWithCommas from "../../utilities/extrafunction/commaseparate";
import { capitalize } from "@mui/material";
import styled from "styled-components";

const Text = styled.div`
  color: #767676;
  font-size: 14px; 
  @media(min-width: 1200px) and (max-width: 1270px){
  font-size: 12px; 
  font-weigth:bold;
  }
`

const DashboardHeader = () => {
  const [dashboardData, setDashboardData] = useState([]);

  useEffect(() => {
    const getData = () => {
      const fetchClient = new MainClient("/investor/dashboard");
      const response = fetchClient.getAPI();
      response
        .then((res) => {
          console.log("dashboard", res.data.result);
          setDashboardData(res.data.result);
        })
        .catch((err) => console.log(err));
    };
    getData();
  }, []);
  const iconcolor = ['#304C89', '#A9AFD1', '#ADA9B7', '#C0BDBE', '#7a7d7d', '#4b5354', '#383c3d', '#000000'];
  const iconcolor2 = ['#A9AFD1', '#ADA9B7', '#C0BDBE'];

  const graphdataTotal = dashboardData?.subscribed_deal?.length > 0 ? dashboardData?.subscribed_deal?.map(dt => dt.amount_subscribed)?.reduce((a, b) => a + b) : 0
  const graphdata = dashboardData?.subscribed_deal?.map(dt => ((dt.amount_subscribed * 100) / graphdataTotal).toFixed(2))
  const graphLable = dashboardData?.subscribed_deal?.map(dt => (dt.deal_name).slice(0, 13))
  console.log("total deals", dashboardData)
  const classLable = dashboardData?.class_allocation?.map(dt => capitalize(dt.class_name))
  const classTotal = dashboardData?.class_allocation?.map(dt => dt.amount).reduce((a, b) => a + b)
  const classD = dashboardData?.class_allocation?.map(dt => ((dt.amount * 100) / classTotal).toFixed(2))
  const data = {
    labels: graphLable,
    datasets: [
      {
        label: "Attendance for Week 1",
        data: graphdata,
        borderColor: ["#fff"],
        backgroundColor: ['#304C89', '#A9AFD1', '#ADA9B7', '#C0BDBE', '#7a7d7d', '#4b5354', '#383c3d', '#000000'],
        pointBackgroundColor: "rgba(255,206,86,0.2)",
        centerText: {
          display: true,
          text: "280",
        },
      },
    ],
  };
  const classData = {
    labels: classLable,
    datasets: [
      {
        label: "Attendance for Week 1",
        data: classD,
        borderColor: ["#fff"],
        backgroundColor: ["#A9AFD1", "#ADA9B7", "#C0BDBE"],
        pointBackgroundColor: "rgba(255,206,86,0.2)",
      },
    ],
  };
  return (
    <div
      className="card mb-3"
      style={{ background: "#FBFAFB", borderRadius: "15px" }}
    >
      <div className="row fw-6 fs-6 m-2">
        <div
          class="alert"
          role="alert"
          style={{
            color: "#304C89",
            fontWeight: 500,
            fontSize: "18px",
            lineHeight: "27px",
            color: "#304C89",
          }}
        >
          Welcome {dashboardData?.user_name}
        </div>
      </div>
      <div
        className="row p-4 fw-5 d-flex justify-content-around align-items-stretch flex-wrap"
        style={{ marginTop: "-40px" }}
      >
        <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12  p-3 ">
          <div
            style={{
              borderRadius: "5px",
              boxShadow:
                "-2px -2px 10px rgba(0, 0, 0, 0.15), 2px 2px 10px rgba(0, 0, 0, 0.15)",
            }}
            className="p-3 m-0 matrix-card"
          >
            <div
              className="text-center fw-6 mb-2 mt-1"
              style={{ fontSize: "18px" }}
            >
              $ {dashboardData?.total_subscription ? numberWithCommas(dashboardData?.total_subscription) : 0}
            </div>
            <div
              className="text-center fw-6 mb-1 mt-1 "
              style={{ color: "#767676", fontSize: "15px" }}
            >
              Total Subscriptions
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-8 col-md-12 col-sm-12  p-3 ">
          <div
            style={{
              borderRadius: "5px",
              boxShadow:
                "-2px -2px 10px rgba(0, 0, 0, 0.15), 2px 2px 10px rgba(0, 0, 0, 0.15)",
            }}
            className="p-3 pt-2 m-0 matrix-card"
          >
            <Text
              className="row mb-2"
            >
              <div className="col-5">Offerings</div>
              <div className="col-6 text-end" style={{ marginLeft: "1rem" }}>
                Equity Shares
              </div>
            </Text>
            <div
              style={{
                overflow: "auto",
                height: "46px",
                overflowX: "hidden",
                scrollbarWidth: "thin",
              }}
            >
              {dashboardData.offerings?.map((dt) => (
                <div className="row mt-1">
                  <div
                    className="col-6"
                    style={{ color: "#767676", fontSize: "12px" }}
                  >
                    {dt.deal_name}
                  </div>
                  <div
                    className="col-5 text-end"
                    style={{ color: "#000000", fontSize: "12px" }}
                  >
                    $ {dt.equity_shares ? numberWithCommas(dt.equity_shares) : 0}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="col-xl-5 col-lg-12 col-md-12 col-sm-12  p-3 ">
          <div
            style={{
              borderRadius: "5px",
              boxShadow:
                "-2px -2px 10px rgba(0, 0, 0, 0.15), 2px 2px 10px rgba(0, 0, 0, 0.15)",
            }}
            className="p-3 pt-2 m-0 matrix-card"
          >
            <Text
              className="row mb-2"
            >
              <div className="col-6">Pending Subscriptions</div>
              <div className="col-6 text-end">Subscription Request</div>
            </Text>
            <div
              style={{
                overflow: "auto",
                height: "46px",
                overflowX: "hidden",
                scrollbarWidth: "thin",
              }}
            >
              {dashboardData.pending_subscription?.map((dt) => (
                <div className="row mt-1">
                  <div
                    className="col-6"
                    style={{ color: "#767676", fontSize: "12px" }}
                  >
                    {dt.deal_name}
                  </div>
                  <div
                    className="col-5 text-end"
                    style={{ color: "#000000", fontSize: "12px" }}
                  >
                    $ {dt.subscription_request ? numberWithCommas(dt.subscription_request) : ""}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {dashboardData?.subscribed_deal?.length > 0 &&
      <div
        className="row p-4 fw-5 d-flex justify-content-around align-items-center flex-wrap"
        style={{ marginTop: "-50px" }}
      >
        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 p-3 ">
          <div
            style={{
              borderRadius: "5px",
              boxShadow:
                "-2px -2px 10px rgba(0, 0, 0, 0.15), 2px 2px 10px rgba(0, 0, 0, 0.15)",
            }}
            className="p-3 m-0 matrix-card"
          >
            <div style={{ marginTop: "-15px" }} className="row">
              <div className="col-lg-5 col-sm-12 p-2 m-1">
                <div className="mt-0" style={{}}>
                  <div
                    className="text-start fw-6 mb-3 mt-0"
                    style={{ color: "#767676", fontSize: "18px" }}
                  >
                    Deals Subscribed
                  </div>
                  <div className="d-flex justify-content-center p-1">
                    <div style={{ width: "150px", textAlign: "center" }}>
                      {dashboardData?.total_deals > 0 ?
                        <DoughnutChart data={data} datalength={dashboardData?.total_deals} /> : <div style={{ margin: "9.2rem" }}></div>}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 col-sm-12 p-lg-0 p-sm-3"
                style={{ marginTop: "4.2rem" }}
              >
                <div
                  className=""
                  style={{ fontSize: "11px", color: "#767676" }}
                >
                  {dashboardData.subscribed_deal?.map((dt, index) => (
                    <div className="row mb-1 p-0">
                      <div className="col-1 ">
                        <div
                          style={{
                            background: iconcolor[index],
                            width: ".5rem",
                            height: ".5rem",
                            borderRadius: "50%",
                            marginRight: "5px",
                            fontSize: "12px",
                            marginTop:".3rem"
                          }}
                        ></div>
                      </div>
                      <div className="col-5 text-start p-0">
                          {dt.deal_name}
                      </div>
                      <div className="col-5 text-end p-0">
                        $ {dt.amount_subscribed ? numberWithCommas(dt.amount_subscribed) : ""}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12  p-3 ">
          <div
            style={{
              borderRadius: "5px",
              boxShadow:
                "-2px -2px 10px rgba(0, 0, 0, 0.15), 2px 2px 10px rgba(0, 0, 0, 0.15)",
            }}
            className="p-3 m-0 matrix-card"
          >
            <div style={{ marginTop: "-15px" }} className="row">
              <div className="col-lg-5 col-sm-12 p-2 m-2 mt-1 mb-1">
                {/* <div className="mt-3 pb-1 p-0" style={{ marginLeft: '5px' }}> */}
                <div className="">
                  <div
                    className="text-start fw-6 mb-4 mt-0"
                    style={{ color: "#767676", fontSize: "18px" }}
                  >
                    Class Allocations
                  </div>
                  <div className="d-flex justify-content-center mt-2">
                    <div style={{ width: "150px", textAlign: "center" }}>
                      <DemoPie data={classData} />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 col-sm-12 p-3"
                style={{ marginTop: "4.5rem" }}
              >
                <div
                  className="mt-1"
                  style={{ fontSize: "11px", color: "#767676" }}
                >
                  {dashboardData.class_allocation?.map((dt, index) => (
                    <div className="row">
                      <div className="col">
                        <div className="d-flex align-items-center justify-content-start">
                          <div
                            style={{
                              background: iconcolor2[index],
                              width: ".5rem",
                              height: ".5rem",
                              borderRadius: "50%",
                              marginRight: "5px",
                            }}
                          ></div>
                          <div className="text-capitalize">{dt.class_name}</div>
                        </div>
                      </div>
                      <div className="col text-end">$ {dt.amount ? numberWithCommas(dt.amount) : 0}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>}
    </div>
  );
};

export default DashboardHeader;
