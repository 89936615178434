const ResultTable = ({ data }) => {
    return (
        <div>
                {/* {data.tier && <div className="pb-2 fs-14 mt-1">{data.tier}</div>} */}
                <div className="table-responsive">
                    <table className="table data-table align-middle table-borderless sponser-index-table"
                        id="sponser-table">
                        <thead className="table-white border-bottom border-top" style={{ 'fontSize': '14px', 'fontWeight': '400' }}>
                            <tr>
                                <th className="col-1 align-middle">Classes</th>
                                <th className="col-1 text-center">{data?.prefered_percentage_class_A ? "Pref%" : data.tier=="Initial Equity %" || data.tier=="Profit Share %" ? (data.tier==="Initial Equity %" ? "Equity %" : "Share %")  : ''} </th>
                                <th className="col-1 align-middle"></th>
                                {data.class_A_year}
                                <th className="col-1 text-center">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="col-1">Class A</td> 
                                {data.class_A_equity ?
                                <td className="col-1 text-center">{data.class_A_equity} %</td>: 
                                <td className="col-1 text-center">{data?.prefered_percentage_class_A ? data.prefered_percentage_class_A + "%" : ''}</td>}
                                <td className="col-1"></td>
                                {/* {data.tier==='% Capital Outstanding' ? data.class_A+'%': data.class_A} */}
                                {data.class_A}
                                {data.class_A_Total ? <td className="col-1 text-center">{data.class_A_Total}</td>: <td className="col-1"></td>}
                            </tr>
                            {data.class_B.length > 0 &&
                            <tr>
                                <td  className="col-1">Class B</td>
                                {data.class_B_equity ?
                                <td className="col-1 text-center">{data.class_B_equity} %</td>: 
                                <td className="col-1 text-center">{data?.prefered_percentage_class_B ? data.prefered_percentage_class_B + "%" : ''}</td>}
                                <td className="col-1"></td>
                                {data.class_B}
                                {data.class_B_Total ? <td className="col-1 text-center">{data.class_B_Total}</td>: <td className="col-1"></td>}
                            </tr>}
                            {data.class_C.length > 0 &&
                            <tr>
                                <td  className="col-1">Class C</td>
                                {data.class_C_equity ?
                                <td className="col-1 text-center">{data.class_C_equity} %</td>: 
                                <td className="col-1 text-center">{data?.prefered_percentage_class_C ? data.prefered_percentage_class_C + "%" : ''}</td>}
                                <td className="col-1"></td>
                                {data.class_C}
                                {data.class_C_Total ? <td className="col-1 text-center">{data.class_C_Total}</td>: <td className="col-1"></td>}
                            </tr>}
                            {data.sponsor ? data.sponsor.length > 0 &&
                            <tr>
                                <td className="col-1">Sponsor</td>
                                {data.sponsor_profit_share ? 
                                <td className="col-1 text-center">{data.sponsor_profit_share} %</td> :<td className="col-1"></td>}
                                
                                <td className="col-1"></td>
                                {data.sponsor}
                                <td className="col-1 text-center">{data.sponsorTotal}</td>
                            </tr>: ''}
                            <tr className="border-top">
                                <td  className="col-1 fw-6">Total</td>
                                {data.totalPref ? 
                                <td className="col-1 text-center">{data.totalPref} %</td> :<td className="col-1"></td>}
                                <td className="col-1"></td>
                                {data.total}
                                <td className="col-1 text-center">{data.tTotal}</td>
                            </tr>
                            {data.rem &&
                            <tr className="border">
                                <td className="col-1 fw-5">Remaining</td>
                                <td className="col-1"></td>
                                <td className="col-1"></td>
                                {data.rem}
                                <td className="col-1 text-center">{data.remTotal}</td>
                            </tr>}
                        </tbody>
                    </table>
                </div>
            </div>
    )
}

export default ResultTable
