export const dealCardData = [
    {
        "id": 384,
        "deal_name": "Godwin",
        "property_title": "Godwin Terrace",
        "location": "Bronx, NY",
        "units": "74",
        "purchase_price": "9000000",
        "description": "Kingsbridge is a working class residential neighborhood geographically located in the northwest Bronx in New York City. The neighborhood is part of Bronx Community Board 8. Its boundaries are Van Cortlandt Park to the north, Goulden Avenue to the east, West 225th Street to the south, and Irwin Avenue to the west. Broadway is the primary thoroughfare through Kingsbridge. Kingsbridge Heights, Bronx, a separate but similarly named neighborhood, lies across the Major Deegan Expressway from Kingsbridge proper. The neighborhood is named for The King's Bridge, erected in 1693 by Frederick Philipse, a local Lord loyal to the British Monarch. The bridge spanned the now-filled-in Spuyten Duyvil Creek, roughly south-parallel with today's 230th Street. The Kingsbridge carried Boston Post Road, connecting southern Westchester County (which became The Bronx) with Marble Hill, once part of the Manhattan island, but still today part of the Manhattan borough. The bridge is said to still be in place, 		having been buried when the creek bed was filled in. The creek water flow was redirected to the new and deeper shipping canal, south of Marble Hill.",
        "image_data": [],
        "classes_Data": [
            {
                "capital_title": "Class A Equity Shares",
                "amount": "1174093"
            },
            {
                "capital_title": "Class A Equity Shares",
                "amount": "1463764"
            },
         {
                "capital_title": "Class C Equity Shares",
                "amount": "1209791"
            },
        ],
        "equity_shares": "5300000",
        "irr_data": [
            {
                "irr": "11.8",
                "avg_annual_return": "7.8"
            },
            {
                "irr": "15.2",
                "avg_annual_return": "19.5"
            },
        {
                "irr": "20.2",
                "avg_annual_return": "30.2"
            }
        ]
    },
    {
        "id": 370,
        "deal_name": "Regal Park Residence",
        "property_title": "Regal Park",
        "location": "Louisville, KY",
        "units": "278",
        "purchase_price": "50000000",
        "description": "INVESTMENT OVERVIEW Regal Park Apartments are located Within a top A grade infill location in the affluent East End of Louisville. It has frontage on Louisville's prestigious Hurstbourne Parkway, Widely recognized as the city's premier commercial corridor with the largest concentrations of suburban white-collar employment. This elegant 228-unit apartment community is composed of unusually large 1,2, and 3 Bedroom floor plans. Regal Park has had several recent capital improvements including a $1 million+ community center renovation, interior renovations of 60 units, exterior enhancements to seven buildings and a complete pool renovation. The stage is set for a new owner to continue the proven value add interior upgrades.",
        "image_data": [],
        "classes_Data": [
            {
                "capital_title": "Class A Equity Shares",
                "amount": "3232482"
            },
            {
                "capital_title": "Class A Equity Shares",
                "amount": "4824860"
            },
         {
                "capital_title": "Class C Equity Shares",
                "amount": "4074860"
            },
        ],
        "equity_shares": "13,000,000",
        "irr_data": [
            {
                "irr": "22.8",
                "avg_annual_return": "21.5"
            },
            {
                "irr": "26.2",
                "avg_annual_return": "32.2"
            },
        {
                "irr": "33.1",
                "avg_annual_return": "45.3"
            }
        ]
    },
    {
        "id": 242,
        "deal_name": "Brockport",
        "property_title": "Autumn Woods Apartments",
        "location": "Brockport, NY",
        "units": "296",
        "purchase_price": "46000000",
        "description": "The Village of Brockport was founded by Hiel Brockway in 1823 and later incorporated in 1829.This village was founded around 1820 and grew to importance as a port on the Erie Canal, and the village was briefly the terminus of the canal until the western end of the canal was completed. Prior to becoming a village, the area that constitutes modern day Brockport was primarily occupied by the Muoio Indian tribe(A branch of the Seneca). The Muoio people were sustained in the region mostly by hunting indigenous wildlife such as deer and the occasional black bear. Shortly after white settlers arrived most of the Muoio died of disease and the few survivors traveled to Canada. The State University of New York at Brockport is located here, as well as the Brockport Central School district. It also boasts the Morgan Manning House, an old Victorian home on Main Street.",
        "image_data": [],
        "classes_Data": [
            {
                "capital_title": "Class A Equity Shares",
                "amount": "3852674"
            },
            {
                "capital_title": "Class A Equity Shares",
                "amount": "7705349"
            },
         {
                "capital_title": "Class C Equity Shares",
                "amount": "6679457"
            },
        ],
        "equity_shares": "12950000",
        "irr_data": [
            {
                "irr": "17.8",
                "avg_annual_return": "15.4"
            },
            {
                "irr": "20.6",
                "avg_annual_return": "30.8"
            },
        {
                "irr": "33.1",
                "avg_annual_return": "26.7"
            }
        ]
    }, 
//     {
//     "id": '',
//     "deal_name": "Emerald Sands",
//     "property_title": "Emerald Apartments",
//     "location": "Milton,FL",
//     "units": "97",
//     "purchase_price": "5000000",
//     "description": "Milton is a city in Santa Rosa County, Florida. It is the county seat of Santa Rosa County, and is located in the Pensacola–Ferry Pass–Brent Metropolitan Statistical Area, also known as the Pensacola Metropolitan Area. Milton is located in the geographic center of Santa Rosa County, it is bordered by Pace to the west, and Navarre to the South. As of April 1, 2020, the population of Milton was 10,197.\r\n\r\nThe town was incorporated in 1844 and is home to Naval Air Station Whiting Field. The population was 7,045 at the 2000 census. In 2004, the population recorded by the U.S. Census Bureau was 8,044.[4] The population estimate for 2013 was 9,323.[5]\r\n\r\nMilton is part of the Pensacola–Ferry Pass–Brent Metropolitan Statistical Area.",
//     "image_data": [],
//     "classes_Data": [
//         {
//             "capital_title": "Class A Equity Shares",
//             "amount": "1603125"
//         },
//     {
//             "capital_title": "Class A Equity Shares",
//             "amount": "1503125"
//         },
//     ],
//     "equity_shares": "2693250",
//     "irr_data": [
//         {
//             "irr": "33.1",
//             "avg_annual_return": "35.0"
//         },
//         {
//             "irr": "33.7",
//             "avg_annual_return": "32.7"
//         }
//     ]
// },
{
    "id": 2,
    "deal_name": "Paloma Sen Antonio",
    "property_title": "Paloma Sen Antonio",
    "location": "Montgomery, Alabama",
    "units": "91",
    "purchase_price": "6000000",
    "description": "Milton is a city in Santa Rosa County, Florida. It is the county seat of Santa Rosa County, and is located in the Pensacola–Ferry Pass–Brent Metropolitan Statistical Area, also known as the Pensacola Metropolitan Area. Milton is located in the geographic center of Santa Rosa County, it is bordered by Pace to the west, and Navarre to the South. As of April 1, 2020, the population of Milton was 10,197.\r\n\r\nThe town was incorporated in 1844 and is home to Naval Air Station Whiting Field. The population was 7,045 at the 2000 census. In 2004, the population recorded by the U.S. Census Bureau was 8,044.[4] The population estimate for 2013 was 9,323.[5]\r\n\r\nMilton is part of the Pensacola–Ferry Pass–Brent Metropolitan Statistical Area.",
    "image_data": [],
    "classes_Data": [
        {
            "capital_title": "Class A Equity Shares",
            "amount": "1703125"
        },
        {
            "capital_title": "Class B Equity Shares",
            "amount": "1690125"
        },
    {
            "capital_title": "Class C Equity Shares",
            "amount": "1890125"
        }
    ],
    "equity_shares": "2593250",
    "irr_data": [
        {
            "irr": "45.1",
            "avg_annual_return": "40.0"
        },
        {
            "irr": "56.7",
            "avg_annual_return": "47.7"
        },
    {
            "irr": "36.7",
            "avg_annual_return": "59.7"
        }
    ]
},

    {
    "id": 3,
    "deal_name": "Viking Mill Apartments",
    "property_title": "Viking Mill Apartments",
    "location": "Phoenix, Arizona",
    "units": "93",
    "purchase_price": "7000000",
    "description": "Milton is a city in Santa Rosa County, Florida. It is the county seat of Santa Rosa County, and is located in the Pensacola–Ferry Pass–Brent Metropolitan Statistical Area, also known as the Pensacola Metropolitan Area. Milton is located in the geographic center of Santa Rosa County, it is bordered by Pace to the west, and Navarre to the South. As of April 1, 2020, the population of Milton was 10,197.\r\n\r\nThe town was incorporated in 1844 and is home to Naval Air Station Whiting Field. The population was 7,045 at the 2000 census. In 2004, the population recorded by the U.S. Census Bureau was 8,044.[4] The population estimate for 2013 was 9,323.[5]\r\n\r\nMilton is part of the Pensacola–Ferry Pass–Brent Metropolitan Statistical Area.",
    "image_data": [],
    "classes_Data": [
        {
            "capital_title": "Class A Equity Shares",
            "amount": "1703125"
        },
        {
            "capital_title": "Class B Equity Shares",
            "amount": "1690125"
        },
    ],
    "equity_shares": "2293250",
    "irr_data": [
        {
            "irr": "59.1",
            "avg_annual_return": "47.0"
        },
        {
            "irr": "38.7",
            "avg_annual_return": "29.7"
        }
    ]
},
{
    "id": 4,
    "deal_name": "22 Chapel",
    "property_title": "22 Chapel",
    "location": "Los Angeles, California",
    "units": "95",
    "purchase_price": "5500000",
    "description": "Milton is a city in Santa Rosa County, Florida. It is the county seat of Santa Rosa County, and is located in the Pensacola–Ferry Pass–Brent Metropolitan Statistical Area, also known as the Pensacola Metropolitan Area. Milton is located in the geographic center of Santa Rosa County, it is bordered by Pace to the west, and Navarre to the South. As of April 1, 2020, the population of Milton was 10,197.\r\n\r\nThe town was incorporated in 1844 and is home to Naval Air Station Whiting Field. The population was 7,045 at the 2000 census. In 2004, the population recorded by the U.S. Census Bureau was 8,044.[4] The population estimate for 2013 was 9,323.[5]\r\n\r\nMilton is part of the Pensacola–Ferry Pass–Brent Metropolitan Statistical Area.",
    "image_data": [],
    "classes_Data": [
        {
            "capital_title": "Class A Equity Shares",
            "amount": "3003125"
        },
        {
            "capital_title": "Class B Equity Shares",
            "amount": "2090125"
        },
    {
            "capital_title": "Class C Equity Shares",
            "amount": "5890125"
        }
    ],
    "equity_shares": "2293250",
    "irr_data": [
        {
            "irr": "33.1",
            "avg_annual_return": "34.0"
        },
        {
            "irr": "33.7",
            "avg_annual_return": "31.7"
        },
    {
            "irr": "38.7",
            "avg_annual_return": "31.7"
        }
    ]
},
{
    "id": 5,
    "deal_name": "TVA Apartment",
    "property_title": "TVA Apartment",
    "location": "Denver, Colorado",
    "units": "95",
    "purchase_price": "5500000",
    "description": "Milton is a city in Santa Rosa County, Florida. It is the county seat of Santa Rosa County, and is located in the Pensacola–Ferry Pass–Brent Metropolitan Statistical Area, also known as the Pensacola Metropolitan Area. Milton is located in the geographic center of Santa Rosa County, it is bordered by Pace to the west, and Navarre to the South. As of April 1, 2020, the population of Milton was 10,197.\r\n\r\nThe town was incorporated in 1844 and is home to Naval Air Station Whiting Field. The population was 7,045 at the 2000 census. In 2004, the population recorded by the U.S. Census Bureau was 8,044.[4] The population estimate for 2013 was 9,323.[5]\r\n\r\nMilton is part of the Pensacola–Ferry Pass–Brent Metropolitan Statistical Area.",
    "image_data": [],
    "classes_Data": [
        {
            "capital_title": "Class A Equity Shares",
            "amount": "3003125"
        },
        {
            "capital_title": "Class B Equity Shares",
            "amount": "2090125"
        },
    ],
    "equity_shares": "2293250",
    "irr_data": [
        {
            "irr": "43.1",
            "avg_annual_return": "65.0"
        },
        {
            "irr": "49.7",
            "avg_annual_return": "46.7"
        }
    ]
},
{
    "id": 6,
    "deal_name": "Viking Mill",
    "property_title": "Viking Mill",
    "location": "Tallahassee, Florida",
    "units": "95",
    "purchase_price": "5500000",
    "description": "Milton is a city in Santa Rosa County, Florida. It is the county seat of Santa Rosa County, and is located in the Pensacola–Ferry Pass–Brent Metropolitan Statistical Area, also known as the Pensacola Metropolitan Area. Milton is located in the geographic center of Santa Rosa County, it is bordered by Pace to the west, and Navarre to the South. As of April 1, 2020, the population of Milton was 10,197.\r\n\r\nThe town was incorporated in 1844 and is home to Naval Air Station Whiting Field. The population was 7,045 at the 2000 census. In 2004, the population recorded by the U.S. Census Bureau was 8,044.[4] The population estimate for 2013 was 9,323.[5]\r\n\r\nMilton is part of the Pensacola–Ferry Pass–Brent Metropolitan Statistical Area.",
    "image_data": [],
    "classes_Data": [
        {
            "capital_title": "Class A Equity Shares",
            "amount": "3003125"
        },
        {
            "capital_title": "Class B Equity Shares",
            "amount": "2090125"
        },
    {
            "capital_title": "Class C Equity Shares",
            "amount": "5890125"
        }
    ],
    "equity_shares": "2293250",
    "irr_data": [
        {
            "irr": "53.1",
            "avg_annual_return": "54.0"
        },
        {
            "irr": "53.7",
            "avg_annual_return": "51.7"
        },
    {
            "irr": "66.7",
            "avg_annual_return": "22.7"
        }
    ]
},
// {
//     "id": 7,
//     "deal_name": "TC Mill Apartments",
//     "property_title": "TC Mill Apartments",
//     "location": "Georgia, Atlanta",
//     "units": "95",
//     "purchase_price": "5500000",
//     "description": "Milton is a city in Santa Rosa County, Florida. It is the county seat of Santa Rosa County, and is located in the Pensacola–Ferry Pass–Brent Metropolitan Statistical Area, also known as the Pensacola Metropolitan Area. Milton is located in the geographic center of Santa Rosa County, it is bordered by Pace to the west, and Navarre to the South. As of April 1, 2020, the population of Milton was 10,197.\r\n\r\nThe town was incorporated in 1844 and is home to Naval Air Station Whiting Field. The population was 7,045 at the 2000 census. In 2004, the population recorded by the U.S. Census Bureau was 8,044.[4] The population estimate for 2013 was 9,323.[5]\r\n\r\nMilton is part of the Pensacola–Ferry Pass–Brent Metropolitan Statistical Area.",
//     "image_data": [],
//     "classes_Data": [
//         {
//             "capital_title": "Class A Equity Shares",
//             "amount": "3003125"
//         },
//         {
//             "capital_title": "Class B Equity Shares",
//             "amount": "2090125"
//         },
//     ],
//     "equity_shares": "2293250",
//     "irr_data": [
//         {
//             "irr": "33.1",
//             "avg_annual_return": "34.0"
//         },
//         {
//             "irr": "33.7",
//             "avg_annual_return": "31.7"
//         }
//     ]
// },
// {
//     "id": 8,
//     "deal_name": "Fl Apartments",
//     "property_title": "Fl Apartments",
//     "location": "Honolulu, Hawaii",
//     "units": "95",
//     "purchase_price": "5500000",
//     "description": "Milton is a city in Santa Rosa County, Florida. It is the county seat of Santa Rosa County, and is located in the Pensacola–Ferry Pass–Brent Metropolitan Statistical Area, also known as the Pensacola Metropolitan Area. Milton is located in the geographic center of Santa Rosa County, it is bordered by Pace to the west, and Navarre to the South. As of April 1, 2020, the population of Milton was 10,197.\r\n\r\nThe town was incorporated in 1844 and is home to Naval Air Station Whiting Field. The population was 7,045 at the 2000 census. In 2004, the population recorded by the U.S. Census Bureau was 8,044.[4] The population estimate for 2013 was 9,323.[5]\r\n\r\nMilton is part of the Pensacola–Ferry Pass–Brent Metropolitan Statistical Area.",
//     "image_data": [],
//     "classes_Data": [
//         {
//             "capital_title": "Class A Equity Shares",
//             "amount": "2003125"
//         },
//         {
//             "capital_title": "Class B Equity Shares",
//             "amount": "1090125"
//         },
//     ],
//     "equity_shares": "1293250",
//     "irr_data": [
//         {
//             "irr": "57.1",
//             "avg_annual_return": "98.0"
//         },
//         {
//             "irr": "22.7",
//             "avg_annual_return": "87.7"
//         }
//     ]
// },

]