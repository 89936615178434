import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Chart from './Chart';
import numberWithCommas from '../../utilities/extrafunction/commaseparate';
import { Link } from 'react-router-dom';
import { capitalize } from '@material-ui/core';


const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function RecipeReviewCard({ data, image, search, userType }) {
  const [expanded, setExpanded] = React.useState(false);
  const color = ['bg-primary', 'bg-success', 'bg-danger'];
  const classes = ['class A', 'class B', 'class C'];
  const barHeigth = ['5rem', '4rem', '3rem'];

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  console.log('searchData', data);
  const classesData = (
    search === 'Offerings' ? data?.classes_Data : data?.class_data
  )?.map((dt, index) => (
    <div style={{ marginRigth: '.5rem', textAlign: 'center' }}>
      <div
        className={color[index] + " colorbox"}
        style={{
          height: barHeigth[index],
          // width: '4rem',
          borderRadius: '.5rem .5rem .5rem',
          position: 'relative',
        }}
      >
        <div
          className='text-capitalize classtext'
        >
          {dt.class_name}{' '}
          {dt.capital_title ? dt.capital_title?.slice(0, 7) : ''}
        </div>
      </div>
    </div>
  ));
  const classesAmount = data?.classes_Data?.map((dt) => (
    <div style={{ marginRigth: '.5rem' }}>
      <div className='fw-6  mt-1 text-center class_amount'>
        ${numberWithCommas(dt.amount)}
      </div>
    </div>
  ));
  const classesIRR = data?.irr_data?.map((dt) => (
    <div style={{ marginRigth: '.5rem' }}>
      <div className='fw-6 mt-1 text-center class_amount'>
        {numberWithCommas(dt.irr)}%
      </div>
    </div>
  ));
  const classesAVG = data?.irr_data?.map((dt) => (
    <div style={{ marginRigth: '.5rem' }}>
      <div className='fw-6  mt-1 text-center class_amount'>
        {numberWithCommas(dt.avg_annual_return)}%
      </div>
    </div>
  ));

  const amountRequest = data?.class_data?.map((dt) => (
    <div style={{ marginRigth: '.5rem' }}>
      <div className='fw-6  mt-1 text-center'>
        ${numberWithCommas(dt.amount)}
      </div>
    </div>
  ));
  const amountAccepted = data?.class_data?.map((dt) => (
    <div style={{ marginRigth: '.5rem' }}>
      <div className='fw-6  mt-1 text-center'>
        ${numberWithCommas(dt.amount_accepted)}
      </div>
    </div>
  ));

  console.log("Images .......",image);
  return (
    <Card
      sx={{
        marginBottom: '2rem',
        cursor: 'pointer',
        minHeight: 550,
      }}
      className='card__hover'
    >
      {/* <CardHeader
                avatar={
                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                        R
                    </Avatar>
                }
                action={
                    <IconButton aria-label="settings">
                        <MoreVertIcon />
                    </IconButton>
                }
                title="Sponsor name"
                subheader="September 14, 2016"
            /> */}
      <CardMedia component='img' height='194' image={image} alt='deal image' />
      <CardContent>
        <div className='d-flex justify-content-between'>
          <div>
            <h5 class='card-title'>
              {data.property_title} {data.property_name}
            </h5>
          </div>
          {!data.id ?
          <div>
              <span class='btn btn-large badge rounded-pill bg-primary round'>
                View
              </span>
          </div>
          :<div>
          <Link to={`/dashboard/investors/${data.id}`}>
            <span class='btn btn-large badge rounded-pill bg-primary round'>
              View
            </span>
          </Link>
        </div>}
        </div>
        <Typography variant='body2' color='text.secondary'>
          <div className='mt-4'>
            <div className='d-flex justify-content-between align-items-start'>
              <h6 className='fs-6 fw-bold col' style={{ width: '5rem' }}>
                Deal Name :{' '}
              </h6>
              <h6 className='flex-grow-1 fs-6 fw-bold text-end '>
                {data?.deal_name}
              </h6>
            </div>
            <div className=' d-flex justify-content-between align-items-start mt-1'>
              <h6 className='fs-6 fw-bold col'>Location :</h6>
              <h6 className='fs-6 fw-bold text-end '>{data?.location}</h6>
            </div>
            {/* <div className='row d-flex justify-content-between align-items-start mt-1'>
                            <h6 className='fs-6 fw-bold col'>City :</h6>
                            <h6 className='fs-6 fw-bold col'>New Work</h6>
                        </div> */}
            <div className=' d-flex justify-content-between align-items-start mt-1'>
              <h6 className='fs-6 fw-bold col'>Units</h6>
              <h6 className='fs-6 fw-bold  text-end'>
                {data?.units}
              </h6>
            </div>

            <div className=' d-flex justify-content-between align-items-start mt-1'>
              <h6 className='fs-6 fw-bold col'>Purchase Price :</h6>
              <h6 className='fs-6 fw-bold text-end'>
                ${' '}
                {data?.purchase_price
                  ? numberWithCommas(data?.purchase_price)
                  : 0}
              </h6>
            </div>
            <div className=' d-flex justify-content-between align-items-start mt-1'>
              <h6 className='fs-6 fw-bold col'>Equity Shares :</h6>
              <h6 className='fs-6 fw-bold text-end'>
                $ {numberWithCommas(data?.equity_shares)}
              </h6>
            </div>

            {/* <div className='row d-flex flex-column justify-content-between align-items-start mt-1'>
                            <h6 className='fs-6 fw-bold col'>Descriptions :</h6>
                            <h6 className=' fw-bold col'>Queens Village is a mostly residential middle class neighborhood in the eastern part of the New York City borough of Queens. It is bound by Hollis to the west, Cambria Heights to the south, Bellerose to the east, and Oakland Gardens to the north.
                                Shopping in the community is located along Braddock Avenue, Hillside Avenue, Hempstead Avenue, and Jamaica Avenue (NY 25), as well as on Springfield Boulevard. Located just east of Queens Village, in Nassau County, is the Belmont Park race track.

                                Close to the neighborhood are Cunningham Park and Alley Pond Park, as well as the historic Long Island Motor Parkway (LIMP), home of the turn of the century racing competition, the Vanderbilt Cup. The LIMP was built by William Kissam Vanderbilt, a descendant of the family that presided over the New York Central Railroad and Western Union; it is now part of the Brooklyn-Queens Greenway.

                                Queens Village is located in Queens Community District 13 and its ZIP Codes are 11427, 11428, and 11429.[1] It is patrolled by the New York City Police Department's 105th Precinct.[5] Politically, Queens Village is represented by the New York City Council's 23rd District.[6]</h6>
                        </div> */}
          </div>
        </Typography>
      </CardContent>
      {/* <div className='d-flex align-items-center justify-content-around pl-1 pr-1'>
                <div className='d-flex align-items-center justify-content-between'>
                    <div className='bg-success' style={{ height: ".6rem", width: ".6rem", borderRadius: ".1rem", marginRight: ".3rem" }}></div>
                    <div className='text-muted fw-6'>Share Amount</div>
                </div>
                <div className='d-flex align-items-center justify-content-between'>
                    <div className='bg-warning' style={{ height: ".6rem", width: ".6rem", borderRadius: ".1rem", marginRight: ".3rem" }}></div>
                    <div className='text-muted fw-6'>IRR</div>
                </div>
                <div className='d-flex align-items-center justify-content-between'>
                    <div className='bg-danger' style={{ height: ".6rem", width: ".6rem", borderRadius: ".1rem", marginRight: ".3rem" }}></div>
                    <div className='text-muted fw-6'>Average Annual Return</div>
                </div>
            </div> */}
      <div className='row mt-3 m-1'>
        <div className='col-4'></div>
        <div className='col-8 d-flex justify-content-around align-items-end'>
          {classesData}
        </div>
      </div>
      {search === 'Offerings' ? (
        <div>
          <div className='row align-items-start m-1'>
            <div className='col-4'>
              <div className=' fw-6 mt-1 share-text'>Share Amount</div>
            </div>
            <div className='col-8 d-flex justify-content-around align-items-end'>
              {classesAmount}
            </div>
          </div>

          <div className='row align-items-start m-1'>
            <div className='col-4'>
              <div className=' fw-6 mt-1 share-text'>IRR</div>
            </div>
            <div className='col-8 d-flex justify-content-around align-items-end'>
              {classesIRR}
            </div>
          </div>

          <div className='row align-items-start m-1'>
            <div className='col-4 pe-0'>
              <div className=' fw-6 mt-1 share-text'>Average Return</div>
            </div>
            <div className='col-8 d-flex justify-content-around align-items-end'>
              {classesAVG}
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className='row align-items-start m-1'>
            <div className='col-4'>
              <div className='text-muted fw-6 mt-1'>Amount Requested</div>
            </div>
            <div className='col-8 d-flex justify-content-around align-items-end'>
              {amountRequest}
            </div>
          </div>
          <div className='row align-items-start m-1'>
            <div className='col-4'>
              <div className='text-muted fw-6 mt-1'>Amount Accepted</div>
            </div>
            <div className='col-8 d-flex justify-content-around align-items-end'>
              {amountAccepted}
            </div>
          </div>
        </div>
      )}

      {/* <div style={{ height: "10rem", padding: "0 1rem", margin: ".5rem 0" }}>
                <Chart />
            </div> */}
      <CardActions disableSpacing>
        {/* <IconButton aria-label="add to favorites">
                    <Typography variant="body2" color="text.secondary">
                        <h6 className='fs-6'>Descriptions</h6>
                    </Typography>
                </IconButton> */}
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label='show more'
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout='auto' unmountOnExit>
        <CardContent>
          <Typography variant='body2' color='text.secondary'>
            <h6 className='fs-6 fw-bold'>Descriptions</h6>
          </Typography>
          <Typography paragraph>{data?.description}</Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}
