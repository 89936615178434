const InvestorDashboardData = 
{
    "subscribed_deal": [
        {
            "deal_id": 2,
            "deal_name": "TVA Apartment",
            "amount_subscribed": 900000.0
        },
        {
            "deal_id": 16,
            "deal_name": "Ft myers",
            "amount_subscribed": 870000.0
        },
        {
            "deal_id": 23,
            "deal_name": "Queens Village",
            "amount_subscribed": 850000.0
        },
        {
            "deal_id": 38,
            "deal_name": "Fl Apartments",
            "amount_subscribed": 550000.0
        }
    ],
    "class_allocation": [
        {
            "class_name": "class A",
            "amount": 560000.0
        },
        {
            "class_name": "class B",
            "amount": 402039.0
        },
        {
            "class_name": "class C",
            "amount": 202039.0
        }
    ],
    "pending_subscription": [
        {
            "deal_name": "Queens Village",
            "subscription_request": 600000
        },
        {
            "deal_name": "Emerald Apartments",
            "subscription_request": 644000
        },
        {
            "deal_name": "Paloma Sen Antonio",
            "subscription_request": 500000
        },
        // {
        //     "deal_name": "Viking Mill Apartments",
        //     "subscription_request": 323232
        // }
    ],
    "offerings": [],
    "user_name": "Joel Weinberger",
    "total_subscription": 3264078.0,
    "total_deals": 4
}
export default InvestorDashboardData