import { message } from 'antd'
import React, { useEffect, useState } from 'react'
import MainClient from '../../utilities/MainClient'
import ProfileDetails from './ProfileDetails'
import ProfileImage from './ProfileImage'

const Profile = ({ UserUpdateHandler }) => {
  const [update, setUpdate] = useState(false)
  const [data, setData] = useState()
  const [storeUser, setStoreUser] = useState()
  const [user, setUser] = useState({
    "id": 26,
    "email": "",
    "first_name": "",
    "last_name": "",
    "phone_number": "",
    "city": "",
    "state": "",
    "country": "",
    "zip": "",
    "investment_entity_name": "",
    "entity_type": "",
    "ssn_or_ein": "",
    "investor_image": "",
    "user": storeUser
  })

  const changeHandler = (e) => {
    const { name, value } = e.target
    setUser({ ...user, [name]: value })
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const updateData = () => {
      const fetchClient = new MainClient(`/investor/investor-profile/${data.profile_id}/`, user)
      const response = fetchClient.putAPI()
      message.success('Your profile successfully updated')
      // response.then(res => {
      //     console.log('responseUser', res.data.user)
      //     // setData((res.data.results).reverse())
      //     setUser(res.data.user)
      // })
    }
    updateData()
  }
  const imageUpload = (e) => {
    setUpdate(true)
    let form_data = new FormData()
    form_data.append('investor_image', e.target.files[0], e.target.files[0].name)
    const fetchClient = new MainClient(`/investor/investor-profile-image/${data.profile_id}/`, form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
    fetchClient.putAPI()
      .then(res => {
        setStoreUser({ ...storeUser, investor_image: res.data.investor_image })
        setUpdate(false)
        message.success("Image Upload successfully")
        UserUpdateHandler()
      })
  }

  useEffect(() => {
    const getData = (id) => {
      const fetchClient = new MainClient(`/investor/investor-profile/${id}/`)
      const response = fetchClient.getAPI()
      response.then(res => {
        console.log('responseUser', res.data)
        // setData((res.data.results).reverse())
        setUser(res.data)
        const user = res.data.user
        setUser({ ...user, user })
        setStoreUser(res.data)
      })
    }
    const getUserData = () => {
      const fetchClient = new MainClient(`/user/user-details`)
      const response = fetchClient.getAPI()
      response.then(res => {
        console.log('responseUser', res.data.profile_id)
        setData(res.data)
        getData(res.data.profile_id)
      })
    }
    // getData()
    getUserData()
  }, [])
  // const updateHandler = () =>{
  //   setUpdate(!update)
  // }
  return (
    <div class="mt-3">
      <ProfileImage user={user} image={storeUser?.investor_image} imageUpload={imageUpload} update={update} />
      <ProfileDetails user={user} handleSubmit={handleSubmit} changeHandler={changeHandler} />
    </div>

  )
}

export default Profile