import { useEffect, useState } from "react"
import More from "../../assets/images/more.svg"
import MainClient from "../../utilities/MainClient"
import InvestorSVG from '../../assets/images/investor.svg'
import DetailsSVG from "../../assets/images/detail.svg"
import { Link } from "react-router-dom"
import styled from "styled-components"
import numFormatter from "../../utilities/extrafunction/numberConveter"
import percentage from "../../utilities/extrafunction/Persentage"
import { message } from "antd"
import DealRow from "./DealRow"
import { useDispatch, useSelector } from "react-redux"
import { dealActions } from "../../store/reducers/DealReducers"
import { fetchCloseDeal, fetchOpenDeal } from "../../store/actions/DealAction"

const Progess = styled.progress`
    background: #B2CBFF;
    border-radius: 15px;
    padding:.5rem;
    width:100%
`

const ProgressBar = styled.div`
    background: #2B4479;
    border-radius: 11px 0px 0px 11px;
    width:30%;
`
const Button = styled.button`
    color: white;
    border-radius: "5px";
    background: ${(props) => props.background};
    &:hover{
        color: white;
    }
`

const Select = styled.select`
    border: none;
    padding: .2rem;
    border-radius: 16px;
    background: ${props => props.color};
    color: #FFFFFF;
`
const Option = styled.option`
    background:  #FFFFFF;
    color: #000000;
`

const DealContent = () => {
    const open = useSelector(state=>state.deals.open)
    const [data, setData] = useState([])
    const [filter, setFilter] = useState('Open Deals')
    const [update, setUpdate] = useState(false)
    const [copy, setCopy] = useState(false)
    const openDeals = useSelector(state=>state.deals.openDealData) 
    const closeDeals = useSelector(state=>state.deals.closeDealData) 
    const user = useSelector(state=>state.user.userData)
    const dispatch = useDispatch()

    const openTabHandler = () => {
        dispatch(dealActions.openHandler())
    }
    const closeTabHandler = () => {
        dispatch(dealActions.closeHandler())
    }
    const updateHandler = (deal_id, status) => {
        const body = {
            "deal_status": status
        }
        const fetchClient = new MainClient(`/deal/deal-status/${deal_id}/`, body)
        const response = fetchClient.putAPI()
        response.then(res => {
            console.log('dealData', res.data)
            dispatch(fetchOpenDeal())
            dispatch(fetchCloseDeal())
            setUpdate(!update)
        }).catch(err => console.log(err))
    }

    const selectedHandler = (e, id) => {
        console.log('selected value', e.target.value)
        updateHandler(id, e.target.value)
    }
    const changeUpdate = () =>{
        setUpdate(!update)
    }
    const CopyDealHandler = (id) =>{
        const fetchClient = new MainClient(`/deal/create-copy-deal/${id}/`)
        const response = fetchClient.postAPI()
        // return response
        response.then(res=>{
            dispatch(fetchOpenDeal())
            message.success("Deal copied successfully")
        }).catch(err=>{
            message.error("Deal dose not copied")
        })
        return response
    }

    return (
        <div className="m-3">
            <div className="row mb-3">
                <ul className="nav nav-pills mb-3 stack-ul deals-role position-relative deals" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className={`nav-link ${open && 'active'}`} id="pills-home-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home"
                            aria-selected={open} onClick={openTabHandler}>Open Deals</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className={`nav-link ${!open && 'active'}`} id="pills-profile-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile"
                            aria-selected={!open} onClick={closeTabHandler}>Closed Deals</button>
                    </li>
                </ul>
                <div className="tab-content p-0" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                        <div className="table-responsive card table-card">
                            <div className="">
                                <table className="table table-striped table-hover align-middle open-deals">
                                    <thead>
                                        <tr>
                                            <th>Deal Number</th>
                                            <th className="text-center">Deal Name</th>
                                            { user?.user_type === 'admin'  &&
                                                <th className="text-center">Created by</th>}
                                            <th className="text-center">Launch Date</th>
                                            <th className="text-center">Total Raise</th>
                                            <th className="text-center">Total Subscribed</th>
                                            <th className="text-center">Deal Status</th>
                                            {/* <th className="text-center">Action</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(open ? openDeals : closeDeals)?.map(dt =>
                                            <DealRow dt={dt} key={dt.id} updateHandler={updateHandler} CopyDealHandler={CopyDealHandler} changeUpdate={changeUpdate}/>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DealContent