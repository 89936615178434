import MainClient from "../../utilities/MainClient"
import { sponsorAction } from "../reducers/SponsorReducer"

export const fetchInvitedSponsors = () => {
    return ( async(dispatch)=> {
    const getData = async () => {
        const fetchClient = new MainClient('/user/invite-new-sponsor?status=Invited')
        const res = await fetchClient.getAPI()
        const data = await res.data.results
        return data.reverse()
    }
    try{
        const data = await getData()
        dispatch(sponsorAction.replaceInvitedSponsor(data))
    }
    catch(error){
        console.log('error', error)
    }
})
}

export const fetchDirectSponsors = () => {
    return ( async(dispatch)=> {
    const getData = async () => {
        const fetchClient = new MainClient('/user/invite-new-sponsor?status=Direct')
        const res = await fetchClient.getAPI()
        const data = await res.data.results
        return data.reverse()
    }
    try{
        const data = await getData()
        dispatch(sponsorAction.replaceDirectSponsor(data))
    }
    catch(error){
        console.log('error', error)
    }
})
}