import { InputNumber, Space } from "antd"
import { useEffect, useRef, useState } from "react"
import numberWithCommas from "../../utilities/extrafunction/commaseparate"

const Metrics = ({ total, metrics, setMetrics, entryNoiSelect, setEntryNoiSelect, stabilizedNoiSelect, setStabilizedNoiSelect, proNoiSelect, setProNoiSelect, updateHandler, id, submit }) => {
    const iEleStabilized = useRef(null)
    useEffect(() => {
        if (iEleStabilized.current) {
            iEleStabilized.current.focus();
        }
    }, [iEleStabilized]);
    // const [metrics, setMetrics] = useState({
    //     purchase_price: '',
    //     closing_cost: '',
    //     capex: '',
    //     reserves: ''
    // })
    // const [entryNoiSelect, setEntryNoiSelect] = useState({
    //     purchase_price: false,
    //     closing_cost: false,
    //     capex: false,
    //     reserves: false,
    // })
    // const [stabilizedNoiSelect, setStabilizedNoiSelect] = useState({
    //     purchase_price: false,
    //     closing_cost: false,
    //     capex: false,
    //     reserves: false,
    // })
    // const [proNoiSelect, setProNoiSelect] = useState({
    //     purchase_price: false,
    //     closing_cost: false,
    //     capex: false,
    //     reserves: false,
    // })
    const reservesChange = (value) => {
        setMetrics({ ...metrics, reserves: value })
    }
    const purchasePriceChange = (value) => {
        setMetrics({ ...metrics, purchase_price: value })
    }
    const closingCostChange = (value) => {
        setMetrics({ ...metrics, closing_cost: value })
    }
    const capexChange = (value) => {
        setMetrics({ ...metrics, capex: value })
    }
    const entrynoiChange = (value) => {
        setMetrics({ ...metrics, entry_noi: value })
    }
    const stabilizednoiChange = (value) => {
        setMetrics({ ...metrics, stabilized_noi: value })
    }
    const pronoiChange = (value) => {
        setMetrics({ ...metrics, pro_noi: value })
    }
    console.log("metrix", metrics)

    // const total = Object.values(metrics).slice(0, 4).reduce((a, b) => a + b, 0)
    const entryNoiBasis = [(entryNoiSelect.purchase_price && metrics.purchase_price), (entryNoiSelect.closing_cost && metrics.closing_cost), (entryNoiSelect.capex && metrics.capex), (entryNoiSelect.reserves && metrics.reserves)].reduce((a, b) => a + b, 0)
    const stabilizedNoiBasis = [(stabilizedNoiSelect.purchase_price && metrics.purchase_price), (stabilizedNoiSelect.closing_cost && metrics.closing_cost), (stabilizedNoiSelect.capex && metrics.capex), (stabilizedNoiSelect.reserves && metrics.reserves)].reduce((a, b) => a + b, 0)
    const proNoiBasis = [(proNoiSelect.purchase_price && metrics.purchase_price), (proNoiSelect.closing_cost && metrics.closing_cost), (proNoiSelect.capex && metrics.capex), (proNoiSelect.reserves && metrics.reserves)].reduce((a, b) => a + b, 0)

    return (
        <div class='row' style={{ fontFamily: 'Verdana' }}>
            <div class='col-12 matrix-card'>
                <div class=''>
                    <div className="p-2">
                        <div class='mb-4 mt-2'>
                            <div class='d-flex justify-content-between align-content-center'>
                                <h2 class='mb-0 fs-16 fw-6 text-black'>Metrics and Capitalization :</h2>
                                <h2 class='mb-0 fs-16 fw-5' style={{ color: "#A2ACBD", letterSpacing: '0.2px', }}>Total
                                    <span class='fw-bold text-black'> ${numberWithCommas(total)}</span>
                                    {/* <span class='fw-bold text-black'>${capital ? numberWithCommas(capital) : 0}</span> */}
                                </h2>
                            </div>
                        </div>
                        <div className=''>
                            <form action="" id="deal-form" >
                                <div class="row g-3">
                                    <div class="col-sm-6 col-md-3 ">
                                        <label for="purchasePrice" class="form-label">Purchase Price</label>
                                        <Space direction="vertical" style={{ width: '100%' }}>
                                            <InputNumber style={{ width: '100%', height: '2.3rem' }}
                                                defaultValue={metrics.purchase_price}
                                                placeholder="Enter Purchase Price"
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                // value={property.closing_cost}
                                                name="purchase_price"
                                                onChange={purchasePriceChange}
                                            />
                                        </Space>
                                    </div>
                                    <div class="col-sm-6 col-md-3 ">
                                        <label for="closingCost" class="form-label">Closing Costs</label>
                                        <Space direction="vertical" style={{ width: '100%' }}>
                                            <InputNumber style={{ width: '100%', height: '2.3rem' }}
                                                defaultValue={metrics.closing_cost}
                                                placeholder="Enter Closing Costs"
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                // value={property.closing_cost}
                                                name="closing_cost"
                                                onChange={closingCostChange}
                                            />

                                        </Space>
                                        {/* <input type="number" class="form-control" id="closingCost"
                                            placeholder='Enter Closing Costs'
                                            name="closing_cost"
                                            value={property.closing_cost.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            onChange={changeHandler}
                                            required
                                        /> */}
                                    </div>
                                    <div class="col-sm-6 col-md-3 ">
                                        <label for="reserves" class="form-label">Capex</label>
                                        <Space direction="vertical" style={{ width: '100%' }}>
                                            <InputNumber style={{ width: '100%', height: '2.3rem' }}
                                                defaultValue={metrics.capex}
                                                placeholder="Enter Capex"
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                // value={property.closing_cost}
                                                // name="closing_cost"
                                                onChange={capexChange}
                                            />
                                        </Space>
                                    </div>
                                    <div class="col-sm-6 col-md-3 ">
                                        <label for="reserves" class="form-label">Reserves</label>
                                        <Space direction="vertical" style={{ width: '100%' }}>
                                            <InputNumber style={{ width: '100%', height: '2.3rem' }}
                                                defaultValue={metrics.reserves}
                                                placeholder="Enter Reserves"
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                // value={property.closing_cost}
                                                // name="closing_cost"
                                                onChange={reservesChange}
                                            />
                                        </Space>

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div >
            <div class="noi-container">
                <div class="noi-card ">
                    <div>
                        <label for="noi" class="form-label">Entry NOI</label>
                        <Space direction="vertical" style={{ width: '100%' }}>
                            <InputNumber style={{ width: '100%', height: '2.3rem' }}
                                placeholder="Enter Entry NOI"
                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                value={metrics.entry_noi}
                                onChange={entrynoiChange}
                            />
                        </Space>
                    </div>
                    {/* <div>
                        
                        <label for="noi" class="form-label">Entry NOI</label>
                        <input type="text" class="form-control" id="noi"
                            placeholder='Enter Entry NOI'
                            name="entry_noi"
                        // value={property.entry_noi}
                        // onChange={changeHandler}
                        />
                    </div> */}
                    <div className="mt-3 ml-2 lable-color">Capitalization Basis</div>
                    <div className="noi-selected">
                        <button className={entryNoiSelect.purchase_price ? "noi-selected-button-active" : "noi-selected-button"} onClick={() => setEntryNoiSelect({ ...entryNoiSelect, purchase_price: !entryNoiSelect.purchase_price })}>Purchase Price</button>
                        <button className={entryNoiSelect.closing_cost ? "noi-selected-button-active" : "noi-selected-button"} onClick={() => setEntryNoiSelect({ ...entryNoiSelect, closing_cost: !entryNoiSelect.closing_cost })}>Closing Cost</button>
                        <button className={entryNoiSelect.capex ? "noi-selected-button-active" : "noi-selected-button"} onClick={() => setEntryNoiSelect({ ...entryNoiSelect, capex: !entryNoiSelect.capex })}>Capex</button>
                        <button className={entryNoiSelect.reserves ? "noi-selected-button-active" : "noi-selected-button"} onClick={() => setEntryNoiSelect({ ...entryNoiSelect, reserves: !entryNoiSelect.reserves })}>Reserves</button>
                        <button className={entryNoiSelect.custom ? "noi-selected-button-active" : "noi-selected-button"} onClick={() => { setEntryNoiSelect({ purchase_price: false, closing_cost: false, capex: false, reserves: false, custom: !entryNoiSelect.custom }) }}>Custom</button>
                    </div>
                    {!entryNoiSelect.custom &&
                        <div className="mt-2">
                            <Space direction="vertical" style={{ width: '100%' }}>
                                <InputNumber style={{ width: '100%', height: '2.3rem' }}
                                    value={entryNoiBasis > 0 && entryNoiBasis}
                                    placeholder="Cap Basis"
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}


                                />
                            </Space>
                        </div>}
                    {entryNoiSelect.custom &&
                        <div className="mt-2">
                            <Space direction="vertical" style={{ width: '100%' }}>
                                <InputNumber style={{ width: '100%', height: '2.3rem' }}
                                    value={metrics.entry_capbasis}
                                    placeholder="Enter Cap Basis"
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    onChange={(value) => setMetrics({ ...metrics, entry_capbasis: value })}
                                />
                            </Space>
                        </div>}
                </div>
                <div class="noi-card">
                    <div>
                        <label for="stabilized" class="form-label">Stabilized NOI</label>
                        <Space direction="vertical" style={{ width: '100%' }}>
                            <InputNumber style={{ width: '100%', height: '2.3rem' }}
                                placeholder="Enter Entry NOI"
                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                value={metrics.stabilized_noi}
                                // name="closing_cost"
                                onChange={stabilizednoiChange}
                            />
                        </Space>
                    </div>
                    <div className="mt-3 ml-2 lable-color">Capitalization Basis</div>
                    <div className="noi-selected">
                        <button className={stabilizedNoiSelect.purchase_price ? "noi-selected-button-active" : "noi-selected-button"} onClick={() => setStabilizedNoiSelect({ ...stabilizedNoiSelect, purchase_price: !stabilizedNoiSelect.purchase_price })}>Purchase Price</button>
                        <button className={stabilizedNoiSelect.closing_cost ? "noi-selected-button-active" : "noi-selected-button"} onClick={() => setStabilizedNoiSelect({ ...stabilizedNoiSelect, closing_cost: !stabilizedNoiSelect.closing_cost })}>Closing Cost</button>
                        <button className={stabilizedNoiSelect.capex ? "noi-selected-button-active" : "noi-selected-button"} onClick={() => setStabilizedNoiSelect({ ...stabilizedNoiSelect, capex: !stabilizedNoiSelect.capex })}>Capex</button>
                        <button className={stabilizedNoiSelect.reserves ? "noi-selected-button-active" : "noi-selected-button"} onClick={() => setStabilizedNoiSelect({ ...stabilizedNoiSelect, reserves: !stabilizedNoiSelect.reserves })}>Reserves</button>
                        <button className={stabilizedNoiSelect.custom ? "noi-selected-button-active" : "noi-selected-button"} onClick={() => setStabilizedNoiSelect({ purchase_price: false, closing_cost: false, capex: false, reserves: false, custom: !stabilizedNoiSelect.custom })}>Custom</button>
                    </div>
                    {!stabilizedNoiSelect.custom &&
                        <div className="mt-2">
                            <Space direction="vertical" style={{ width: '100%' }}>
                                <InputNumber style={{ width: '100%', height: '2.3rem' }}
                                    value={stabilizedNoiBasis > 0 && stabilizedNoiBasis}
                                    placeholder="Cap Basis"
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                />
                            </Space>
                        </div>}
                    {stabilizedNoiSelect.custom &&
                        <div className="mt-2">
                            <Space direction="vertical" style={{ width: '100%' }}>
                                <InputNumber style={{ width: '100%', height: '2.3rem' }}
                                    value={metrics.stabilized_capbasis}
                                    placeholder="Enter Cap Basis"
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    onChange={(value) => setMetrics({ ...metrics, stabilized_capbasis: value })}

                                />
                            </Space>
                        </div>}
                </div>
                {/* <div className="row"></div> */}
                <div class='noi-card'>
                    <div class='row g-3'>
                        <div class="col-12">
                            <div>
                                <label for="performa" class="form-label">ProForma NOI</label>
                                <Space direction="vertical" style={{ width: '100%' }}>
                                    <InputNumber style={{ width: '100%', height: '2.3rem' }}
                                        value={metrics.pro_noi}
                                        placeholder="Enter ProForma NOI"
                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        onChange={pronoiChange}
                                    />
                                </Space>
                            </div>
                            <div className="mt-3 ml-2 lable-color">Capitalization Basis</div>
                            <div className="noi-selected">
                                <button className={proNoiSelect.purchase_price ? "noi-selected-button-active" : "noi-selected-button"} onClick={() => setProNoiSelect({ ...proNoiSelect, purchase_price: !proNoiSelect.purchase_price })}>Purchase Price</button>
                                <button className={proNoiSelect.closing_cost ? "noi-selected-button-active" : "noi-selected-button"} onClick={() => setProNoiSelect({ ...proNoiSelect, closing_cost: !proNoiSelect.closing_cost })}>Closing Cost</button>
                                <button className={proNoiSelect.capex ? "noi-selected-button-active" : "noi-selected-button"} onClick={() => setProNoiSelect({ ...proNoiSelect, capex: !proNoiSelect.capex })}>Capex</button>
                                <button className={proNoiSelect.reserves ? "noi-selected-button-active" : "noi-selected-button"} onClick={() => setProNoiSelect({ ...proNoiSelect, reserves: !proNoiSelect.reserves })}>Reserves</button>
                                <button className={proNoiSelect.custom ? "noi-selected-button-active" : "noi-selected-button"} onClick={() => setProNoiSelect({ purchase_price: false, closing_cost: false, capex: false, reserves: false, custom: !proNoiSelect.custom })}>Custom</button>
                            </div>
                            {!proNoiSelect.custom &&
                                <div className="mt-2">
                                    <Space direction="vertical" style={{ width: '100%' }}>
                                        <InputNumber style={{ width: '100%', height: '2.3rem' }}
                                            value={proNoiBasis > 0 && proNoiBasis}
                                            placeholder="Cap Basis"
                                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        />
                                    </Space>
                                </div>}
                            {proNoiSelect.custom &&
                                <div className="mt-2">
                                    <Space direction="vertical" style={{ width: '100%' }}>
                                        <InputNumber style={{ width: '100%', height: '2.3rem' }}
                                            value={metrics.pro_capbais}
                                            placeholder="Enter Cap Basis"
                                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                            onChange={(value) => setMetrics({ ...metrics, pro_capbais: value })}
                                        />
                                    </Space>
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
            {id &&
                <div class="row m-3 mb-2 pb-0 align-items-center justify-content-end">
                    <div class="col-auto ">
                        {submit ?
                            <button class="btn ms-1 save-btn" type="button" disabled style={{ background: "#2b4479" }}>
                                <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                Update...
                            </button> :
                            <button class="btn btn-success save-btn" onClick={updateHandler} style={{ background: "#2b4479" }}>Update</button>}
                    </div>
                </div>}
        </div >)
}

export default Metrics