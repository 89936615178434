import { message } from 'antd';
import React, { useEffect, useState } from 'react'
import MainClient from '../../utilities/MainClient';

const ProfileDetails = ({data, user, handleSubmit, changeHandler}) => {
    console.log('user', user)
    console.log('data', data)
    return (
        <div class="row mb-3">
            <div class="col-12">
                <form onSubmit={handleSubmit}>
                    <div class="card data-card">
                        <div class="card-body">
                            <h3 class="data-title fw-bold">Personal Details</h3>
                            <div class="divider"></div>
                            <div class="row g-3 mb-3">
                                <div class="col-sm-6 col-md-4">
                                    <label for="firstName" class="form-label">First Name</label>
                                    <input type="text" class="form-control" id="firstName"
                                        placeholder="Enter First Name" name="first_name" value={user.first_name} onChange={changeHandler} />
                                </div>
                                <div class="col-sm-6 col-md-4">
                                    <label for="lastName" class="form-label">Last Name</label>
                                    <input type="text" class="form-control" id="lastName" placeholder="Enter Last Name" name="last_name" value={user.last_name} onChange={changeHandler} />
                                </div>
                                <div class="col-sm-6 col-md-4">
                                    <label for="email" class="form-label">Email</label>
                                    <input type="email" class="form-control" id="email"
                                        placeholder="Enter Email Address" value={user.email} name="email" />
                                </div>
                                <div class="col-sm-6 col-md-4">
                                    <label for="companyCountry" class="form-label">Address</label>
                                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="2" name="address" value={user.address} onChange={changeHandler}></textarea>
                                    {/* <input type="textbox" class="form-control" id="companyCountry"
                                        placeholder="Enter Your Address" name="address" value={user.address} onChange={changeHandler} /> */}
                                </div>
                               
                                <div class="col-sm-6 col-md-4">
                                    <label for="company" class="form-label">City</label>
                                    <input type="text" class="form-control" id="company"
                                        placeholder="Enter Your City" name="city" value={user.city} onChange={changeHandler} />
                                </div>
                                <div class="col-sm-6 col-md-4">
                                    <label for="address" class="form-label">State</label>
                                    <input type="text" class="form-control" id="address"
                                        placeholder="Enter Your State" name="state" value={user.state} onChange={changeHandler} />
                                </div>
                                <div class="col-sm-6 col-md-4">
                                    <label for="companyCountry" class="form-label">Country</label>
                                    <input type="text" class="form-control" id="companyCountry"
                                        placeholder="Enter Your Country" name="country" value={user.country} onChange={changeHandler} />
                                </div>
                                <div class="col-sm-6 col-md-4">
                                    <label for="companyCountry" class="form-label">Zip</label>
                                    <input type="text" class="form-control" id="companyCountry"
                                        placeholder="Enter Your Zip" name="zip" value={user.zip} onChange={changeHandler} />
                                </div>
                                <div class="col-sm-6 col-md-4">
                                    <label for="email" class="form-label">Phone</label>
                                    <input type="text" class="form-control" id="email"
                                        placeholder="Enter Your Phone number" value={user.phone_number} name="phone_number" onChange={changeHandler} />
                                </div>
                            </div>
                            <div class="d-flex justify-content-end mb-2" id="submit-btn">
                                {/* <button type="submit" class="btn btn-secondary clr-btn">Reset</button> */}
                                <button type="submit" class="btn button_color ms-3">Update</button>
                            </div>
                        </div>
                    </div>
                    <div class="card data-card mt-3">
                        <div class="card-body">
                            <h3 class="data-title fw-bold">Investor Details</h3>
                            <div class="divider"></div>
                            <div class="row g-3 mb-3">
                                <div class="col-sm-6 col-md-4">
                                    <label for="companyCountry" class="form-label">Investment Entity Name</label>
                                    <input type="text" class="form-control" id="companyCountry"
                                        placeholder="Enter Entity Name" name="investment_entity_name" value={user.investment_entity_name} onChange={changeHandler} />
                                </div>

                                <div class="col-sm-6 col-md-4">
                                    <label for="companyCountry" class="form-label">Entity type</label>
                                    <select class="form-select" aria-label="Default select example" name="entity_type" value={user.entity_type} onChange={changeHandler}>
                                        <option selected>Select your entity type</option>
                                        <option value="LLC">LLC</option>
                                        <option value="LP">LP</option>
                                        <option value="Sole Proprietorship">Sole Proprietorship</option>
                                        <option value="S Corp">S Corp</option>
                                        <option value="C Corp">C Corp</option>
                                        <option value="GP">GP</option>
                                    </select>
                                </div>
                                <div class="col-sm-6 col-md-4">
                                    <label for="zip" className="form-label">SSN or EIN</label>
                                    <input type="text" class="form-control" id="companyCountry"
                                        placeholder="Enter Entity Name" name="ssn_or_ein" value={user.ssn_or_ein} onChange={changeHandler} />
                                </div>
                            </div>
                            <div class="d-flex justify-content-end mb-2" id="submit-btn">
                                {/* <button type="submit" class="btn btn-secondary clr-btn">Reset</button> */}
                                <button type="submit" class="btn button_color ms-3" >Update</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ProfileDetails