import numberWithCommas from "../../utilities/extrafunction/commaseparate";
import numberWithOutCommas from "../../utilities/extrafunction/numberWithoutComma";

const InvestorResult = ({ data }) => {
  return (
    <div className="bg-white p-2 invesment_result_card">
      {/* {data.tier && <div className="pb-2 fs-14 mt-1">{data.tier}</div>} */}
      <div className="table-responsive">
        <table
          className="table data-table align-middle table-borderless sponser-index-table invesment_table"
          id="sponser-table"
        >
          <thead
            className="border-bottom  inline-block"
            style={{ fontSize: "14px", fontWeight: "500", fontFamily:"inter, san-serif", letterSpacing:".3px", lineHeight:"20px" }}
          >
            <tr className="fw-5 investment_tr">
              <th scope="col-1" className="align-middle fw-5">
                Cash Flow{" "}
              </th>
              <th scope="col-1" className="text-center fw-5">
                IRR
              </th>
              <th scope="col-1" className="text-center fw-5">
                Investment
              </th>
              {!data.class_A_year.length > 0
                ? data.class_B_year.length > 0
                  ? data.class_B_year
                  : data.class_C_year
                : data.class_A_year}
              <th scope="col" className="text-center fw-5">
                Total
              </th>
            </tr>
          </thead>
          <div className="invisible" style={{ height:"12px" }}>invisible</div>
          <tbody className="invesment_tbody">
            {data?.investment_class_A > 0 && (
              <>
                <tr className="border">
                  <td >Class A</td>
                  {/* {data.tier==='% Capital Outstanding' ? data.class_A+'%': data.class_A} */}
                  {data.irr_class_A && (
                    <td className="text-center">
                      {isNaN(data.irr_class_A)
                        ? 0
                        : (data.irr_class_A * 100).toFixed(2)}{" "}
                      %
                    </td>
                  )}
                  {data.investment_class_A && (
                    <td className="text-center">${numberWithCommas(data.investment_class_A)}</td>
                  )}
                  {data.class_A}
                  {data.totalCashFlowClassA && (
                    <td className="text-center">
                      <p
                        className="m-0"
                        style={{
                          background: "rgba(178, 203, 255, 1)",
                          borderRadius: "4px",
                        }}
                      >
                        ${numberWithCommas(data.totalCashFlowClassA.toFixed())}
                      </p>
                    </td>
                  )}
                </tr>
                <div className="invisible" style={{ height:"10px" }}>invisible</div>
              </>
            )}

            {data?.investment_class_B > 0 && (
              <>
              <tr className="border">
                <td>Class B</td>
                {data.irr_class_B && (
                  <td className="text-center">
                    {isNaN(data.irr_class_B)
                      ? 0
                      : (data.irr_class_B * 100).toFixed(2)}{" "}
                    %
                  </td>
                )}
                {data.investment_class_B && (
                  <td className="text-center">${numberWithCommas(data.investment_class_B)}</td>
                )}
                {data.class_B}
                {data.totalCashFlowClassB && (
                  <td className="text-center">
                    <p
                      className="m-0"
                      style={{
                        background: "rgba(178, 203, 255, 1)",
                        borderRadius: "4px",
                      }}
                    >
                      ${numberWithCommas(data.totalCashFlowClassB.toFixed())}
                    </p>
                  </td>
                )}
              </tr>
              <div className="invisible" style={{ height:"10px" }}>invisible</div>
              </>
            )}
            {data?.investment_class_C > 0 && (
              <>
              <tr>
                <td>Class C</td>
                {data.irr_class_C && (
                  <td className="text-center">
                    {isNaN(data.irr_class_C)
                      ? 0
                      : (data.irr_class_C * 100).toFixed(2)}{" "}
                    %
                  </td>
                )}
                {data.investment_class_C && (
                  <td className="text-center">${numberWithCommas(data.investment_class_C)}</td>
                )}
                {data.class_C}
                {data.totalCashFlowClassC && (
                  <td className="text-center">
                    <p
                      className="m-0"
                      style={{
                        background: "rgba(178, 203, 255, 1)",
                        borderRadius: "4px",
                      }}
                    >
                      ${numberWithCommas(data.totalCashFlowClassC.toFixed())}
                    </p>
                  </td>
                )}
              </tr>
              <div className="invisible" style={{ height:"10px" }}>invisible</div>
              </>
            )}

            {data.total.length > 0 && (
              <>
              <tr className="border mt-2 p-0 m-0 investment_tr">
                <td className="fw-5">Total</td>
                {data.totalInvetment > 0 ? (
                  <td className="fw-5 text-center">
                    {isNaN(data.totalIRR)
                      ? 0
                      : (data.totalIRR * 100).toFixed(2)}{" "}
                    %
                  </td>
                ) : (
                  <td className="text-center">0.00 %</td>
                )}
                <td className="align-middle fw-5 text-center">
                  ${numberWithCommas(data.totalInvetment.toFixed(0))}
                </td>
                {data.total}
                <td className="text-center fw-5 p-2">
                  <p
                    className="m-0"
                    style={{
                      background: "rgba(178, 203, 255, 1)",
                      borderRadius: "4px",
                    }}
                  >
                    ${numberWithCommas(data.projected_return.toFixed(0))}
                  </p>
                </td>
              </tr>
              <div className="invisible" style={{ height:"10px" }}>invisible</div>
              </>
            )}
            {data.total_cash_yield.length > 0 && (
              <tr className="border inline-block mt-2 investment_tr">
                <td className="fw-5">Cash Yield on Investment</td>
                <td></td>
                <td className="align-middle"></td>
                {data.total_cash_yield}
                <td className="text-center fw-5">
                  <p
                    className="m-0"
                    style={{
                      background: "rgba(178, 203, 255, 1)",
                      borderRadius: "4px",
                    }}
                  >
                    {data.totalInvetment
                      ? (
                          (data.projected_return / data.totalInvetment) *
                          100
                        ).toFixed(2)
                      : "0.00"}{" "}
                    %
                  </p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default InvestorResult;
