import { useState } from "react"
import Common from "./Common"
import AuthClient from "../../utilities/AuthClient"
import { Link, useNavigate, useParams } from "react-router-dom"
import { message } from "antd"

const ResetPassword = () => {
    const [messages, setMessages] = useState('')
    const [data, setData] = useState({
        password1: "",
        password2: ""
    })
    const changeHandler = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
        setMessages({ text: '', color: '' })
    }
    const { uid, token } = useParams()
    const navigate = useNavigate()
    console.log('messages', messages)
    const submitHandler = async (e) => {
        e.preventDefault()
        console.log(data)
        const dt = {
    		"password": data.password1,
    		"token": token,
    		"uidb64": uid
	   }
        const fetchClient = new AuthClient('/user/password-reset-complete', dt)
        await fetchClient.patchAPI()
            .then(res => {
                setMessages({ text: 'Successfully update new password', color: 'success' })
                navigate('/login')
            })
            .catch(err => {
                console.log("err",(JSON.parse(err.request.response)).error[0])
                setMessages({ text: (JSON.parse(err.request.response)).error[0], color: 'danger' })
            })

    }

    return (
        <div className="row vh-height-100">
            <Common />
            <div className="col-sm-7 ps-0 pe-0 bg-white">
                {/* <div className="d-flex align-center justify-content-end pt-2">
                    <div className='col-2'>
                        <Link replace to="/register">Register</Link>
                    </div>
                </div> */}
                <div className="row align-items-center vh-height-94 justify-content-center">
                    <div className="col-sm-10">
                        <div className="signup-col-2">
                            <h2 className="text-center fs-28 fw-5 text-black">Forget Password</h2>
                            {messages.text &&
                                <Link to="/login">
                                    <div class={`alert alert-${messages.color}`} role="alert">
                                        <Link to="/login" class="alert-link">{messages.text}</Link>
                                    </div>
                                </Link>}
                            <div className="sign-up-form">
                                <form className="needs-validation" onSubmit={submitHandler}>
                                    <div className="mb-3">
                                        <label for="password" className="form-label signup-label">New Password</label>
                                        <input type="password" className="form-control sign-up-control" id="password"
                                            name="password1"
                                            value={data.password1}
                                            onChange={changeHandler}
                                            placeholder="Please enter new password"
                                            minLength={8}
                                            required />
                                    </div>
                                    
                                    <div className="d-grid gap-2">
                                        <button type="submit" className="btn button_color mb-3 btn-lg">Save</button>
                                    </div>
                                    <div className="grid float-end">
                                        <Link replace to="/login" style={{ color:"#2B4479" }}>Login</Link> |
                                        <Link to="/register" className="text-muted fs-14 fw-4" href="#"> Register</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default ResetPassword