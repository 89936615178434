import { useState } from 'react';
import { useParams } from 'react-router-dom';
import numberWithCommas from '../../utilities/extrafunction/commaseparate';
import MainClient from '../../utilities/MainClient';
import Graph from './Graph';
import { BsFillXCircleFill } from 'react-icons/bs';
import { InputNumber, message, Switch } from 'antd';
import numberWithOutCommas from '../../utilities/extrafunction/numberWithoutComma';
import { useEffect } from 'react';

const CapitalStack = ({
  totalCapital,
  purchasePrice,
  data,
  setData,
  seniorDept,
  setSeniorDept,
  updateHandler,
  sponsorUpdate,
  setDataLen,
  hurdle,
  setHurdle, 
  errorMessage
}) => {
  const [stackUpdate, setStackUpdate] = useState(false);
  const [update, setUpdate] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [hideClassBtn,sethideClassBtn] = useState(false)
  // console.log('stackData', data)
  const { id } = useParams();
  // const [seniorDept, setSeniorDept] = useState({
  //     capital_stack: '',
  //     amount: '',
  //     purchase_basis: '',
  //     total_capital_basis: '',
  // })

  // const [data, setData] = useState([{
  //     capital_title: 'Class A Equity Shares',
  //     capital_stack: '',
  //     equity: 0,
  //     amount: 0,
  //     purchase_basis: 0,
  //     total_capital_basis: 0,
  //     pref: '',
  //     min_equity_multiple: '',
  //     profit_share: ''
  // }])

  const seniorDeptChangeHandler = (e) => {
    setSeniorDept((prev) => {
      const newVal = { ...prev };
      newVal.amount = numberWithOutCommas(e.target.value);
      newVal.capital_stack =
        (numberWithOutCommas(e.target.value) * 100) / totalCapital;
      return newVal;
    });
    setStackUpdate(true);
  };
  // original handler
  const changeHandler = (e) => {
    const { name, value, id } = e.target;
    const listData = data.slice();
    const newCap = listData.map((cap, index) => {
      for (var key in cap) {
        if (name === 'capital_stack' && cap.capital_title === id) {
          cap['capital_stack'] = value;
          let equity = (
            (parseFloat(value) /
              100 /
              (1 - parseFloat(seniorDept.capital_stack) / 100)) *
            100
          ).toFixed(3);
          cap['equity'] = equity;
          cap['amount'] = ((totalCapital * parseFloat(value)) / 100).toFixed();
          let lp = (cap.profit_share / equity) * 100;
          cap['Sponsor'] = lp;
          cap['LP'] = lp > 0 && 100 - lp;
          // cap["purchase_basis"] = ((parseFloat(value) / 100) + (parseFloat(seniorDept.capital_stack) / 100) * purchasePrice).toFixed(2)
          let sumStack = 0;
          if (data.length > 0) {
            // sumStack = 0
            // console.log('eqData', data)
            // const sliceData = data.slice(0, data.length)
            let l = [];
            listData.map((dt) => {
              l.push(parseFloat(dt.capital_stack));
            });
            // console.log('l', l)
            const sliceData = l.splice(0, index + 1);
            // console.log('spliceData', sliceData)
            // console.log('l', sliceData.reduce((a, b) => a + b, 0))
            sumStack = sliceData.reduce((a, b) => a + b, 0);
          }
          // console.log('sum', sumStack + seniorDept.capital_stack)
          cap['sums'] =
            parseFloat(sumStack) + parseFloat(seniorDept.capital_stack);
          cap['purchase_basis'] = (
            (parseFloat(sumStack) / 100 +
              parseFloat(seniorDept.capital_stack) / 100) *
            purchasePrice
          ).toFixed(0);
          cap['total_capital_basis'] = (
            (parseFloat(sumStack) / 100 +
              parseFloat(seniorDept.capital_stack) / 100) *
            totalCapital
          ).toFixed(0);
        } else if (name === 'profit_share' && cap.capital_title === id) {
          cap[name] = value;
          let lp = (value / cap.equity) * 100;
          cap['LP'] = (value / cap.equity) * 100;
          cap['Sponsor'] = 100 - lp;
        }
        // console.log('capName', key, name)
        else if (key === name && cap.capital_title === id) {
          cap[name] = value;

          // console.log('cap', cap.name)
        }
        // else{
        //     cap[name] = value
        // }
      }
      return cap;
    });
    setData(newCap);
    // setStackUpdate(!stackUpdate)
  };

  // modified
  const changeHandler1 = (e) => {
    const { name, value, id } = e.target;
    const listData = data.slice();
    const newCap = listData.map((cap, index) => {
      for (var key in cap) {
        if (name === 'amount' && cap.capital_title === id) {
          let amount = numberWithOutCommas(value);
          cap['amount'] = amount;
          let capitalStack = (100 * amount) / totalCapital;
          cap['capital_stack'] = capitalStack;
          let equity = (
            (parseFloat(capitalStack) /
              100 /
              (1 - parseFloat(seniorDept.capital_stack) / 100)) *
            100
          ).toFixed(3);

          cap['equity'] = equity;
          // cap["purchase_basis"] = ((parseFloat(value) / 100) + (parseFloat(seniorDept.capital_stack) / 100) * purchasePrice).toFixed(2)
          let sumStack = 0;
          if (data.length > 0) {
            // sumStack = 0
            // console.log('eqData', data)
            // const sliceData = data.slice(0, data.length)
            let l = [];
            listData.map((dt) => {
              l.push(parseFloat(dt.capital_stack));
            });
            // console.log('l', l)
            const sliceData = l.splice(0, index + 1);
            // console.log('spliceData', sliceData)
            // console.log('l', sliceData.reduce((a, b) => a + b, 0))
            sumStack = sliceData.reduce((a, b) => a + b, 0);
          }
          // console.log('sum', sumStack + seniorDept.capital_stack)
          cap['sums'] =
            parseFloat(sumStack) + parseFloat(seniorDept.capital_stack);
          cap['purchase_basis'] = (
            (parseFloat(sumStack) / 100 +
              parseFloat(seniorDept.capital_stack) / 100) *
            purchasePrice
          ).toFixed(0);
          cap['total_capital_basis'] = (
            (parseFloat(sumStack) / 100 +
              parseFloat(seniorDept.capital_stack) / 100) *
            totalCapital
          ).toFixed(0);
        } else if (name === 'profit_share' && cap.capital_title === id) {
          cap[name] = value;
          let lp = (cap.profit_share / cap.equity) * 100;
          cap['LP'] = lp;
          cap['Sponsor'] = lp && 100 - lp;
        }
        // console.log('capName', key, name)
        else if (name == 'min_equity_multiple' && cap.capital_title === id) {
          cap[name] = value;
          cap['irr_hurdle'] = "0.0"
          // console.log('cap', cap.name)
        }
        else if (name == 'irr_hurdle' && cap.capital_title === id) {
          cap[name] = value;
          cap['min_equity_multiple'] = "0.0"
          // console.log('cap', cap.name)
        }
        else if (name == 'pref' && cap.capital_title === id) {
          cap[name] = value;
          if(value > 0){
            if(cap.serviceable_pref_return > 0){
            cap['accruable_pref_return'] = value - cap.serviceable_pref_return
            }else{
              cap['accruable_pref_return'] = value
            }
            console.log('accurable', )
          }
          else{
            cap['accruable_pref_return'] = cap.serviceable_pref_return
          }
        }
        else if (name == 'serviceable_pref_return' && cap.capital_title === id) {
          cap[name] = value;
          if(value){
            if(cap.pref){
              cap['accruable_pref_return'] = cap.pref - value
            }else{
              cap['accruable_pref_return'] = value
            }
          }else{
            cap['accruable_pref_return'] = cap.pref
          }
        }
        else if (key === name && cap.capital_title === id) {
          cap[name] = value;
        }
      }
      return cap;
    });
    setData(newCap);
    // setStackUpdate(!stackUpdate)
  };
  console.log('newCap', data);

  const sponsorProfitShare =
    data &&
    (1 -
      data
        .map((dt) => {
          return parseFloat(dt.profit_share);
        })
        .reduce((a, b) => a + b) /
      100) *
    100;

  const addHandler = (e) => {
    const mt = [
      { 1: 'A' },
      { 2: 'B' },
      { 3: 'C' },
      { 4: 'D' },
      { 5: 'E' },
      { 6: 'F' },
      { 7: 'G' },
      { 8: 'H' },
      { 9: 'I' },
      { 10: 'J' },
      { 11: 'K' },
      { 12: 'L' },
      { 13: 'M' },
      { 14: 'N' },
      { 15: 'O' },
      { 16: 'P' },
      { 17: 'Q' },
      { 18: 'R' },
      { 19: 'S' },
      { 20: 'T' },
    ];
    const len = data.length;
    console.log("data is",data);
    if(len <3){  //Can add only 3 class 
      const value = Object.values(mt)[len];
      const capital_class = Object.values(value)[0];
      // const l = ...data
      const dt = {
        capital_title: `Class ${capital_class} Equity Shares`,
        capital_stack: '',
        equity: 0,
        amount: '',
        purchase_basis: 0,
        total_capital_basis: 0,
        pref: '',
        min_equity_multiple: "0",
        irr_hurdle: "0",
        profit_share: '',
      };
      data.push(dt);
      setData(data);
      setDataLen(data.length);
      setUpdate(!update);
    }else{
      sethideClassBtn(true)
      message.warn("You can Add Only 3 Class")
    }

  };

  // let renData = ((parseFloat(dt.capital_stack)/100)/(1-(parseFloat(seniorDept.capital_stack)/100))*100).toFixed(2)

  // useEffect(() => {
  //     if (stackUpdate === true) {
  //         renderCapitalStack()
  //     } else {
  //         renderCapitalStack()
  //     }
  // }, [stackUpdate])

  const removeHandler = () => {
    data.pop();
    setUpdate(!update);
  };
  console.log('data', data);
  const stack = data.map((dt, index) => (
    <tr className='mt-3'>
      <td className='col'>{dt.capital_title}</td>
      <td className='text-center'>
        <div className='d-flex align-items-center justify-content-center'>
          <input
            placeholder='amount'
            className='form-control table-control'
            type='text'
            name='amount'
            id={dt.capital_title}
            value={dt.amount > 0 ? numberWithCommas(dt.amount) : ''}
            onChange={changeHandler1}
            style={{ textAlign: 'center' }}
          />{' '}
        </div>
      </td>
      {/* {id ? <td className="text-center">
                {dt.capital_stack}%
            </td> :
                <td className="text-center">
                    <div className="d-flex align-items-center justify-content-center">
                        <input placeholder="capital stack %" className="form-control table-control"
                            type="text" name="capital_stack" id={dt.capital_title} value={dt.capital_stack} onChange={changeHandler} /> %
                    </div>
                </td>} */}
      <td className='text-center'>
        {dt.equity ? parseFloat(dt.equity).toFixed(2) : (0).toFixed(2)} %
        {/* {dt.capital_stack && seniorDept.capital_stack ? ((parseFloat(dt.capital_stack)/100)/(1-(parseFloat(seniorDept.capital_stack)/100))*100).toFixed(2) : 0}% */}
      </td>
      <td className='text-center'>
        {/* {dt.amount ? numberWithCommas(dt.amount) : 0} */}
        {dt.capital_stack
          ? parseFloat(dt.capital_stack).toFixed(2)
          : (0).toFixed(2)}
        %
      </td>
      <td className='text-center'>
        <div className='d-flex align-items-center justify-content-center'>
          <input
            placeholder='Enter Return'
            className='form-control table-control'
            type='text'
            name='pref'
            value={dt.pref}
            onChange={changeHandler1}
            id={dt.capital_title}
            style={{ textAlign: 'center' }}
          />{' '}
          %
        </div>
      </td>
      <td className='text-center'>
        <div className='d-flex align-items-center justify-content-center'>
          <input
            placeholder='Enter Serviceable'
            className='form-control table-control'
            type='text'
            name='serviceable_pref_return'
            value={dt.serviceable_pref_return}
            onChange={changeHandler1}
            id={dt.capital_title}
            style={{ textAlign: 'center' }}
          />{' '}
          %
        </div>
      </td>
      <td className='text-center'>
        <div className='d-flex align-items-center justify-content-center'>
          <div>{dt.accruable_pref_return > 0 ? dt.accruable_pref_return : 0}</div>
          {' '}%
        </div>
      </td>
      {hurdle == 'None' &&
        <td className='d-flex align-items-center justify-content-center'>
          <input
            placeholder="Please Select Hurdle"
            className='form-control table-control'
            type='text'
            // name={hurdle === 'None' ? '' : hurdle}
            // value={dt.min_equity_multiple}
            // onChange={changeHandler1}
            id={dt.capital_title}
            style={{ textAlign: 'center' }}
          />
        </td>}
      {hurdle == 'min_equity_multiple' &&
        <td className='d-flex align-items-center justify-content-center'>
          <input
            placeholder={hurdle === 'None' ? '' : hurdle}
            className='form-control table-control'
            type='text'
            name={hurdle === 'None' ? '' : hurdle}
            value={dt.min_equity_multiple}
            onChange={changeHandler1}
            id={dt.capital_title}
            style={{ textAlign: 'center' }}
          />{' '}
          X
        </td>}
      {hurdle == "irr_hurdle" &&
        <td className='d-flex align-items-center justify-content-center'>
          <input
            placeholder={hurdle === 'None' ? '' : hurdle}
            className='form-control table-control'
            type='text'
            name={hurdle === 'None' ? '' : hurdle}
            value={dt.irr_hurdle}
            onChange={changeHandler1}
            id={dt.capital_title}
            style={{ textAlign: 'center' }}
          />{' '}
          %
        </td>}
      <td className='col text-center'>
        <div className='d-flex align-items-center justify-content-center'>
          <input
            placeholder='Profit Share'
            className='form-control table-control'
            type='text'
            name='profit_share'
            onChange={changeHandler1}
            value={dt.profit_share}
            id={dt.capital_title}
            style={{ textAlign: 'center' }}
          />{' '}
          %
          {/* <span style={{ cursor: 'pointer', textAlign: "center", }}><BsFillXCircleFill size={20} color={"tomato"} className="mb-1" onClick={removeHandler} /></span> */}
        </div>
      </td>
      {!(data.length - 1 == index && data.length > 1) ?
        <td className='text-center'>
          {dt.LP ? dt.LP.toFixed(2) : (0).toFixed(2)} % / {dt.Sponsor ? dt.Sponsor.toFixed(2) : (0).toFixed(2)} %
        </td> :
        <td className='text-center'>
          {dt.LP ? dt.LP.toFixed(2) : (0).toFixed(2)} %
        </td>}
      {data.length - 1 == index && data.length > 1 && (
        <span style={{ cursor: 'pointer', textAlign: 'center', padding: 0 }}>
          <BsFillXCircleFill
            size={20}
            color={'tomato'}
            className='mb-1'
            onClick={removeHandler}
          />
        </span>
      )}
    </tr>
  ));
  // console.log('data', data)
  let p = [];
  let percent = [];
  let amount = [];
  const value = [
    data.map((dt) => {
      p.push(parseFloat(dt.total_capital_basis));
      amount.push(parseFloat(dt.amount));
    }),
  ];
  const totalCapitalBasis = p.reduce((a, b) => a + b, 0);
  const tatalAmount = amount.reduce((a, b) => a + b, 0);
  const seniorDebtAmount =
    totalCapital && seniorDept.capital_stack
      ? ((totalCapital * parseFloat(seniorDept.capital_stack)) / 100).toFixed()
      : 0;
  const seniorDebtAmount1 =
    totalCapital && seniorDept.amount
      ? (seniorDept.amount * 100) / totalCapital
      : 0;

  console.log('senior dept', seniorDebtAmount1, seniorDept.amount);
  const submitHandler = () => {
    setSubmit(true);
    const dt = {
      deal_id: id,
      senior_debt: seniorDept.capital_stack,
      senior_debt_amount: seniorDept.amount,
      classesData: data,
      sponsor_profit_share: sponsorProfitShare,
    };
    const postData = () => {
      const fetchClient = new MainClient(`/deal/stack-creation/`, dt);
      const response = fetchClient.postAPI();
      response.then((res) => {
        updateHandler(!sponsorUpdate);
        setSubmit(false);
        // navigate(`/dashboard/sponsor/${id}`)
        // updateHandler(!sponsorUpdate)
      });
    };
    postData();
    // console.log('capPostData', dt)
  };
  const color = [
    '#2F80ED',
    '#009D55',
    '#EB5757',
    '#ffbf00',
    '#ff00ff',
    '#660033',
  ];
  const bgColor = [
    '#E4EFFF',
    '#DAEAFF',
    '#CBE0FD',
    '#00ffff',
    '#00ffbf',
    '#00bfff',
  ];  
  const capitalStackList = data.map((dt) =>
    parseFloat(dt.capital_stack > 0 ? dt.capital_stack : 0)
  );
  const equityList = data.map((dt) =>
    parseFloat(dt.equity > 0 ? dt.equity : 0)
  );
  const amountList = data.map((dt) =>
    parseFloat(dt.amount > 0 ? dt.amount : 0)
  );
  const totalCapitalStack =
    capitalStackList.reduce((a, b) => a + b) +
    parseFloat(seniorDept.capital_stack);
  const totalEquity = equityList.reduce((a, b) => a + b);
  const totalAmount =
    amountList.reduce((a, b) => a + b) +
    parseFloat(
      numberWithOutCommas(seniorDebtAmount > 0 ? seniorDebtAmount : '0')
    );

  const profitList = data.map((dt) =>
    parseFloat(dt.profit_share > 0 ? dt.profit_share : 0)
  );
  const sponsorList = data.map((dt) =>
    parseFloat(dt.Sponsor ? dt.Sponsor : 0)
  );
  const LPList = data.map((dt) =>
    parseFloat(dt.LP ? dt.LP : 0)
  );
  const totalProfitShare = profitList.reduce((a, b) => a + b, 0);
  const totalSponsor = sponsorList.reduce((a, b) => a + b);
  const totalLP = LPList.reduce((a, b) => a + b);
  console.log("totalSponsor", totalSponsor, totalLP, sponsorList, LPList)
  useEffect(() => {
    if (totalCapitalStack > 100) {
      message.error('Total capital stack sould be 100%');
    }
  }, [totalCapitalStack]);
  console.log('totalCapital', totalCapitalStack);
  console.log('dataLen', data)
  return (
    <div className='mb-3'>
      <div className='col-12'>
        <div className=''>
          <div className='card-body'>
            <div className='mb-3'>
              <div className='d-flex justify-content-between align-items-center'>
                <h2 className='mb-0 fs-18 fw-6 text-black'>Capital Stack</h2>
                <h4 className='mb-0 fs-14 '>
                  Total Capitalization :{' '}
                  <span className='text-black fw-bold'>
                    ${totalCapital ? numberWithCommas(totalCapital) : 0}
                  </span>{' '}
                </h4>
              </div>
            </div>
            <div className='data-body sponser-data-body'>
              <div className='table-responsive-xxl'>
                <table
                  className='table data-table table-borderless sponser-index-table'
                  id='sponser-table'
                >
                  <thead className='table-white mb-2'>
                    <tr>
                      <th scope='col' className='border'>
                        Capital Stack Segment
                      </th>
                      <th scope='col' className='text-center border'>
                        Amount
                      </th>
                      <th scope='col' className='text-center border'>
                        Equity %
                      </th>
                      <th scope='col' className='text-center border'>
                        Capital Stack %
                      </th>
                      <th scope='col' className='text-center border'>
                        <div className=''>Preferred Return %</div>
                      </th>
                      <th scope='col' className='text-center border'>
                        <div className=''>Serviced</div>
                      </th>
                      <th scope='col' className='text-center border'>
                        <div className=''>Accrued</div>
                      </th>
                      <th
                        scope='col text-center'
                        className='align-middle border'
                      >
                        {/* Min Equity Multiple */}
                        <div className='d-flex flex-column align-items-center mt-2'>
                          <div className=''>Hurdle</div>
                          <div className='d-flex align-items-center justify-content-around'>
                            <div className='col text-center mt-1'>
                              <select
                                class=''
                                aria-label=''
                                style={{
                                  padding: '.2rem .6rem .2rem .6rem',
                                  cursor: 'pointer',
                                  outline: 'none',
                                  border: '1px solid blue',
                                  boxShadow: '2px 2px 2px #ddd',
                                  borderRadius: '10px',
                                }}
                                onChange={(e) => setHurdle(e.target.value)}
                                value={hurdle}
                              >
                                <option value='None'>
                                  None
                                </option>
                                <option value='min_equity_multiple'>
                                  Min Equity Multiple
                                </option>
                                <option
                                  className='font-weight-bold'
                                  value='irr_hurdle'
                                >
                                  IRR
                                </option>
                              </select>
                            </div>
                            {/* <span style={{ fontWeigth: "400" }}>{hurdle}</span> */}
                          </div>
                        </div>
                      </th>
                      <th scope='col' className='text-center border'>
                        Profit Share %
                      </th>
                      <th scope='col' className='text-center border'>
                        Profit Class Allocation %
                      </th>
                      {/* <th scope='col' className='text-center border'>
                        LP %
                      </th> */}
                    </tr>
                  </thead>
                  <tbody style={{ marginTop: '1rem' }}>
                    <tr style={{ marginTop: '1rem' }}>
                      <td>Senior Debt</td>
                      <td className='col mt-2'>
                        <div className='d-flex align-items-center justify-content-center'>
                          {/* <InputNumber
                            className='form-control table-control'
                            style={{ width: '100%', height: '2.3rem' }}
                            defaultValue={seniorDept.amount}
                            formatter={(value) =>
                              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                            }
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            // value={property.closing_cost}
                            name='amount'
                            id='amount'
                            // value={numberWithCommas(seniorDept.amount)}
                            onChange={seniorDeptChangeHandler}
                          /> */}
                          <input
                            placeholder='amount'
                            className='form-control table-control'
                            type='text'
                            name='amount'
                            value={seniorDept.amount > 0 ? numberWithCommas(seniorDept.amount) : ''}
                            onChange={seniorDeptChangeHandler}
                            style={{ textAlign: 'center' }}
                          />{' '}
                        </div>
                      </td>
                      {/* {id ? <td className="text-center">
                                                {seniorDept.capital_stack} %
                                            </td> :
                                                <td className="col mt-2">
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <input placeholder="Senior Dept" className="form-control table-control"
                                                            type="text" name="capital_stack" value={seniorDept.capital_stack} onChange={seniorDeptChangeHandler} />%
                                                    </div>
                                                </td>} */}
                      <td className='col'></td>
                      <td className='col text-center'>
                        {seniorDebtAmount1.toFixed(3)}%
                      </td>
                      <td className='text-center'></td>
                      <td className='text-center'></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    {stack}

                    {!hideClassBtn?(

                    <div className='mt-2 mb-4'>
                      <button
                        onClick={addHandler}
                        className='btn add-new-btn p-0 mt-0'
                        style={{ fontSize: '.8rem' }}
                        id='addittional-className'
                      >
                        + Add additional class
                      </button>
                    </div>
                    ):(
                      <>
                        
                      </>
                    )}
                    <tr style={{ background: '#eee' }}>
                      <td className=''>Total</td>
                      <td className='text-center'>
                        {totalAmount > 0 ? numberWithCommas(totalAmount) : 0}
                      </td>

                      <td
                        className={
                          totalEquity > 100
                            ? 'text-center text-danger'
                            : 'text-center'
                        }
                      >
                        {totalEquity.toFixed(2)} %
                      </td>
                      <td
                        className={
                          totalCapitalStack > 100
                            ? 'text-center text-danger'
                            : 'text-center'
                        }
                      >
                        {totalCapitalStack > 0
                          ? totalCapitalStack.toFixed(3)
                          : (0).toFixed(3)}{' '}
                        %
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className='text-center'>
                        {totalProfitShare > 0
                          ? totalProfitShare.toFixed(2)
                          : (0).toFixed(2)}{' '}
                        %
                      </td>
                      <td className='text-center'>
                        {/* {totalLP ? totalLP.toFixed(2) : (0).toFixed(2)} % /  {totalSponsor
                          ? totalSponsor.toFixed(2)
                          : (0).toFixed(2)}{' '}%
                         */}
                        {totalProfitShare > 0
                          ? totalProfitShare.toFixed() + "%" + " / " + (100 - totalProfitShare).toFixed()
                          : '0 % / 0 '}
                        %
                      </td>
                      {/* <td className='text-center'>
                        {totalLP ? totalLP.toFixed(2) : (0).toFixed(2)} %
                      </td>} */}
                    </tr>
                  </tbody>
                  {/* <caption>
                    <div style={{ marginRight: '1.2rem' }}>
                      <h2 className='text-dark fs-16 fw-5 mt-4'>
                        Capital Stack Graph
                      </h2>
                      <div className='d-flex mt-5 w-100 mr-5'>
                        
                        <div
                          style={{
                            width: seniorDept.capital_stack + '%',
                            background: '#F5F9FF',
                            padding: '1rem',
                            textAlign: 'center',
                            position: 'relative',
                            minHeight: '67px',
                          }}
                        >
                          <div className='d-flex align-items-center me-5 justify-content-center'>
                            <div className='dark-box'></div>
                            <h2 className='mb-0 fs-12 text-black ms-2'>
                              Debt{' '}
                              <span className='text-primary'>
                                <br />
                                {seniorDept.capital_stack > 0
                                  ? parseFloat(
                                      seniorDept.capital_stack
                                    ).toFixed()
                                  : 0}
                                % ${numberWithCommas(seniorDebtAmount)}
                              </span>{' '}
                            </h2>
                          </div>
                          <div
                            style={{
                              position: 'absolute',
                              right: 12,
                              top: 28,
                              width: '26px',
                              lineHeight: '53.61px',
                            }}
                          >
                            <div
                              style={{
                                border: '.5px solid #A1C9FF',
                                transform: 'rotate(-90deg)',
                                width: '75px',
                              }}
                            ></div>
                          </div>
                          <div
                            style={{
                              position: 'absolute',
                              right: 2,
                              top: -30,
                              width: '14px',
                              lineHeight: '53.61px',
                            }}
                          >
                            <div
                              style={{
                                border: '1px solid #A1C9FF',
                                borderBottom: 0,
                                width: '30px',
                                height: '20px',
                              }}
                            >
                              <div
                                className='text-dark'
                                style={{
                                  position: 'absolute',
                                  top: -15,
                                  right: -14,
                                  fontSize: '12px',
                                  textAlign: 'center',
                                  fontWeight: '600',
                                }}
                              >
                                {seniorDept.capital_stack
                                  ? parseFloat(
                                      seniorDept.capital_stack
                                    ).toFixed()
                                  : 0}
                                %
                              </div>
                            </div>
                            <div
                              style={{
                                width: 0,
                                height: 0,
                                borderLeft: '15px solid #A1C9FF',
                                borderRight: '15px solid #A1C9FF',
                                borderTop: '10px solid #A1C9FF',
                              }}
                            ></div>
                          </div>
                        </div>
                        {data &&
                          data.map((dt, index) => (
                            <div
                              style={{
                                minWidth:
                                  parseFloat(dt.capital_stack) < 10
                                    ? 10
                                    : dt.capital_stack + '%',
                                background: bgColor[index],
                                paddingTop: '.5rem',
                                textAlign: 'center',
                                position: 'relative',
                              }}
                              key={dt.capital_title}
                            >
                              <div className='d-flex align-items-center justify-content-center p-2 me-2 '>
                                <div
                                  className='primary-box'
                                  style={{ background: color[index] }}
                                ></div>
                                <h2 className='mb-0 fs-12 text-black ms-2'>
                                  {dt.capital_title.slice(0, 7)}
                                  <span>
                                    <br />
                                    {dt.capital_stack
                                      ? parseFloat(dt.capital_stack).toFixed()
                                      : 0}
                                    % $
                                    {dt.capital_stack
                                      ? numberWithCommas(dt.amount)
                                      : 0}
                                  </span>{' '}
                                </h2>
                              </div>
                             
                              <div
                                style={{
                                  position: 'absolute',
                                  right: 12,
                                  top: 28,
                                  width: '26px',
                                  lineHeight: '53.61px',
                                }}
                              >
                                <div
                                  style={{
                                    border: '.5px solid #A1C9FF',
                                    transform: 'rotate(-90deg)',
                                    width: '75px',
                                  }}
                                ></div>
                              </div>
                              <div
                                style={{
                                  position: 'absolute',
                                  right: 4,
                                  top: -30,
                                  width: '14px',
                                  lineHeight: '53.61px',
                                }}
                              >
                                <div
                                  style={{
                                    border: '1px solid #A1C9FF',
                                    borderBottom: 0,
                                    width: '36px',
                                    height: '20px',
                                  }}
                                >
                                  <div
                                    className='text-dark'
                                    style={{
                                      position: 'absolute',
                                      top: -15,
                                      right: -19,
                                      fontSize: '12px',
                                      textAlign: 'center',
                                      fontWeight: '600',
                                    }}
                                  >
                                    {dt.capital_stack
                                      ? parseFloat(dt.sums).toFixed()
                                      : 0}
                                    %
                                  </div>
                                </div>
                                <div
                                  style={{
                                    width: 0,
                                    height: 0,
                                    borderLeft: '16px solid #A1C9FF',
                                    borderRight: '20px solid #A1C9FF',
                                    borderTop: '10px solid #A1C9FF',
                                  }}
                                ></div>
                              </div>
                            </div>
                          ))}
                        
                      </div>
                    </div>
                  </caption> */}
                </table>
              </div>
            </div>
            {errorMessage?.length > 0 &&
            <div class="alert alert-danger text-center" role="alert">
              {errorMessage}
            </div>}
          </div>

          {/* <div class="row m-3 align-items-center justify-content-end">

                        <div class="col-auto ">
                            {submit ?
                                <button class="btn btn-primary ms-1 save-btn" type="button" disabled>
                                    <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                    Saving...
                                </button> :
                                <button class="btn btn-success save-btn" onClick={submitHandler}>Save</button>}
                        </div>
                    </div> */}
          {/* <div className="d-flex justify-content-end mt-0 mb-3 mx-4">
                    <button className="btn btn-md btn-primary" type="submit" onClick={submitHandler}>Submit</button>
                </div> */}
        </div>
      </div>
    </div>
  );
};

export default CapitalStack;
