import numberWithCommas from "../../utilities/extrafunction/commaseparate";
import styled from "styled-components";
import "../../assets/css/subscriptioncol.css";

const PercentDept = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.bg ? props.bg : "")};
  color: white;
  padding: 2rem 0;
  position: relative;
  font-size: 10px;
  min-width: 80px;
  height: ${(props) => (props.hg ? props.hg : "")};
  border-width: 2px; /* Default border width */
  border-style: solid;
  position: relative;

  @media screen and (max-width: 768px) {
    width: ${(props) => (props.hg ? props.hg : "")};
    height: 63px;
  }
`;

const NewDeptGraph = ({ data, seniorDept, total }) => {
  const color = [
    "#2F80ED",
    "#009D55",
    "#EB5757",
    "#ffbf00",
    "#ff00ff",
    "#660033",
  ];
  const bgColor = [
    "#0063AF",
    "#58A3A8",
    "#15BD80",
    "#E4EFFF",
    "#DAEAFF",
    "#CBE0FD",
    "#00ffff",
    "#00ffbf",
    "#00bfff",
  ];
  let lastResult = parseFloat(
    seniorDept.capital_stack
      ? parseFloat(seniorDept.capital_stack).toFixed()
      : 0
  );
  let accumulator = 0;

  console.log("the data is a follow", total);
  return (
    <>
      <div className="cube_top"></div>
      <div className="front-view">
        <div className="cube_left"></div>
        <div className="graph_values">
          <PercentDept
            hg={
              seniorDept.capital_stack < "15px"
                ? // ? "auto"
                  seniorDept.capital_stack + "%"
                : seniorDept.capital_stack + "%"
            }
            bg="#4F7CDA"
            first // Apply first border width
          >
            <span className="dept_percentaged">
              {seniorDept.capital_stack
                ? parseFloat(seniorDept.capital_stack).toFixed()
                : 0}
              %
            </span>
            <span className="dept_name">Debt</span>
            <span className="dept_values">
              $
              {total && seniorDept.capital_stack
                ? numberWithCommas(
                    (
                      (total * parseFloat(seniorDept.capital_stack)) /
                      100
                    ).toFixed()
                  )
                : 0}
            </span>
          </PercentDept>

          {/* For Small Devices */}

          {data.map((dt, index) => {
            const val1 = seniorDept.capital_stack
              ? parseFloat(seniorDept.capital_stack).toFixed()
              : 0;
            const val2 = dt.capital_stack
              ? parseFloat(dt.capital_stack).toFixed()
              : 0;

            let total;

            if (index === 0) {
              total = parseFloat(val1) + parseFloat(val2);
              accumulator = total;
            } else {
              total = accumulator + parseFloat(val2);
              accumulator = total;
            }

            return (
              <PercentDept
                hg={dt.capital_stack + "%"}
                bg="#4F7CDA"
                key={dt.capital_title}
                last={index === data.length - 1} // Apply last border width
              >
                <span className="dept_percentaged">
                  {dt.capital_stack
                    ? parseFloat(dt.capital_stack).toFixed()
                    : 0}
                  %
                </span>
                <span className="dept_name">
                  {dt.capital_title.slice(0, 7)}
                </span>
                <span className="dept_values">
                  ${dt.capital_stack ? numberWithCommas(dt.amount) : 0}
                </span>
                {index === data.length - 1 && (
                  <span className="scaler_class_last">{total.toFixed()}%</span>
                )}
              </PercentDept>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default NewDeptGraph;
