import { message } from 'antd';
import React, { useEffect, useState } from 'react'
import MainClient from '../../utilities/MainClient';

const PasswordDetails = ({ data, user, handleSubmit, changeHandler }) => {
    // console.log('data', data)
    return (
        <div class="row mb-3">
            <div class="col-12">
                    <div class="card data-card">
                        <div class="card-body mt-3">
                            <div className='border-bottom border-dark pb-2'>
                                <h3 class="fw-bold fs-5 p-0 m-0">Password</h3>
                                <p className='fw-4 text-secondary mb-0'>Please enter your current password to change your password</p>
                            </div>
                            <div class="row border-bottom border-dark pb-4 m-0 g-3">
                                <div class="col-sm-6 col-md-4 col-lg-4 fw-bold fs-6 p-0">
                                    Current password
                                </div>
                                <div class="col-sm-6 col-md-4 col-lg-4">
                                <input type="password" class="form-control" id="firstName"
                                        placeholder="Enter Current Password" name="old_password" value={data.old_password} onChange={changeHandler} required/>
                                </div>
                                <div class="col-sm-6 col-md-4 col-lg-2">
                                </div>
                                {/* <div class="col-sm-6 col-md-4">
                                    <label for="firstName" class="form-label">Current Password</label>
                                    <input type="text" class="form-control" id="firstName"
                                        placeholder="Enter First Name" name="first_name" value={user.first_name} onChange={changeHandler} />
                                </div> */}
                            </div>
                            <div class="row border-bottom border-dark pb-4 m-0 g-3">
                                <div class="col-sm-6 col-md-4 col-lg-4 fw-bold fs-6 p-0">
                                    New password
                                </div>
                                <div class="col-sm-6 col-md-4 col-lg-4">
                                <input type="password" class="form-control" id="firstName"
                                        placeholder="Enter Your New Password" name="new_password" value={data.new_password} onChange={changeHandler} minLength="8" required/>
                                <div className='mt-1 ms-1' style={{ color: "#767676", fontSize:"12px" }}>Your new password must be more than 8 characters</div>
                                </div>
                                <div class="col-sm-6 col-md-4 col-lg-2">
                                </div>
                            </div>
                            <div class="row border-bottom border-dark pb-4 m-0 g-3">
                                <div class="col-sm-6 col-md-4 col-lg-4 fw-bold fs-6 p-0">
                                    Confirm password
                                </div>
                                <div class="col-sm-6 col-md-4 col-lg-4">
                                <input type="password" class="form-control" id="firstName"
                                        placeholder="Re-Enter Your New Password" name="confirm_new_password" value={data.confirm_new_password} onChange={changeHandler} minLength="8" required/>
                                
                                    {/* <div className='mt-1 ms-1 text-danger' style={{ fontSize:"12px" }}>Confirm Password Should match with New Password</div> */}
                                </div>
                                <div class="col-sm-6 col-md-4 col-lg-2">
                                </div>
                                {/* <div class="col-sm-6 col-md-4">
                                    <label for="firstName" class="form-label">Current Password</label>
                                    <input type="text" class="form-control" id="firstName"
                                        placeholder="Enter First Name" name="first_name" value={user.first_name} onChange={changeHandler} />
                                </div> */}
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    )
}

export default PasswordDetails