import { message } from 'antd'
import React, { useEffect, useState } from 'react'
import MainClient from '../../utilities/MainClient'
import ProfileImage from '../Profile/ProfileImage'
import NewHeader from '../static/NewHeader'
import NewSidebar from '../static/NewSidebar'
import Sidebar from '../static/Sidebar'
import PasswordDetails from './PasswordDetails'
import ProfileDetails from './PasswordDetails'
// import ProfileImage from './ProfileImage'

const ChangePassword = () => {
  const [update, setUpdate] = useState(false)
  const [data, setData] = useState({
    "old_password": "",
    "new_password": "",
    "confirm_new_password": ""
  })
  const [userData, setUserData] = useState()
  const [storeUser, setStoreUser] = useState()
  const [user, setUser] = useState({
    "id": 26,
    "email": "",
    "first_name": "",
    "last_name": "",
    "phone_number": "",
    "city": "",
    "state": "",
    "country": "",
    "zip": "",
    "investment_entity_name": "",
    "entity_type": "",
    "ssn_or_ein": "",
    "investor_image": "",
    "user": storeUser
  })

  const changeHandler = (e) => {
    const { name, value } = e.target
    setData({ ...data, [name]: value })
  }

  const updateData = () => {
    const fetchClient = new MainClient(`/user/change-password/`, data)
    const response = fetchClient.putAPI()
    response.then(res => {
      console.log("res", res)
      message.success('Password Successfully Updated')
      setData({
        "old_password": "",
        "new_password": "",
        "confirm_new_password": ""
      })
    }).catch(error => {
      let er = error.request.response
      let errMessage = JSON.parse(er).message
      message.error(errMessage)
    })
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('chnagePassword2', data)
    if (data.new_password == data.confirm_new_password) {
      updateData()
    }
    else {
      message.error('Confirm password should match with new password')
    }
  }
  const imageUpload = (e) => {
    setUpdate(true)
    let form_data = new FormData()
    form_data.append('investor_image', e.target.files[0], e.target.files[0].name)
    const fetchClient = new MainClient(`/investor/investor-profile-image/${data.profile_id}/`, form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
    fetchClient.putAPI()
      .then(res => {
        setStoreUser({ ...storeUser, investor_image: res.data.investor_image })
        setUpdate(false)
        message.success("Image Upload successfully")
      })
  }

  useEffect(() => {
    const getInvestorProfile = (id) => {
      const fetchClient = new MainClient(`/investor/investor-profile/${id}/`)
      const response = fetchClient.getAPI()
      response.then(res => {
        console.log('responseInvestor', res.data)
        // setData((res.data.results).reverse())
        setUser(res.data)
        const user = res.data.user
        setUser({ ...user, user })
        setStoreUser(res.data)
      })
    }
    const getSponsorProfile = (id) => {
      const fetchClient = new MainClient(`/investor/sponsor-profile/${id}/`)
      const response = fetchClient.getAPI()
      response.then(res => {
        console.log('responseSponsor', res.data)
        // setData((res.data.results).reverse())
        setUser(res.data)
        const user = res.data.user
        setUser({ ...user, user })
        setStoreUser(res.data)
      })
    }
    const getUserData = () => {
      const fetchClient = new MainClient(`/user/user-details`)
      const response = fetchClient.getAPI()
      response.then(res => {
        console.log('arunResponse', res.data)
        setUserData(res.data)
        if(res.data.user_type=="sponsor"){
          getSponsorProfile(res.data.profile_id)
        }else{
          getInvestorProfile(res.data.profile_id)
        }
      })
    }
    // getData()
    getUserData()
  }, [])
  // const updateHandler = () =>{
  //   setUpdate(!update)
  // }
  const cancelHandler = () => {
    setData({
      "old_password": "",
      "new_password": "",
      "confirm_new_password": ""
    })
    message.success("Successfully Cancel")
  }
  console.log('storeImage', storeUser)
  return (
    <div class="mt-3">
      <ProfileImage user={user} image={userData?.user_type=="sponsor" ? storeUser?.sponsor_image : storeUser?.investor_image} />
      <form onSubmit={handleSubmit}>
        <PasswordDetails data={data} handleSubmit={handleSubmit} changeHandler={changeHandler} />
        <div class="d-flex justify-content-end mb-2">
          <button className="btn btn-sm px-4 py-1 me-2 fw-6" style={{ border: "1px solid #2B4479", color: "#2B4479" }} onClick={cancelHandler}>Cancel</button>
          <button type="submit" className="btn btn-sm button_color px-3 py-1 fw-bold">Update Password</button>
        </div>
      </form>
    </div>

  )
}

export default ChangePassword