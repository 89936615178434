import { message, Spin } from 'antd';
import React, { useEffect, useState } from 'react'
import { DeleteSvg, EditSvg } from '../../assets/icons/Icons'
import MainClient from '../../utilities/MainClient';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import MyInvestorData from '../../data/MyInvestorData';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { sponsorAction } from '../../store/reducers/SponsorReducer';
import { fetchInvitedSponsors } from '../../store/actions/SponsorAcrions';


const MySponsor = ({ userType }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [sendInvitations, setSendInvitations] = useState(false)
  const [data, setData] = useState()
  const [update, setUpdate] = useState(false)
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [searchData, setSearchData] = useState("Invited")
  const [loading, setLoading] = useState()
  const [userData, setUserData] = useState({
    sponsor_name: "",
    sponsor_email: "",
  })
  const { directSponsors, invitedSponsors, direct, invited } = useSelector(state => state.sponsors)
  const dispatch = useDispatch()
  // const direct = useSelector(state=>state.sponsors.direct)
  console.log('directSponsor', directSponsors)
  const changeHandler = (e) => {
    const { name, value } = e.target
    setUserData({
      ...userData, [name]: value
    })
  }
  const submitHandler = (e) => {
    setSendInvitations(true)
    e.preventDefault()
    const fetchClient = new MainClient('/user/invite-new-sponsor', userData)
    const response = fetchClient.postAPI()
    response.then(res => {
      setUserData({
        sponsor_name: "",
        sponsor_email: ""
      })
      dispatch(fetchInvitedSponsors())
      message.success("Invitation send successfully")
    })
      .catch(err => {
        setSendInvitations(false)
        message.success("Please enter a valid email")
      })
  }
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const invitedHandler = () =>{
    dispatch(sponsorAction.replaceInvited())
  }
  const directHandler = () =>{
    dispatch(sponsorAction.replaceDirect())
  }
  // useEffect(() => {
  //   const getData = () => {
  //     const fetchClient = new MainClient(`/user/invite-new-sponsor?status=${searchData}`)
  //     const response = fetchClient.getAPI()
  //     response.then(res => {
  //       console.log('response', res.data)
  //       setData((res.data.results).reverse())
  //     })
  //   }
  //   // if(userType=="admin"){
  //   //   setData(MyInvestorData)
  //   // }else{
  //   //   getData()
  //   // }
  //   getData()
  // }, [update, searchData])

  const FilterButton = styled.div`
  padding: 3px 12px;
  border : 2px solid green;
  border-radius: 12px;
  transition: .5s;
  color : ${(props) => props.searchData ? "white" : "green"};
  background : ${(props) => props.searchData ? "green" : "white"};
  margin-right: 15px;
  cursor: pointer;
  font-weight: 600;
`

  return (
    <div class="row mb-3">
      <div class="col-12">
        <div class="card data-card">
          <div class="card-body">
            <div class="d-flex align-items-center justify-content-between">
              <h3 class="data-title fw-bold mb-0">My Sponsors </h3>
              <button class="btn btn-primary add-investor" style={{ background: "#2B4479" }} data-bs-toggle="modal"
                onClick={handleShow}>
                Add Sponsor
              </button>
            </div>
            <div className='d-flex align-items-center justify-content-start mt-2'>
              <FilterButton searchData={invited} onClick={invitedHandler}>Invited</FilterButton>
              <FilterButton searchData={direct} onClick={directHandler}>Direct</FilterButton>
            </div>
            <div class="divider"></div>
            <div class="table-responsive">
              <table
                class="table data-table table-striped align-middle table-borderless sponser-profile-table">
                <thead>
                  <tr>
                    <th className="col">Sponsor name </th>
                    <th className="col text-center">Phone Number</th>
                    <th className="col text-center">Email</th>
                    <th className="col text-center">Address</th>
                    <th className="col text-center">Country</th>
                    <th className="col text-center">Status</th>
                    <th className="text-center" scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {(direct ? directSponsors: invitedSponsors)?.map((dt) =>
                    <tr>
                      <td className=''>{dt.sponsor_name}</td>
                      <td className='text-center'>{dt.phone_number ? dt.phone_number : '-'}</td>
                      <td className='text-center'>{dt.email ? dt.email : '-'}</td>
                      <td className='text-center'>{dt.address ? dt.address : '-'}</td>
                      <td className='text-center'>{dt.country ? dt.country : '-'}</td>
                      <td className='text-center'>
                        {dt.status === 'Pending' ?
                          <span class="badge table-badge rounded-pill bg-danger fw-4">Pending</span> :
                          <span class="badge table-badge rounded-pill bg-green fw-4">Accepted</span>}
                      </td>
                      <td class="text-center">
                        <a class="">
                          {EditSvg}
                        </a>
                        <a class="del-btn">
                          {DeleteSvg}
                        </a>
                      </td>
                    </tr>
                  )}

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} className="p-5 m-5">
        <div className="modal-header p-4" style={{ background: "#f0f9ff" }}>
          <h5 className="modal-title" id="invite-title" style={{ color: "#2B4479" }}>Invite Sponsor</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
        </div>
        <Modal.Body>
          <p class="text-dark fs-18">Invite Sponsor by entering their name & email</p>
          <form onSubmit={submitHandler}>
            <div class="mb-3">
              <label for="sponserName" class="form-label">Sponsor Name</label>
              <input type="text" class="form-control" id="sponserName" placeholder="Enter Name" name="sponsor_name" value={userData.sponsor_name} onChange={changeHandler} required />
            </div>
            <div class="mb-3">
              <label for="sponserAddress" class="form-label">Email address</label>
              <input type="email" class="form-control" id="sponserAddress" name="sponsor_email"
                placeholder="Enter email address" value={userData.sponsor_email} onChange={changeHandler} required />
            </div>
            <div class="d-grid gap-2">
              {sendInvitations ?
                <button class="btn btn-primary">
                  <Spin /> Sending...
                </button> :
                <button class="btn btn-primary" style={{ background: "#2B4479" }}>Send Invite</button>}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>
      </Modal>
      {/* <Modal visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <div class="modal-dialog modal-dialog-centered" id="invitation-modal">
          <div class="modal-content p-0">
            <div class="modal-header">
              <h5 class="modal-title" id="invite-title">Invite Investor</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleCancel}></button>
            </div>
            <div class="modal-body">
              <p class="text-dark fs-18">Invite Investor by entering their name & email</p>
              <form onSubmit={submitHandler}>
                <div class="mb-3">
                  <label for="sponserName" class="form-label">Investor Name</label>
                  <input type="text" class="form-control" id="sponserName" placeholder="Enter Name" name="investor_name" value={userData.investor_name} onChange={changeHandler} />
                </div>
                <div class="mb-3">
                  <label for="sponserAddress" class="form-label">Email address</label>
                  <input type="text" class="form-control" id="sponserAddress" name="email"
                    placeholder="Enter email address" value={userData.email} onChange={changeHandler} />
                </div>
                <div class="d-grid gap-2">
                  {sendInvitations ?
                    <button class="btn btn-primary">
                      <Spin /> Sending...
                    </button> :
                    <button class="btn btn-primary" style={{ background: "#2B4479" }}>Send Invite</button>}
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal> */}
    </div>

  )
}

export default MySponsor