import React from 'react'

const ProfileDetails = ({ changeHandler, data, submitHandler }) => {
    return (
      <div class="row mb-3">
      <div class="col-12">
          <div class="card data-card">
              <div class="card-body">
                  <h3 class="data-title fw-bold">Personal Details</h3>
                  <div class="divider"></div>
                  <form>
                      <div class="row g-3 mb-3">
                          <div class="col-sm-6 col-md-4">
                              <label for="firstName" class="form-label">First Name</label>
                              <input type="text" class="form-control" id="firstName"
                                  placeholder="Enter First Name" name="first_name" value={data?.user?.first_name} onChange={changeHandler}/>
                          </div>
                          <div class="col-sm-6 col-md-4">
                              <label for="lastName" class="form-label">Last Name</label>
                              <input type="text" class="form-control" id="lastName" placeholder="Enter Last Name"
                              name="last_name" value={data?.user?.last_name} onChange={changeHandler}/>
                          </div>
                          <div class="col-sm-6 col-md-4">
                              <label for="email" class="form-label">Email</label>
                              <input type="email" class="form-control" id="email"
                                  placeholder="Enter Email Address"
                                  name="email" value={data?.user?.email} onChange={changeHandler}/>
                          </div>
                          <div class="col-sm-6 col-md-4">
                              <label for="companyCountry" class="form-label">Phone Number</label>
                              <input type="text" class="form-control" id="companyCountry"
                                  placeholder="Enter Phone Number"
                                  name="phone_number" value={data?.phone_number} onChange={changeHandler}/>
                          </div>
                      </div>
                      <div class="d-flex justify-content-end mb-2" id="submit-btn">
                          {/* <button type="submit" class="btn btn-secondary clr-btn">Reset</button> */}
                          <button type="submit" class="btn ms-3 button_color" onClick={submitHandler}>Update</button>
                      </div>
                  </form>
              </div>
          </div>
      </div>
  </div>
    )
}

export default ProfileDetails