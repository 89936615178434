import '../../assets/css/icons.css'
import '../../assets/css/multi-slider.css'
import '../../assets/css/style1.css'
import Sidebar from '../static/Sidebar'
import Header from '../static/Header'
import { useEffect, useState } from 'react'
import DealContent from './DealContent'
import Footer from '../static/Footer'
import { useDispatch } from 'react-redux'
import { fetchCloseDeal, fetchOpenDeal } from '../../store/actions/DealAction'


const Deal = () => {
    const [open, setOpen] = useState(true)
    const [close, setClose] = useState(false)
    const dispatch = useDispatch()

    useEffect(()=>{
        dispatch(fetchOpenDeal())
        dispatch(fetchCloseDeal())
    },[])

    const openTabHandler = () => {
        setOpen(true)
        setClose(false)
    }
    const closeTabHandler = () => {
        setOpen(false)
        setClose(true)
    }
    return (
        <div>
            <DealContent openTabHandler={openTabHandler} closeTabHandler={closeTabHandler} open={open} close={close} />
        </div>
    )
}

export default Deal