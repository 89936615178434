import numberWithCommas from "../../../utilities/extrafunction/commaseparate";
import { Carousel, Icon } from "antd";
import { useEffect, useRef, useState } from "react";
import KeyboardArrowLeftSharpIcon from "@mui/icons-material/KeyboardArrowLeftSharp";
import KeyboardArrowRightSharpIcon from "@mui/icons-material/KeyboardArrowRightSharp";
import { Button, IconButton } from "@mui/material";
import styled from "styled-components";
import { getGeocode, getLatLng } from "use-places-autocomplete";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { Link, useNavigate } from "react-router-dom";
import Arrow from "../../../assets/images/arrow.png";
import { saveAs } from "file-saver";
import GoogleMapKey from "../../Keys/apiKeys";
import { DefaultImage } from "../../static/DefaultImgUrl";
import SubscribeDialog from "../SubscribeDialog";
import "./mobileDealDetails.css";

const Price = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.05em;
  color: #2b4479;
  cursor: pointer;
  /* margin-top: 0.4rem; */
  @media (min-width: 1200px) and (max-width: 1440px) {
    font-size: 20px;
  }
  @media (min-width: 768px) and (max-width: 1200px) {
    font-size: 16px;
  }
  @media (min-width: 200px) and (max-width: 560px) {
    font-size: 14px;
  }
`;

const libraries = ["places"];

const NewDealDetails = ({
  property,
  propertyList,
  terms,
  total,
  targeted_irr,
  totalCashflow,
  capAmount,
  sponsor,
  document,
}) => {
  const [Index, setIndex] = useState();
  const navigate = useNavigate();
  const contentStyle = {
    height: "160px",
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    background: "#364d79",
  };
  const [center, setCenter] = useState({
    lat: 43.6532,
    lng: -79.3832,
  });
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GoogleMapKey,
    libraries,
  });
  const [showFullDescription, setShowFullDescription] = useState(false);
  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const descriptionText = property.description;
  const shortDescription =
    descriptionText.length > 378
      ? descriptionText.substring(0, 378) + "..."
      : descriptionText;

  const CarouselRef = useRef();
  const CarouselMediaRef = useRef();
  const totalAddProUnit = propertyList.reduce(
    (total, property) =>
      total + (property.units ? parseInt(property.units) : 0),
    0
  );
  const totalUnits = parseInt(property.units) + parseInt(totalAddProUnit);

  // For Subscription Dialog For Public Url
  const user_id = localStorage.getItem("user_id");
  const [showDialog, setShowDialog] = useState(false);

  let addPropertySquareFeet = 0;
  // Calculate the total square feet
  for (const property of propertyList) {
    const squareFeet = property.square_feet
      ? property.square_feet.replace(/,/g, "")
      : 0;
    addPropertySquareFeet += parseInt(squareFeet);
  }

  const totalSquareFeet =
    parseInt(property.square_feet) + parseInt(addPropertySquareFeet);

  const next = () => {
    CarouselRef.current.next();
  };
  const previous = () => {
    CarouselRef.current.prev();
  };
  const sponsorHandler = () => {
    if (user_id) {
      console.log("navigate");
      navigate(`/dashboard/sponsor-details/${sponsor.id}`);
    } else {
      setShowDialog(true);
    }
  };

  console.log("sponsor is ", sponsor);

  // Geolocation When Page Load
  useEffect(() => {
    const getLocation = async (address, city, state) => {
      try {
        let results = await getGeocode({ address });
        console.log("mapd", results);
        if (results.length === 0) {
          throw new Error("No results found for address");
        }
        const { lat, lng } = await getLatLng(results[0]);
        setCenter({
          lat: lat,
          lng: lng,
        });
      } catch (error) {
        console.log("😱 Error: ", error);
        console.log("Error: ", error);

        try {
          let cityResults = await getGeocode({ address: city });
          console.log("mapd with city", cityResults);
          if (cityResults.length === 0) {
            throw new Error("No results found for city");
          }
          const { lat, lng } = await getLatLng(cityResults[0]);
          setCenter({
            lat: lat,
            lng: lng,
          });
        } catch (error) {
          console.log("😱 Error: ", error);
          console.log("Error: ", error);
        }
      }
    };

    getLocation(property.address, property.city, property.state);
  }, [property.address, property.city]);

  console.log(property.address, property.city, property.state);

  const getLocationNew = async (address, city) => {
    try {
      let results = await getGeocode({ address });
      console.log("mapd", results);
      if (results.length === 0) {
        throw new Error("No results found for address");
      }
      const { lat, lng } = await getLatLng(results[0]);
      setCenter({
        lat: lat,
        lng: lng,
      });
    } catch (error) {
      console.log("😱 Error: ", error);
      console.log("Error: ", error);

      try {
        let cityResults = await getGeocode({ address: city });
        console.log("mapd with city", cityResults);
        if (cityResults.length === 0) {
          throw new Error("No results found for city");
        }
        const { lat, lng } = await getLatLng(cityResults[0]);
        setCenter({
          lat: lat,
          lng: lng,
        });
      } catch (error) {
        console.log("😱 Error: ", error);
        console.log("Error: ", error);
      }
    }
  };

  console.log("center", center);
  const mapContainerStyle = {
    height: "230px",
    width: "100%",
  };
  const options = {
    // styles: mapStyles,
    disableDefaultUI: true,
    zoomControl: true,
  };
  const omHandler = () => {
    let file = document[0]?.file;
    const file_name = document[0]?.file_name;
    if (window.location.hostname !== "localhost") {
      file = file.replace("http://", "https://");
    }
    console.log("file", file, file_name);
    saveAs(file, file_name);
  };
  // const omHandler = () => {

  //   if(user_id){
  //     const file = document[0]?.file;
  //     const file_name = document[0]?.file_name;
  //     saveAs(file, file_name);
  //   }
  //   else{
  //     setShowDialog(true)
  //   }
  // };
  const mapRef = useRef(null);

  const scrollToSection = () => {
    mapRef.current.scrollIntoView({ behavior: "smooth" });
  };
  if (loadError) return "Error";
  if (!isLoaded) return "Loading...";

  return (
    <div className="deal_container">
      <div className="row p-0 m-0 ">
        <div className="">
          <div>
            {property?.image_data?.length > 0 ? (
              <div className="single-item">
                <Carousel
                  ref={(carousel) => (CarouselMediaRef.current = carousel)}
                  autoplay
                  dots={false}
                  asNavFor={CarouselRef.current}
                  // afterChange={Index}
                >
                  {property.image_data.map((img, index) => (
                    <div key={index}>
                      <img
                        className="img-fluid w-100 investor-deal-image rounded"
                        src={img.images}
                        alt=""
                        style={{
                          // minHeight: '378px',
                          objectFit: "content",
                        }}
                      />
                    </div>
                  ))}
                </Carousel>
              </div>
            ) : (
              <div className="single-item">
                <img
                  className="img-fluid w-100 investor-deal-image rounded"
                  src={DefaultImage}
                  alt=""
                  style={{
                    // minHeight: '378px',
                    objectFit: "content",
                  }}
                />
              </div>
            )}
          </div>

          {propertyList.length > 1 && (
            <div className="mt-4">
              <p className="deal_description_title p-0 mb-1">Description</p>
              <div>
                <p className="deal_description_text">
                  {showFullDescription ? descriptionText : shortDescription}
                  {descriptionText.length > 400 && (
                    <span onClick={toggleDescription} className="more_text">
                      {showFullDescription ? "show less" : "show more"}
                    </span>
                  )}
                </p>
              </div>
            </div>
          )}
        </div>
        {/* portfolio */}
        <div className="portfolio_container">
          <div className="portfolio_header">
            <h5 className="portfolio_header_title">{property.deal_name}</h5>
            <Button
              variant="contained"
              style={{ backgroundColor: "#2B4479" }}
              onClick={omHandler}
            >
              <img src={Arrow} alt="" className="me-1" />
              OM
            </Button>
          </div>
          <div className="line"></div>
          <div className="property_card">
            <div className="property_card_left">
              <h5 className="property_card_title">
                Property Title : <span>{property.property_title}</span>
              </h5>
              <h5 className="property_card_title">
                Address : <span>{property.address}</span>
              </h5>
              <h5 className="property_card_title">
                City : <span>{property.city}</span>
              </h5>
              <h5 className="property_card_title">
                State : <span>{property.state}</span>
              </h5>
              <h5 className="property_card_title">
                Units : <span>{property.units}</span>
              </h5>
            </div>
            <div className="property_card_right">
              <div className="border mt-4 me-2" onClick={scrollToSection}>
                <button
                  className="view_map"
                  onClick={() => {
                    setCenter({
                      lat: property.lat,
                      lng: property.lng,
                    });
                  }}
                >
                  View Map
                </button>
              </div>
            </div>
          </div>

          {propertyList.map((property, index) => (
            <>
              <div className="line"></div>
              <div className="property_card">
                <div className="property_card_left">
                  <h5 className="property_card_title">
                    Property Title : <span>{property.property_title}</span>
                  </h5>
                  <h5 className="property_card_title">
                    Address : <span>{property.address}</span>
                  </h5>
                  <h5 className="property_card_title">
                    City : <span>{property.city}</span>
                  </h5>
                  <h5 className="property_card_title">
                    State : <span>{property.state}</span>
                  </h5>
                  <h5 className="property_card_title">
                    Units : <span>{property.units}</span>
                  </h5>
                </div>
                <div className="property_card_right">
                  <div className="border mt-4 me-2" onClick={scrollToSection}>
                    <button
                      className="view_map"
                      onClick={() => {
                        setCenter({
                          lat: property.lat,
                          lng: property.lng,
                        });
                      }}
                    >
                      View Map
                    </button>
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
        {/* map */}
        <div
          className="col-sm-12 col-md-12 col-lg-12 investor_map"
          id="mapId"
          ref={mapRef}
        >
          {/* <div className="google-map-box border rounded"> */}
          <GoogleMap
            id="map"
            mapContainerStyle={mapContainerStyle}
            zoom={16.5}
            center={center}
            options={options}
            // onClick={onMapClick}
            // onLoad={onMapLoad}
          >
            <Marker
              key={`${center.lat}-${center.lng}`}
              position={{ lat: center.lat, lng: center.lng }}
              // onClick={() => {
              //     setSelected(marker);
              // }}
              icon={{
                url: `https://img.icons8.com/office/40/000000/marker.png`,
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(15, 15),
                scaledSize: new window.google.maps.Size(30, 30),
              }}
            />
          </GoogleMap>
          {/* </div> */}
        </div>
        {/* total cards */}
        <div className="cash_flow_card_wrapper">
          <div className="cash_flow_card">
            <h6 className="cash_flow_card_title">Total Units</h6>
            <h5 className="cash_flow_card_content">275</h5>
          </div>
          <div className="cash_flow_card">
            <h6 className="cash_flow_card_title">Total Square Feet</h6>
            <h5 className="cash_flow_card_content">
              {totalSquareFeet ? numberWithCommas(totalSquareFeet) : 0}
            </h5>
          </div>
          <div className="cash_flow_card">
            <h6 className="cash_flow_card_title">Investment Term</h6>
            <h5 className="cash_flow_card_content">{terms} Year </h5>
          </div>
          <div className="cash_flow_card">
            <h6 className="cash_flow_card_title">Purchase Price</h6>
            <h5 className="cash_flow_card_content">
              $
              {property.purchase_price &&
                numberWithCommas(property.purchase_price)}{" "}
            </h5>
          </div>
          <div className="cash_flow_card">
            <h6 className="cash_flow_card_title">Total Capitalization</h6>
            <h5 className="cash_flow_card_content">
              ${total && numberWithCommas(total)}
            </h5>
          </div>
          <div className="cash_flow_card">
            <h6 className="cash_flow_card_title">Total Shares</h6>
            <h5 className="cash_flow_card_content">
              ${numberWithCommas(capAmount)}
            </h5>
          </div>
          <div className="cash_flow_card">
            <h6 className="cash_flow_card_title">Projected Deal Cash Flow</h6>
            <h5 className="cash_flow_card_content">
              ${numberWithCommas(totalCashflow)}
            </h5>
          </div>
          <div className="cash_flow_card">
            <h6 className="cash_flow_card_title">Deal Level IRR</h6>
            <h5 className="cash_flow_card_content">{targeted_irr} %</h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewDealDetails;
