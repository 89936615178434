import numberWithCommas from "../../utilities/extrafunction/commaseparate";
import { Carousel, Icon } from "antd";
import { useEffect, useRef, useState } from "react";
import KeyboardArrowLeftSharpIcon from "@mui/icons-material/KeyboardArrowLeftSharp";
import KeyboardArrowRightSharpIcon from "@mui/icons-material/KeyboardArrowRightSharp";
import { Button, IconButton } from "@mui/material";
import styled from "styled-components";
import { getGeocode, getLatLng } from "use-places-autocomplete";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { Link, useNavigate } from "react-router-dom";
import Arrow from "../../assets/images/arrow.png";
import { saveAs } from "file-saver";
import GoogleMapKey from "../Keys/apiKeys";
import { DefaultImage } from "../static/DefaultImgUrl";
import SubscribeDialog from "./SubscribeDialog";


const Price = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.05em;
  color: #2b4479;
  cursor: pointer;
  /* margin-top: 0.4rem; */
  @media (min-width: 1200px) and (max-width: 1440px) {
    font-size: 20px;
  }
  @media (min-width: 768px) and (max-width: 1200px) {
    font-size: 16px;
  }
  @media (min-width: 200px) and (max-width: 560px) {
    font-size: 14px;
  }
`;

const MetricsContainer = styled.div`
  .large-screen-metrics {
    display: none;
  }

  .small-screen-metrics {
    display: block;
    
  }
    .card-body {
    background-color: rgb(245, 249, 255);
    }

  @media (min-width: 780px) {
    .large-screen-metrics {
      display: block;
    }
    .small-screen-metrics {
      display: none;
      
    }
    
    .card-body {
    background-color: white;
    }

  }
`;

const libraries = ["places"];

const MetricCard = ({ title, value }) => (
  <div className="p-3 h-100 bg-white" style={{border: '2px solid transparent', borderRadius: '5px', boxShadow: '0px 0px 10px 0px #0000000D'}}>
    <h6 className="fs-12 mb-2" style={{color: '#2B4479'}}>{title}</h6>
    <span className="fs-16 fw-6 text-black">{value}</span>
  </div>
);

const NewDealDetails = ({
  property,
  propertyList,
  terms,
  total,
  targeted_irr,
  totalCashflow,
  capAmount,
  sponsor,
  document,
}) => {
  const [Index, setIndex] = useState();
  const navigate = useNavigate();
  const contentStyle = {
    height: "160px",
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    background: "#364d79",
  };
  const [center, setCenter] = useState({
    lat: 43.6532,
    lng: -79.3832,
  });
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GoogleMapKey,
    libraries,
  });

  const CarouselRef = useRef();
  const CarouselMediaRef = useRef();
  const totalAddProUnit = propertyList.reduce(
    (total, property) =>
      total + (property.units ? parseInt(property.units) : 0),
    0
  );
  const totalUnits = parseInt(property.units) + parseInt(totalAddProUnit);

  // For Subscription Dialog For Public Url
  const user_id = localStorage.getItem("user_id");
  const [showDialog, setShowDialog] = useState(false);

  let addPropertySquareFeet = 0;
  // Calculate the total square feet
  for (const property of propertyList) {
    const squareFeet = property.square_feet
      ? property.square_feet.replace(/,/g, "")
      : 0;
    addPropertySquareFeet += parseInt(squareFeet);
  }

  const totalSquareFeet =
    parseInt(property.square_feet) + parseInt(addPropertySquareFeet);

  const next = () => {
    CarouselRef.current.next();
  };
  const previous = () => {
    CarouselRef.current.prev();
  };
  const sponsorHandler = () => {
    if (user_id) {
      console.log("navigate");
      navigate(`/dashboard/sponsor-details/${sponsor.id}`);
    } else {
      setShowDialog(true);
    }
  };

  console.log("sponsor is ", sponsor);

  // Geolocation When Page Load
  useEffect(() => {
    const getLocation = async (address, city, state) => {
      try {
        let results = await getGeocode({ address });
        console.log("mapd", results);
        if (results.length === 0) {
          throw new Error("No results found for address");
        }
        const { lat, lng } = await getLatLng(results[0]);
        setCenter({
          lat: lat,
          lng: lng,
        });
      } catch (error) {
        console.log("😱 Error: ", error);
        console.log("Error: ", error);

        try {
          let cityResults = await getGeocode({ address: city });
          console.log("mapd with city", cityResults);
          if (cityResults.length === 0) {
            throw new Error("No results found for city");
          }
          const { lat, lng } = await getLatLng(cityResults[0]);
          setCenter({
            lat: lat,
            lng: lng,
          });
        } catch (error) {
          console.log("😱 Error: ", error);
          console.log("Error: ", error);
        }
      }
    };

    getLocation(property.address, property.city, property.state);
  }, [property.address, property.city]);

  console.log(property.address, property.city, property.state);

  const getLocationNew = async (address, city) => {
    try {
      let results = await getGeocode({ address });
      console.log("mapd", results);
      if (results.length === 0) {
        throw new Error("No results found for address");
      }
      const { lat, lng } = await getLatLng(results[0]);
      setCenter({
        lat: lat,
        lng: lng,
      });
    } catch (error) {
      console.log("😱 Error: ", error);
      console.log("Error: ", error);

      try {
        let cityResults = await getGeocode({ address: city });
        console.log("mapd with city", cityResults);
        if (cityResults.length === 0) {
          throw new Error("No results found for city");
        }
        const { lat, lng } = await getLatLng(cityResults[0]);
        setCenter({
          lat: lat,
          lng: lng,
        });
      } catch (error) {
        console.log("😱 Error: ", error);
        console.log("Error: ", error);
      }
    }
  };

  console.log("center", center);
  const mapContainerStyle = {
    height: "130px",
    width: "100%",
  };
  const options = {
    // styles: mapStyles,
    disableDefaultUI: true,
    zoomControl: true,
  };
  const omHandler = () => {
    let file = document[0]?.file;
    const file_name = document[0]?.file_name;
    if(window.location.hostname !== 'localhost'){
      file = file.replace("http://", "https://");
    }
    console.log('file', file, file_name)
    saveAs(file, file_name);
  };
  // const omHandler = () => {

  //   if(user_id){
  //     const file = document[0]?.file;
  //     const file_name = document[0]?.file_name;
  //     saveAs(file, file_name);
  //   }
  //   else{
  //     setShowDialog(true)
  //   }
  // };
  if (loadError) return "Error";
  if (!isLoaded) return "Loading...";

  return (
    <div>
      <div className="row">
        <div className="col-md-12 col-lg-12 col-xl-7">
          <div className="card data-card h-100">
            <div className="card-body">
              <div className="hero">
                <div className="d-flex justify-content-between align-items-start">
                  {sponsor?.company ? (
                    <div className="d-lg-flex justify-content-between align-items-center">
                      <Price className="fs-16 mb-0 ">
                        {/* {sponsor?.company ? sponsor?.company : "Deal"} */}
                        {property.deal_name}
                      </Price>
                      <span
                        class="badge rounded-pill ms-3 font-monospace fs-14"
                        style={{
                          backgroundColor: "rgb(43, 68, 121)",
                          cursor: "pointer",
                        }}
                        onClick={sponsorHandler}
                      >
                        Profile
                      </span>
                    </div>
                  ) : (
                    <Price className="fs-16 mb-4">{property.deal_name}</Price>
                  )}
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "#2B4479", }}
                    onClick={omHandler}
                  >
                    <img src={Arrow} alt="" className="me-1" />
                    OM
                  </Button>
                </div>
                {/* Show Subscribe Dialog Popup for Public Url  */}
                {showDialog && (
                  <SubscribeDialog
                    showDialog={showDialog}
                    setShowDialog={setShowDialog}
                  />
                )}
                <hr/>

                {/* <div className="row">
                  <div
                    className="col-md-6 mb-1 fw-6 fs-6"
                    style={{ marginTop: "20px", fontWeight: "bold" }}
                  >
                    
                  </div>
                  <div className="col-md-6 text-end">
                    <span
                      class="badge rounded-pill ms-3 font-monospace fs-14"
                      style={{
                        backgroundColor: "rgb(43, 68, 121)",
                        cursor: "pointer",
                        marginTop: "20px",
                      }}
                      onClick={() => getLocationNew(property.address)}
                    >
                      Locate
                    </span>
                  </div>
                </div> */}
<div className="row mt-3">
  <div className="col-sm-12 col-md-6 col-lg-3 col-xl-2 d-md-block d-flex">
    <p className="fs-14 mb-0 fw-6 d-flex flex-nowrap">
      Property Title <span className="d-md-none mx-2"> : </span>
    </p>
    <p className="fs-16">{property.property_title}</p>
  </div>
  <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4 d-md-block d-flex">
    <p className="fs-14 mb-0 fw-6 d-flex flex-nowrap">
      Address <span className="d-md-none mx-2"> : </span>
      <div className="d-md-flex d-none">
        <span
          className="badge rounded-pill ms-3 font-monospace"
          style={{
            backgroundColor: "rgb(43, 68, 121)",
            cursor: "pointer",
            padding: "6px",
            fontSize: "14px",
          }}
          onClick={() => getLocationNew(property.address, property.city)}
        >
          Map
        </span>
      </div>
    </p>
    <p className="fs-16 flex-wrap">{property.address}</p>
    <div className="d-md-none d-flex ms-auto">
      <Button
        variant="contained"
        style={{
          backgroundColor: "#2B4479",
          cursor: "pointer",
          padding: "8px",
          fontSize: "12px",
          marginLeft: "8px",
          maxHeight: '40px',
        }}
        className="d-flex flex-no-wrap"
        onClick={() => getLocationNew(property.address, property.city)}
      >
        View map
      </Button>
    </div>
  </div>
  <div className="col-sm-12 col-md-3 col-lg-2 col-xl-2 d-md-block d-flex">
    <p className="fs-14 mb-0 fw-6 d-flex flex-nowrap">
      City
      <span className="d-md-none mx-2"> : </span>
    </p>
    <p className="fs-16">{property.city}</p>
  </div>
  <div className="col-sm-12 col-md-3 col-lg-2 col-xl-2 d-md-block d-flex">
    <p className="fs-14 mb-0 fw-6 d-flex flex-nowrap">
      State
      <span className="d-md-none mx-2"> : </span>
      </p>
    <p className="fs-16">{property.state}</p>
  </div>
  <div className="col-sm-12 col-md-3 col-lg-1 col-xl-2 d-md-block d-flex">
    <p className="fs-14 mb-0 fw-6 d-flex flex-nowrap">
      Units
      <span className="d-md-none mx-2"> : </span>
      </p>
    <p className="fs-16">{property.units}</p>
  </div>
</div>


                <hr />

                {/* Show Additional Property  */}
                {propertyList.map((property, index) => (
                  <>
                    {/* <div className="row">
                      <div
                        className="col-md-6 mb-1 fw-6 fs-6"
                        style={{ marginTop: "10px", fontWeight: "bold" }}
                      >
                        
                      </div>
                      <div className="col-md-6 text-end">
                        <span
                          class="badge rounded-pill ms-3 font-monospace fs-14"
                          style={{
                            backgroundColor: "rgb(43, 68, 121)",
                            cursor: "pointer",
                          }}
                          onClick={() => getLocationNew(property.address)}
                        >
                          Locate
                        </span>
                      </div>
                    </div> */}

<div className="row mt-3">
  <div className="col-sm-12 col-md-6 col-lg-3 col-xl-2 d-md-block d-flex">
    <p className="fs-14 mb-0 fw-6 d-flex flex-nowrap">
      Property Title <span className="d-md-none mx-2"> : </span>
    </p>
    <p className="fs-16">{property.property_title}</p>
  </div>
  <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4 d-md-block d-flex">
    <p className="fs-14 mb-0 fw-6 d-flex flex-nowrap">
      Address <span className="d-md-none mx-2"> : </span>
      <div className="d-md-flex d-none">
        <span
          className="badge rounded-pill ms-3 font-monospace"
          style={{
            backgroundColor: "rgb(43, 68, 121)",
            cursor: "pointer",
            padding: "6px",
            fontSize: "14px",
          }}
          onClick={() =>
            setCenter({
              lat: property.lat,
              lng: property.lng,
            })
          }
        >
          Map
        </span>
      </div>
    </p>
    <p className="fs-16 flex-wrap">{property.address}</p>
    <div className="d-md-none d-flex ms-auto">
      <Button
        variant="contained"
        style={{
          backgroundColor: "#2B4479",
          cursor: "pointer",
          padding: "8px",
          fontSize: "12px",
          marginLeft: "8px",
          maxHeight: '40px',
        }}
        className="d-flex flex-no-wrap"
        onClick={() =>
          setCenter({
            lat: property.lat,
            lng: property.lng,
          })
        }
      >
        View map
      </Button>
    </div>
  </div>
  <div className="col-sm-12 col-md-3 col-lg-2 col-xl-2 d-md-block d-flex">
    <p className="fs-14 mb-0 fw-6 d-flex flex-nowrap">
      City
      <span className="d-md-none mx-2"> : </span>
    </p>
    <p className="fs-16">{property.city}</p>
  </div>
  <div className="col-sm-12 col-md-3 col-lg-2 col-xl-2 d-md-block d-flex">
    <p className="fs-14 mb-0 fw-6 d-flex flex-nowrap">
      State
      <span className="d-md-none mx-2"> : </span>
    </p>
    <p className="fs-16">{property.state}</p>
  </div>
  <div className="col-sm-12 col-md-3 col-lg-1 col-xl-2 d-md-block d-flex">
    <p className="fs-14 mb-0 fw-6 d-flex flex-nowrap">
      Units
      <span className="d-md-none mx-2"> : </span>
    </p>
    <p className="fs-16">{property.units}</p>
  </div>
</div>


                    <hr />
                  </>
                ))}

                {/* <div className="mt-2">
                  <p className="fs-15 mb-1 fw-5">Description</p>
                  <div
                    style={{
                      overflow: "auto",
                      height: "110px",
                      overflowX: "hidden",
                      scrollbarWidth: "thin",
                    }}
                  >
                    <p className="fs-14">{property.description}</p>
                  </div>
              
                </div> */}

                {propertyList.length < 2 && (
                  <div className="mt-2">
                    <p className="fs-15 mb-1 fw-5">Description</p>
                    <div
                      style={{
                        overflow: "auto",
                        height: "110px",
                        overflowX: "hidden",
                        scrollbarWidth: "thin",
                      }}
                    >
                      <p className="fs-14">{property.description}</p>
                    </div>
                  </div>
                )}
              </div>

              <div className="row p-0">
                <div className="col-sm-12 col-md-12 col-lg-12 ps-4 pe-3 pt-3">
                  {/* <div className="google-map-box border rounded"> */}
                  <GoogleMap
                    id="map"
                    mapContainerStyle={mapContainerStyle}
                    zoom={9}
                    center={center}
                    options={options}
                    // onClick={onMapClick}
                    // onLoad={onMapLoad}
                  >
                    <Marker
                      key={`${center.lat}-${center.lng}`}
                      position={{ lat: center.lat, lng: center.lng }}
                      // onClick={() => {
                      //     setSelected(marker);
                      // }}
                      icon={{
                        url: `https://img.icons8.com/office/40/000000/marker.png`,
                        origin: new window.google.maps.Point(0, 0),
                        anchor: new window.google.maps.Point(15, 15),
                        scaledSize: new window.google.maps.Size(30, 30),
                      }}
                    />
                  </GoogleMap>
                  {/* </div> */}
                </div>
                {/* <div className='col mt-1'>
                  {property?.image_data?.length > 0 && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <IconButton onClick={previous}>
                        <KeyboardArrowLeftSharpIcon />
                      </IconButton>
                      <div
                        className='single-item'
                        style={{
                          display: 'block',
                          width: 100,
                          flex: 1,
                          cursor: "pointer"
                        }}
                      >
                        <Carousel
                          ref={(carousel) => (CarouselRef.current = carousel)}
                          asNavFor={CarouselMediaRef.current}
                          autoplay
                          dots={false}
                          slidesToShow={4}
                          centerMode={true}
                          //   swipeToSlide={true}
                          touchThreshold={50}
                          focusOnSelect={true}
                          draggable={true}
                          infinite={property?.image_data?.length > 5}

                        // afterChange={Index}
                        >
                          {property?.image_data?.map((img, index) => (
                            <div key={index}>
                              <img
                                className='img-fluid'
                                src={img.images}
                                alt=''
                                style={{
                                  minHeight: '90px',
                                  height: '90px',
                                  objectFit: 'cover',
                                  width: '90%',
                                }}
                              />
                            </div>
                          ))}
                        </Carousel>
                      </div>
                      <IconButton onClick={next}>
                        <KeyboardArrowRightSharpIcon />
                      </IconButton>
                    </div>
                  )}
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-lg-12 col-xl-5 investor-image-div h-100">
          <div>
            {property?.image_data?.length > 0 ? (
              <div className="single-item">
                <Carousel
                  ref={(carousel) => (CarouselMediaRef.current = carousel)}
                  autoplay
                  dots={false}
                  asNavFor={CarouselRef.current}
                  // afterChange={Index}
                >
                  {property.image_data.map((img, index) => (
                    <div key={index}>
                      <img
                        className="img-fluid w-100 investor-deal-image rounded"
                        src={img.images}
                        alt=""
                        style={{
                          // minHeight: '400px',
                          objectFit: "content",
                        }}
                      />
                    </div>
                  ))}
                </Carousel>
              </div>
            ) : (
              <div className="single-item">
                <img
                  className="img-fluid w-100 investor-deal-image rounded"
                  src={DefaultImage}
                  alt=""
                  style={{
                    // minHeight: '400px',
                    objectFit: "content",
                  }}
                />
              </div>
            )}
          </div>
          <div className="col mt-1 investor-slide-box border me-3 ms-3  mt-lg-1 pt-lg-0 pb-lg-0 mt-md-2 pt-md-1 pb-md-1">
            {property?.image_data?.length > 0 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <IconButton onClick={previous}>
                  <KeyboardArrowLeftSharpIcon />
                </IconButton>
                <div
                  className="single-item mobile-res"
                  style={{
                    display: "block",
                    width: "80%",
                    flex: 1,
                    cursor: "pointer",
                  }}
                >
                  <Carousel
                    ref={(carousel) => (CarouselRef.current = carousel)}
                    asNavFor={CarouselMediaRef.current}
                    autoplay
                    dots={false}
                    slidesToShow={3}
                    centerMode={true}
                    swipeToSlide={true}
                    touchThreshold={5}
                    focusOnSelect={true}
                    draggable={true}
                    infinite={property?.image_data?.length > 5}

                    // afterChange={Index}
                  >
                    {property?.image_data?.map((img, index) => (
                      <div key={index} className="pe-2 investor-small-image">
                        <img
                          className="img-fluid"
                          src={img.images}
                          alt=""
                          style={{
                            height: "100%",
                            objectFit: "content",
                            width: "100%",
                          }}
                        />
                      </div>
                    ))}
                  </Carousel>
                </div>
                <IconButton onClick={next}>
                  <KeyboardArrowRightSharpIcon />
                </IconButton>
              </div>
            )}
          </div>

          {propertyList.length > 1 && (
            <div className="mt-4">
              <p className="fs-15 mb-1 fw-6">Description</p>
              <div
                style={{
                  overflow: "auto",
                  height: "110px",
                  overflowX: "hidden",
                  scrollbarWidth: "thin",
                }}
              >
                <p className="fs-14">{property.description}</p>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="mb-3 mt-1"></div>
      <div className="row mb-3">
        <div className="col">
          <div className="card inv-card">
              <MetricsContainer>
            <div className="card-body">
              <div className="small-screen-metrics">
                            <div className="row g-3">
      <div className="col-6 col-md-3">
        <MetricCard 
          title="Total Units" 
          value={totalUnits ? numberWithCommas(totalUnits) : 0}
        />
      </div>
      <div className="col-6 col-md-3">
        <MetricCard 
          title="Total Square Feet" 
          value={totalSquareFeet ? numberWithCommas(totalSquareFeet) : 0}
        />
      </div>
      <div className="col-6 col-md-3">
        <MetricCard 
          title="Investment Term" 
          value={`${terms} Year`}
        />
      </div>
      <div className="col-6 col-md-3">
        <MetricCard 
          title="Purchase Price" 
          value={`$${property.purchase_price && numberWithCommas(property.purchase_price)}`}
        />
      </div>
      <div className="col-6 col-md-3">
        <MetricCard 
          title="Total Capitalization" 
          value={`$${total && numberWithCommas(total)}`}
        />
      </div>
      <div className="col-6 col-md-3">
        <MetricCard 
          title="Total Shares" 
          value={`$${numberWithCommas(capAmount)}`}
        />
      </div>
      <div className="col-6 col-md-3">
        <MetricCard 
          title="Projected Deal Cash Flow" 
          value={`$${numberWithCommas(totalCashflow)}`}
        />
      </div>
      <div className="col-6 col-md-3">
        <MetricCard 
          title="Deal Level IRR" 
          value={`${targeted_irr} %`}
        />
      </div>
 </div>
              </div>

            <div className="large-screen-metrics">
            <div className="row m-0 position-relative">
                <div className="col-lg-6 col-md-12 col-sm-12 p-0">
                  <div className="container-fluid p-0">
                    <div className="row m-0">
                      <div className="col-lg-6 col-md-6 col-sm-6 p-0">
                        <div className="container-fluid p-0">
                          <div className="row m-0">
                            <div className="col-lg-6 col-md-6 col-sm-6  p-0">
                              <div className="sub-card position-relative">
                                <div className="text-center">
                                  <h6 className="text-muted fs-12 mb-1">
                                    Total Units
                                  </h6>
                                  <span className="fs-16 fw-4 text-black fw-6">
                                    {totalUnits
                                      ? numberWithCommas(totalUnits)
                                      : 0}{" "}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6  p-0">
                              <div className="sub-card position-relative">
                                <div className="text-center">
                                  <h6 className="text-muted fs-12 mb-1">
                                    Total Square Feet
                                  </h6>
                                  <span className="fs-16 fw-4 text-black fw-6">
                                    {totalSquareFeet
                                      ? numberWithCommas(totalSquareFeet)
                                      : 0}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-6 p-0">
                        <div className="container-fluid p-0">
                          <div className="row m-0">
                            <div className="col-lg-6 col-md-6  p-0">
                              <div className="sub-card position-relative">
                                <div className="text-center">
                                  <h6 className="text-muted fs-12 mb-1">
                                    Investment Term
                                  </h6>
                                  <span className="fs-16 fw-4 text-black fw-6">
                                    {terms} Year{" "}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 p-0">
                              <div className="sub-card position-relative">
                                <div className="text-center">
                                  <h6 className="text-muted fs-12 mb-1">
                                    Purchase Price
                                  </h6>
                                  <span className="fs-16 fw-4 text-black fw-6">
                                    $
                                    {property.purchase_price &&
                                      numberWithCommas(property.purchase_price)}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12  p-0">
                  <div className="container-fluid p-0">
                    <div className="row m-0">
                      <div className="col-lg-6 col-md-6 p-0">
                        <div className="container-fluid p-0">
                          <div className="row m-0">
                            <div className="col-lg-6 col-md-6  p-0">
                              <div className="sub-card position-relative">
                                <div className="text-center">
                                  <h6 className="text-muted fs-12 mb-1">
                                    Total Capitalization
                                  </h6>
                                  <span className="fs-16 fw-4 text-black fw-6">
                                    ${total && numberWithCommas(total)}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 p-0">
                              <div className="sub-card position-relative">
                                <div className="text-center">
                                  <h6 className="text-muted fs-12 mb-1">
                                    Total Shares
                                  </h6>
                                  <span className="fs-16 fw-4 text-black fw-6">
                                    ${numberWithCommas(capAmount)}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6  p-0">
                        <div className="container-fluid p-0">
                          <div className="row m-0">
                            <div className="col-lg-6 col-md-6 p-0">
                              <div className="sub-card position-relative">
                                <div className="text-center">
                                  <h6 className="text-muted fs-12 mb-1">
                                    Projected Deal Cash Flow
                                  </h6>
                                  <span className="fs-16 fw-4 text-black fw-6">
                                    ${numberWithCommas(totalCashflow)}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 p-0">
                              <div className=" position-relative">
                                <div className="text-center">
                                  <h6 className="text-muted fs-12 mb-1">
                                    Deal Level IRR
                                  </h6>
                                  <span className="fs-16 fw-4 text-black fw-6">
                                    {targeted_irr} %
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            </div>
            </MetricsContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewDealDetails;
