import { message } from 'antd';
import React, { useEffect, useState } from "react";
import {
  Button,
  Stepper,
  Step,
  StepLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import getStepContent from './Stepper';
import { useParams } from 'react-router-dom';


function getSteps() {
  return [
    "Property Info",
    "Metrics & Capitalization",
    "Cash Flows",
    "Capital Stack and Waterfall",
    "Waterfall Summary",
    "Deal Summary",
  ];
}

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));

const LinearStepperDetails = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [skippedSteps, setSkippedSteps] = useState([]);
  const steps = getSteps();
  const [update, setUpdate] = useState()
  const { id } = useParams()


  const isStepOptional = (step) => {
    return step === 1 || step === 2;
  };

  const isStepSkipped = (step) => {
    return skippedSteps.includes(step);
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
    setSkippedSteps(skippedSteps.filter((skipItem) => skipItem !== activeStep));
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleSkip = () => {
    if (!isStepSkipped(activeStep)) {
      setSkippedSteps([...skippedSteps, activeStep]);
    }
    setActiveStep(activeStep + 1);
  };
  
  useEffect(()=>{
    setUpdate(!update)
  }, [update])

  return (
    <div style={{ margin:"10px" }}>
      <div className='step-header'>
        <div className='add_deal' style={{ background:"#3D61AE" }}>
          Add New Deal</div>
        <div>
          <Stepper alternativeLabel activeStep={activeStep}>
            {steps.map((step, index) => {
              const labelProps = {};
              const stepProps = {};
              return (
                // <div>
                <Step {...stepProps} key={index} onClick={()=>setActiveStep(index)} style={{cursor:"pointer"}}>
                  <StepLabel {...labelProps}><p className='step-label' >{step}</p></StepLabel>
                </Step>
                // </div>
              );
            })}
          </Stepper>
        </div>
      </div>
      <div>
        {getStepContent(activeStep, setActiveStep)}
      </div>

      <div className={"d-flex align-items-center mt-3 " + (activeStep === 0 ? "justify-content-end" : "justify-content-between")}>
        {activeStep > 0 && (
          <div className='text-primary'>
            <BsArrowLeft className='mr-2' />
            <Button className='text-primary' onClick={() => handleBack()}>
              Back
            </Button>
          </div>
        )}
        {activeStep < steps.length - 1 && (
          <div className='rounded px-2 text-white' style={{ background:"#3D61AE" }}>
            <Button className='text-white' onClick={() => handleNext()} style={{ fontSize: '.8rem' }}>
              {steps[activeStep + 1]}
            </Button>
            <BsArrowRight className='ml-2' />
          </div>
        )}
        
        {activeStep === steps.length - 1 && (
          <div className='bg-success rounded px-2 text-white'>
            {/* <Button type="primary" className='text-white' onClick={() => message.success('Deal Saved!')}>
              Share
            </Button> */}
          </div>
        )}

      </div>
    </div>
  );
};

export default LinearStepperDetails;
