import { createSlice } from '@reduxjs/toolkit'

const dealSlice = createSlice({
  name: 'deal',
  initialState:{
    openDealData: null,
    closeDealData: null,
    open: true,
}, 
  reducers:{
    replaceOpenDeal(state, action){
        state.openDealData = action.payload
    },
    replaceCloseDeal(state, action){
        state.closeDealData = action.payload
    },
    openHandler(state){
      state.open = true
    },
    closeHandler(state){
      state.open = false
    }
  }
})

export const dealActions = dealSlice.actions
export default dealSlice