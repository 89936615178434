import numberWithCommas from "../../utilities/extrafunction/commaseparate"

const MetricsDetails = ({ metrics, total }) => {
    return (
        <div className="row p-3 pb-4" style={{ fontFamily: "Poppins", color: "#404756", height: "1rem auto" }}>
            <div class='mb-4'>
                <div class='d-flex justify-content-between align-content-center'>
                    <h2 class='mb-0 fw-6 fs-6'>Metrics and Capitalization :</h2>
                    <h2 class='mb-0 fs-15 fw-5' style={{ color: "#A2ACBD", letterSpacing: '0.2px', }}>Total : 
                        <span class='fw-bold text-black'> ${numberWithCommas(total)}</span>
                        {/* <span class='fw-bold text-black'> ${numberWithCommas(total)}</span> */}
                        {/* <span class='fw-bold text-black'>${capital ? numberWithCommas(capital) : 0}</span> */}
                    </h2>
                </div>
            </div>
            <div className="col-lg-12">
                <div className="row">
                    <div className="col-3">
                        <div className="metrics-text-1">
                            Purchase Price
                        </div>
                        <div className="metrics-text-2">
                            {metrics.purchase_price ? numberWithCommas(metrics.purchase_price) : ''}
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="metrics-text-1">
                            Closing Cost
                        </div>
                        <div className="metrics-text-2">
                            {metrics.closing_cost ? numberWithCommas(metrics.closing_cost) : ''}
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="metrics-text-1">
                            Capex
                        </div>
                        <div className="metrics-text-2">
                            {metrics.capex ? numberWithCommas(metrics.capex) : ''}
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="metrics-text-1">
                            Reservers
                        </div>
                        <div className="metrics-text-2">
                            {metrics.reserves ? numberWithCommas(metrics.reserves) : ''}
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-3">
                        <div className="metrics-text-1">
                            Entry NOI
                        </div>
                        <div className="metrics-text-2">
                        {metrics.entry_noi ? numberWithCommas(metrics.entry_noi) : ''}
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="metrics-text-1">
                            Stabilized NOI
                        </div>
                        <div className="metrics-text-2">
                        {metrics.stabilized_noi ? numberWithCommas(metrics.stabilized_noi) : ''}
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="metrics-text-1">
                            Proforma NOI
                        </div>
                        <div className="metrics-text-2">
                        {metrics.pro_noi ? numberWithCommas(metrics.pro_noi) : ''}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MetricsDetails