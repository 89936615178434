import { useEffect } from "react"
import numberWithCommas from "../../utilities/extrafunction/commaseparate"

const CashflowDetails = ({ opCashFlow, setOpCashFlow, cpCashFlow, setCpCashFlow, terms, totalOpCashFlow, totalCpCashFlow }) => {

    useEffect(() => {
        let op = []
        let cp = []
        for (let i = 1; i <= terms; i++) {
            op.push({ year: i, value: '' },)
            cp.push({ year: i, value: '' },)
        }
        console.log('opCashFlow', opCashFlow)
        if (opCashFlow.length > 0 && terms > opCashFlow.length) {
            let opf = [...opCashFlow]
            let cpf = [...cpCashFlow]
            for (let i = opf.length + 1; i <= terms; i++) {
                opf.push({ year: i, value: '' },)
                cpf.push({ year: i, value: '' },)
            }
            setOpCashFlow(opf)
            setCpCashFlow(cpf)
        }
        else if (opCashFlow.length > terms) {
            let opf = [...opCashFlow].slice(0, terms)
            let cpf = [...cpCashFlow].slice(0, terms)
            setOpCashFlow(opf)
            setCpCashFlow(cpf)
        }
        else if (opCashFlow.length > 0 || cpCashFlow.length > 0) {
            setOpCashFlow(opCashFlow)
            setCpCashFlow(cpCashFlow)
        }
        else {
            setOpCashFlow(op)
            setCpCashFlow(cp)
        }
    }, [terms])

    const op = opCashFlow.map(dt =>
        <div className="col">
            <div className="cashflow-years">
                {dt.year} Year
            </div>
            <div className="pb-3">
                {numberWithCommas(dt.value)}
            </div>
        </div>
    )

    const cp = cpCashFlow.map(dt =>
        <div className="col">
            <div className="cashflow-years">
                {dt.year} Year
            </div>
            <div className="pb-3">
                {numberWithCommas(dt.value)}
            </div>
        </div>
    )

    return (
        <div className="row p-3 pb-4" style={{ fontFamily: "Poppins", color: "#404756", height: "1rem auto" }}>
            <div class='mb-4'>
                <div class='d-flex justify-content-between align-content-center'>
                    <h2 class='mb-0 fw-6 fs-6'>Cash FLows</h2>
                    <h2 class='mb-0 fs-15 fw-5' style={{ color: "#A2ACBD", letterSpacing: '0.2px', }}>Investment Terms :
                        <span class='fw-bold text-black'> {terms} years</span>
                        {/* <span class='fw-bold text-black'> ${numberWithCommas(total)}</span> */}
                        {/* <span class='fw-bold text-black'>${capital ? numberWithCommas(capital) : 0}</span> */}
                    </h2>
                </div>
            </div>
            <div className="opDheader">
                <div class='d-flex justify-content-between align-content-center mb-2'>
                    <h2 class='cashflow-test-1'>Operating Cash Flows</h2>
                    <h2 class='mb-0 fs-14 fw-5' style={{ color: "#A2ACBD", letterSpacing: '0.2px', }}>Total :
                        <span class='fw-bold text-black'> ${numberWithCommas(totalOpCashFlow)}</span>
                        {/* <span class='fw-bold text-black'> ${numberWithCommas(total)}</span> */}
                        {/* <span class='fw-bold text-black'>${capital ? numberWithCommas(capital) : 0}</span> */}
                    </h2>
                </div>
            </div>
            <div className="row px-4">
                {op}
            </div>
            <div className="opDheader mt-4">
                <div class='d-flex justify-content-between align-content-center mb-2'>
                    <h2 class='cashflow-test-1'>Capital Event Cash Flows</h2>
                    <h2 class='mb-0 fs-14 fw-5' style={{ color: "#A2ACBD", letterSpacing: '0.2px', }}>Total :
                        <span class='fw-bold text-black'> ${numberWithCommas(totalCpCashFlow)}</span>
                        {/* <span class='fw-bold text-black'> ${numberWithCommas(total)}</span> */}
                        {/* <span class='fw-bold text-black'>${capital ? numberWithCommas(capital) : 0}</span> */}
                    </h2>
                </div>
            </div>
            <div className="row px-4">
                {cp}
            </div>
        </div>
    )
}

export default CashflowDetails