import { fontSize } from '@mui/system';
import DoughnutChart from './DoughnutChart';
import DemoPie from './PieChart';
import MainClient from '../../utilities/MainClient';
import { useEffect, useState } from 'react';
import numberWithCommas from '../../utilities/extrafunction/commaseparate';
import { capitalize } from '@mui/material';
import styled from 'styled-components';
import TotalChart from './TotalChart';
import { BarChart } from './BarChart';
import NewHeader from '../static/NewHeader';
import faker from 'faker';
import InvestorDashboardData from '../../data/Investordashboard';
import { TotalBarChart } from './TotalBarChart';
import numFormatter from '../../utilities/extrafunction/numberConveter';

const Text = styled.div`
  color: #767676;
  font-size: 16px;
  font-weight: 500;
  @media (min-width: 1200px) and (max-width: 1440px) {
    font-size: 14px;
  }
  @media (min-width: 768px) and (max-width: 1200px) {
    font-size: 12px;
  }
  @media (min-width: 300px) and (max-width: 786px) {
    font-size: 12px;
  }
`;
const ClassText = styled.div`
  color: #767676;
  font-size: 16px;
  font-weight: 500;
  @media (min-width: 1200px) and (max-width: 1440px) {
    font-size: 14px;
  }
  @media (min-width: 768px) and (max-width: 1200px) {
    font-size: 10px;
  }
  @media (max-width: 560px) {
    font-size: 10px;
  }
`;
const Price = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.05em;
  color: ${(props) => props.value > 0 ? '#2b4479' : '#000'};
  margin-top: 0.4rem;
  @media (min-width: 1200px) and (max-width: 1440px) {
    font-size: 20px;
  }
  @media (min-width: 768px) and (max-width: 1200px) {
    font-size: 16px;
  }
`;
const Wapper = styled.div`
  background-color: transparent;
`;
const ChartWapper = styled.div`
  background-color: transparent;
  width: 140px;
  text-align: center;
  @media (min-width: 1440px) and (max-width: 2506px) {
    width: 180px;
  }
  @media (min-width: 1200px) and (max-width: 1440px) {
    width: 120px;
  }
  @media (min-width: 768px) and (max-width: 1200px) {
    width: 100px;
  }
`;
const BarChartWapper = styled.div`
  background-color: transparent;
  height: 200px;
  text-align: center;
  @media (min-width: 1440px) and (max-width: 2506px) {
    width: 240px;
  }
  @media (min-width: 1200px) and (max-width: 1440px) {
    height: 180px;
  }
  @media (min-width: 768px) and (max-width: 1200px) {
    height: 160px;
  }
`;
const ColCard = styled.div`
  background: #ffffff;
  box-shadow: -2px -2px 10px rgba(0, 0, 0, 0.15),
    2px 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
`;
const ColCardTotalSubscription = styled.div`
  background: #ffffff;
  box-shadow: -2px -2px 10px rgba(0, 0, 0, 0.15),
    2px 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
`;
const DealName = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #767676;
`;
const Bar = styled.div`
  width: 0.5rem;
  height: ${(props) => props.value}%;
  background: ${(props) => props.color};
  border-radius: 5px;
`;

const GraphWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 160px;
  height: 100px;
  @media(max-width: 580px){
    width: 140px;
  }
`

const SubscribedGraphContainer = styled.div`
    display: flex;
    justify-content: space-between;
    // border: 1px solid red;
    width: 100%;
    height: 100px;
`
const Graph = styled.div`
    width: 60px;
    height: ${(props) => props.percentage}%;
    background-color: ${(props) => props.color ? props.color : ''};
    border-radius: 6px 6px 0 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    @media(max-width: 580px){
      width: 50px;
    }
`
const GraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100%
`
const GraphText = styled.div`
    font-size: 14px;
    color: #0D0D0D;
    font-weight: 600;
    text-align: center;
    @media(max-width: 580px){
      font-size: 12px;
    }
`
const GraphPrice = styled.div`
    font-size: 14px;
    color: #FFFFFF;
    @media(max-width: 580px){
      font-size: 12px;
    }
`

const ClassGraphContainer = styled.div`
    width: 100%;
    height: 190px;
    display: flex;
    justify-content: space-between;
    @media (min-width: 1200px) and (max-width: 1440px) {
      height: 180px;
    }
    @media (min-width: 768px) and (max-width: 1200px) {
      height: 160px;
    }
    @media (max-width: 785px){
      width: 180px;
    }
`
const ClassesGraph = styled.div`
    width: 60px;
    height: ${(props) => props.percentage}%;
    background-color: ${(props) => props.color ? props.color : ''};
    border-radius: 6px 6px 0 0;
    display: flex;
    align-items: end;
    justify-content: center;
    @media(max-width: 580px){
      width: 50px;
    }
`

const InvestorDashboard = ({ userType }) => {
  const [dashboardData, setDashboardData] = useState([]);

  useEffect(() => {
    const getData = () => {
      const fetchClient = new MainClient('/investor/dashboard');
      const response = fetchClient.getAPI();
      response
        .then((res) => {
          console.log('dashboard', res.data.result);
          setDashboardData(res.data.result);
        })
        .catch((err) => console.log(err));
    };
    console.log("admin", userType)
    if (userType == "admin") {
      setDashboardData(InvestorDashboardData);
      console.log("adminDashboard", InvestorDashboardData)
    } else {
      getData();
    }
  }, [userType]);
  const iconcolor = [
    '#304C89',
    '#A9AFD1',
    '#ADA9B7',
    '#C0BDBE',
    '#7a7d7d',
    '#4b5354',
    '#383c3d',
    '#000000',
  ];
  const iconcolor2 = ['#A9AFD1', '#ADA9B7', '#C0BDBE'];

  const graphdataTotal =
    dashboardData?.subscribed_deal?.length > 0
      ? dashboardData?.subscribed_deal
        ?.map((dt) => dt.amount_subscribed)
        ?.reduce((a, b) => a + b)
      : 0;
  const graphdata = dashboardData?.subscribed_deal?.map((dt) =>
    ((dt.amount_subscribed * 100) / graphdataTotal).toFixed(2)
  );
  const graphLable = dashboardData?.subscribed_deal?.map((dt) =>
    dt.deal_name.slice(0, 13)
  );
  console.log('total deals', dashboardData);
  const classLable = dashboardData?.class_allocation?.map((dt) =>
    capitalize(dt.class_name)
  );
  const classTotal = dashboardData?.class_allocation
    ?.map((dt) => dt.amount)
    .reduce((a, b) => a + b);
  const classPersentage = dashboardData?.class_allocation?.map((dt) =>
    ((dt.amount * 100) / classTotal).toFixed()
  );
  console.log('percentage', classPersentage)

  const pTotal =
    dashboardData?.pending_subscription?.length > 0
      ? dashboardData?.pending_subscription
        ?.map((dt) => dt.subscription_request)
        ?.reduce((a, b) => a + b)
      : 0;
  const pdata = dashboardData?.pending_subscription?.map((dt) =>
    ((dt.subscription_request * 100) / pTotal).toFixed(2)
  );
  const pLable = dashboardData?.pending_subscription?.map((dt) =>
    dt.deal_name.slice(0, 13)
  );
  const color = [
    '#2B4479',
    '#5D91FF',
    '#B2CBFF',
    '#8BB0FF',
    '#3D61A',
    '#1D2E52',
  ];
  console.log('pData', pdata);
  const data = {
    labels: graphLable,
    datasets: [
      {
        label: 'Attendance for Week 1',
        data: graphdata,
        borderColor: ['#fff'],
        backgroundColor: color,
        pointBackgroundColor: 'rgba(255,206,86,0.2)',
        centerText: {
          display: true,
          text: '280',
        },
      },
    ],
  };
  const pendingColor9 = ["rgb(192 38 211)", "rgb(232 121 249)", "rgb(240 171 252)", "rgb(245 208 254)", "rgb(163 230 53)", "rgb(101 163 13)", "rgb(236 252 203)"]
  const pendingColor8 = ["rgb(168 85 247)", "rgb(192 132 252)", "rgb(216 180 254)", "rgb(34 197 94)", "rgb(163 230 53)", "rgb(101 163 13)", "rgb(236 252 203)"]
  const pendingColor = ["rgb(124 58 237)", "rgb(167 139 250)", "rgb(221 214 254)", "rgb(237 233 254)", "rgb(34 197 94)", "rgb(163 230 53)", "rgb(101 163 13)", "rgb(236 252 203)"]
  const pendingColor6 = ["rgb(129 140 248)", "rgb(165 180 252)", "rgb(199 210 254)", "rgb(224 231 255)", "rgb(34 197 94)", "rgb(163 230 53)", "rgb(101 163 13)", "rgb(236 252 203)"]
  const pendingColor5 = ["rgb(29 78 216)", "rgb(59 130 246)", "rgb(147 197 253)", "rgb(30 58 138)", "rgb(34 197 94)", "rgb(163 230 53)", "rgb(101 163 13)", "rgb(236 252 203)"]
  const pendingColor4 = ["rgb(22 163 74)", "rgb(74 222 128)", "rgb(187 247 208)", "rgb(134 239 172)", "rgb(34 197 94)", "rgb(163 230 53)", "rgb(101 163 13)", "rgb(236 252 203)"]
  const pendingColor3 = ["rgb(101 163 13)", "rgb(132 204 22)", "rgb(217 249 157)", "rgb(101 163 13)", "rgb(132 204 22)", "rgb(163 230 53)", "rgb(101 163 13)", "rgb(236 252 203)"]
  const pendingColor2 = ["#004080", "#80bfff", "#3399ff", "#0066cc", "#00264d",]
  const pendingColor1 = ["rgb(2 132 199)", "rgb(56 189 248)", "rgb(186 230 253)", "rgb(186 230 253)", "rgb(7 89 133)", "rgb(14 165 233)"]
  const pendingdata = {
    labels: pLable,
    datasets: [
      {
        label: 'Attendance for Week 1',
        data: pdata,
        borderColor: ['#fff'],
        backgroundColor: color,
        pointBackgroundColor: 'rgba(255,206,86,0.2)',
        centerText: {
          display: true,
          text: '280',
        },
      },
    ],
  };
  const effectColors = {
    highlight: 'rgba(255, 255, 255, 0.75)',
    shadow: 'rgba(0, 0, 0, 0.5)',
    glow: 'rgb(255, 255, 0)',
  };
  const tData = {
    labels: ['Total', 'Remaining'],
    datasets: [
      {
        label: 'Attendance for Week 1',
        data: [parseInt(graphdataTotal), parseInt(pTotal)],
        borderColor: ['#fff'],
        backgroundColor: ['#2B4479', '#B2CBFF'],
        shadowOffsetX: 3,
        shadowOffsetY: 3,
        shadowBlur: 10,
        shadowColor: effectColors.shadow,
        bevelWidth: 2,
        bevelHighlightColor: effectColors.highlight,
        bevelShadowColor: effectColors.shadow,
        hoverInnerGlowWidth: 20,
        hoverInnerGlowColor: effectColors.glow,
        hoverOuterGlowWidth: 20,
        hoverOuterGlowColor: effectColors.glow,
        pointBackgroundColor: 'rgba(255,206,86,0.2)',
        centerText: {
          display: true,
          text: '280',
        },
      },
    ],
  };
  const labels = ['Class A', 'Class B', 'Class C'];
  const scolor = ['#2B4479', '#5D91FF', '#B2CBFF'];
  function poolColors(a) {
    var pool = [];
    for (let i = 0; i < color.length; i++) {
      pool.push(color[i]);
    }
    return pool;
  }
  const classData = {
    labels,
    datasets: [
      {
        data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        backgroundColor: poolColors(scolor.length),
        borderRadius: 5,
        innerHeight: 50,
        // borderSkipped: false,
      },
    ],
  };
  const total = graphdataTotal + pTotal
  const subscriptionPercentage = ((graphdataTotal / total) * 100).toFixed()
  const pendingPercentage = ((pTotal / total) * 100).toFixed()
  console.log("totalGraph", subscriptionPercentage, pendingPercentage)
  return (
    <Wapper className='p-0'>
      <div className='row d-flex flex-wrap ps-2 pe-2' style={{ margin: '0 -16px' }}>
        {/* box one start */}
        <div className='col-xl-4 col-lg-6 col-md-5 p-2 d-flex justify-content-between flex-column'>
          <div className='topContainer'>
            <ColCardTotalSubscription className='col-12 p-1 ps-4'>
              <div className='row align-items-start justify-content-between'>
                <div className='col-4 my-2'>
                  <div>
                    <Text>Total Subscriptions</Text>
                  </div>
                  <div className='pt-1'>
                    <Price value={dashboardData?.total_subscription}>
                      $
                      {dashboardData?.total_subscription
                        ? numberWithCommas(dashboardData?.total_subscription)
                        : 0}
                    </Price>
                  </div>
                </div>
                <GraphWrapper
                  className='col-8 me-3 p-0 pe-2 position-relative'

                >
                  {dashboardData?.total_deals > 0 ? (
                    // <BarChartWapper>
                    // <TotalBarChart pdata={[(parseInt(graphdataTotal)), parseInt(pTotal)]} />
                    // </BarChartWapper>
                    <SubscribedGraphContainer className='position-absolute bottom-0 right-0'>
                      <GraphContainer>
                        <Graph color="#2B4479" percentage={subscriptionPercentage}>
                          <GraphPrice >${numFormatter(graphdataTotal)}</GraphPrice>
                        </Graph>
                        {/* <GraphText>$ 3M</GraphText> */}
                        <GraphText>Subscriptions</GraphText>
                      </GraphContainer>
                      <GraphContainer>
                        <Graph color="#5D91FF" percentage={pendingPercentage}>
                          <GraphPrice >${numFormatter(pTotal)}</GraphPrice>
                        </Graph>
                        {/* <GraphText>$ 3M</GraphText> */}
                        <GraphText>Pending</GraphText>
                      </GraphContainer>
                    </SubscribedGraphContainer>
                  ) : (
                    <div className=''></div>
                  )}
                </GraphWrapper>
              </div>
            </ColCardTotalSubscription>
          </div>
          <div className='bottomContainer flex-grow-1'>
            <ColCard
              className='col-12 border mt-3 '
              style={{ zIndex: '1' }}
            >
              <Text className='m-2 ms-3'>Class Allocations</Text>
              <div className='row align-items-start ps-1 position-relative'>
                <div className='col-lg-8 col-md-10 col-8 pb-1 pt-2 '>
                  {/* <BarChartWapper>
                    <BarChart pdata={classD} />
                  </BarChartWapper> */}
                  <BarChartWapper className='ps-2 h-100'>
                    <ClassGraphContainer className=' w-100'>
                      <GraphContainer>
                        <Graph color="#2B4479" percentage={dashboardData?.class_allocation?.length > 0 ? classPersentage[0] : 0}>
                          <GraphPrice >{dashboardData?.class_allocation?.length > 0 ? classPersentage[0] : 0}%</GraphPrice>
                        </Graph>
                        {/* <GraphText>$ 3M</GraphText> */}
                        <GraphText>Class A</GraphText>
                      </GraphContainer>
                      <GraphContainer>
                        <Graph color="#5D91FF" percentage={dashboardData?.class_allocation?.length > 0 ? classPersentage[1] : 0}>
                          <GraphPrice >{dashboardData?.class_allocation?.length > 0 ? classPersentage[1] : 0}%</GraphPrice>
                        </Graph>
                        {/* <GraphText>$ 3M</GraphText> */}
                        <GraphText>Class B</GraphText>
                      </GraphContainer>
                      <GraphContainer>
                        <ClassesGraph color="#B2CBFF" percentage={dashboardData?.class_allocation?.length > 0 ? classPersentage[2] : 0}>
                          <GraphPrice >{dashboardData?.class_allocation?.length > 0 ? classPersentage[2] : 0}%</GraphPrice>
                        </ClassesGraph>
                        {/* <GraphText>$ 3M</GraphText> */}
                        <GraphText>Class C</GraphText>
                      </GraphContainer>
                    </ClassGraphContainer>
                  </BarChartWapper>
                </div>
                <div className='col-6 p-0 pe-3 position-absolute top-15 end-0'>
                  <div
                    className='mb-2'
                    style={{
                      fontSize: '12px',
                      color: '#767676',
                      fontWeight: 500,
                    }}
                  >
                    {dashboardData?.class_allocation?.map((dt, index) => (
                      <div className='row mb-1 p-0 align-items-center '>
                        <div className='col-2 pe-0 d-flex justify-content-end'>
                          <div
                            style={{
                              background: color[index],
                              width: '.5rem',
                              height: '.5rem',
                              borderRadius: '50%',
                              fontSize: '10px',
                            }}
                            className=' me-0'
                          ></div>
                        </div>
                        <ClassText className='col-5 text-start text-capitalize'>
                          {dt.class_name}
                        </ClassText>
                        <ClassText className='col-5 text-start p-0'>
                          $ {dt.amount ? numberWithCommas(dt.amount) : 0}
                        </ClassText>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </ColCard>
          </div>
        </div>
        {/* box one end */}

        {/* box two start */}
        <div className='col-xl-8 col-lg-6 col-md-7'>
          <div className='row p-0 h-100'>
            {/* Deal subscribed start */}
            <div className='col-xl-6 col-lg-12 p-2'>
              <ColCard className='h-100'>
              <Text className='pt-2 ps-3'>Deals Subscribed</Text>
                <div className='row p-0 align-items-center pb-lg-2 pb-md-1 pb-sm-3'>
                  {/* graph start */}
                  <div className='col-xl-12 col-lg-5 col-md-5'>
                    <div className='row m-0 p-0'>
                      <div className='col-lg-12 col-sm-12'>
                        <div className='mt-2'>
                          <div className='d-flex justify-content-center p-1'>
                            <ChartWapper>
                              {dashboardData?.total_deals > 0 ? (
                                <DoughnutChart
                                  data={data}
                                  datalength={dashboardData?.total_deals}
                                />
                              ) : (
                                <div class="circle small">
                                  0
                                </div>
                              )}
                            </ChartWapper>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* graph end */}

                  {/* text start */}
                  <div className='col-xl-12 col-lg-7 col-md-7 pt-lg-1'>
                    <div
                      className='mt-3 mb-2'
                      style={{
                        fontSize: '11px',
                        color: '#767676',
                        // display: 'flex',
                        // justifyContent: 'center',
                        // flexDirection: 'column',
                      }}
                    >
                      {dashboardData.subscribed_deal?.map((dt, index) => (
                        <DealName className='row mb-1 p-0 align-items-center justify-content-around'>
                        <div className='col-1'></div>
                        <div className='col-1 pe-0 d-flex justify-content-end'>
                          <div
                            style={{
                              background: color[index],
                              width: '.7rem',
                              height: '.7rem',
                              borderRadius: '50%',
                              fontSize: '10px',
                            }}
                            className=' me-0'
                          ></div>
                        </div>
                        <ClassText className='col-4 text-start text-nowrap '>
                          {dt.deal_name}
                        </ClassText>
                        <ClassText className='col-4 text-end p-0'>
                          ${' '}
                          {dt.amount_subscribed
                              ? numberWithCommas(dt.amount_subscribed)
                             : ''}
                        </ClassText>
                        <div className='col-1'></div>
                      </DealName>
                        // <DealName className='row mb-1 p-0 align-items-center'>
                        //   <div className='col-1'></div>
                        //   <div className='col-1 pe-0 d-flex justify-content-end'>
                        //     <div
                        //       style={{
                        //         background: color[index],
                        //         width: '.7rem',
                        //         height: '.7rem',
                        //         borderRadius: '50%',
                        //         fontSize: '10px',
                        //       }}
                        //       className=' me-0'
                        //     ></div>
                        //   </div>
                        //   <ClassText className='col-4 text-start '>{dt.deal_name}</ClassText>
                        //   <ClassText className='col-4 text-end p-0'>
                        //     ${' '}
                        //     {dt.amount_subscribed
                        //       ? numberWithCommas(dt.amount_subscribed)
                        //       : ''}
                        //   </ClassText>
                        //   <div className='col-1'></div>
                        // </DealName>
                      ))}
                    </div>
                  </div>
                  {/* text end */}
                </div>
              </ColCard>
            </div>
            {/* Deal subscribed end */}

            {/* Pending deals start */}
            <div className='col-xl-6 col-lg-12 p-2'>
              <ColCard className='h-100'>
              <Text className='pt-2 ps-3'>Pending Subscriptions</Text>
                <div className='row p-0 align-items-center pb-lg-2 pb-md-1 pb-sm-3'>
                  {/* graph start */}
                  <div className='col-xl-12 col-lg-5 col-md-5'>
                    <div className='row m-0 p-0'>
                      <div className='col-lg-12 col-sm-12'>
                        {/* <Text className='mt-2 ms-2'>Pending Subscriptions</Text> */}
                        <div className='mt-2'>
                          <div className='d-flex justify-content-center p-1'>
                            <ChartWapper>
                              {dashboardData?.pending_subscription?.length > 0 ? (
                                <DoughnutChart
                                  data={pendingdata}
                                  datalength={dashboardData?.pending_subscription?.length}
                                />
                              ) : (
                                <div class="circle small">
                                  0
                                </div>
                              )}
                            </ChartWapper>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* graph end */}

                  {/* text start */}
                  <div className='col-xl-12 col-lg-7 col-md-7 pt-lg-1'>
                    <div
                      className='mt-3 mb-2'
                      style={{
                        fontSize: '11px',
                        color: '#767676',
                        // display: 'flex',
                        // justifyContent: 'center',
                        // flexDirection: 'column',
                      }}
                    >
                      {dashboardData.pending_subscription?.map((dt, index) => (
                        <DealName className='row mb-1 p-0 align-items-center justify-content-around'>
                          <div className='col-1'></div>
                          <div className='col-1 pe-0 d-flex justify-content-end'>
                            <div
                              style={{
                                background: color[index],
                                width: '.7rem',
                                height: '.7rem',
                                borderRadius: '50%',
                                fontSize: '10px',
                              }}
                              className=' me-0'
                            ></div>
                          </div>
                          <ClassText className='col-4 text-start text-nowrap '>
                            {dt.deal_name}
                          </ClassText>
                          <ClassText className='col-4 text-end p-0'>
                            ${' '}
                            {dt.subscription_request
                              ? numberWithCommas(dt.subscription_request)
                              : ''}
                          </ClassText>
                          <div className='col-1'></div>
                        </DealName>
                      ))}
                    </div>
                  </div>
                  {/* text end */}
                </div>
              </ColCard>
            </div>
            {/* Pending deals end */}
          </div>
        </div>
        {/* box two end */}
      </div>
    </Wapper>
  );
};

export default InvestorDashboard;
