import { useState } from "react"
import Common from "./Common"
import AuthClient from "../../utilities/AuthClient"
import { Link, useNavigate } from "react-router-dom"

const ForgetPassword = () => {
    const [message, setMessage] = useState('')
    const [data, setData] = useState({
        email: "",
    })
    const changeHandler = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
        setMessage({ text: '', color: '' })
    }
    const navigate = useNavigate()
    console.log('message', message)
    const submitHandler = async (e) => {
        e.preventDefault()
        console.log(data)
        const fetchClient = new AuthClient('/user/request-reset-email/', data)
        await fetchClient.postAPI()
            .then(res => {
                setMessage({ text: 'Successfull! Please check on email', color: 'success' })
                setData({ email: "" })
            })
            .catch(err => {
                setMessage({ text: 'Invalid Email', color: 'danger' })
            })

    }

    return (
        <div className="row vh-height-100">
            <Common />
            <div className="col-sm-7 ps-0 pe-0 bg-white">
                {/* <div className="d-flex align-center justify-content-end pt-2">
                    <div className='col-2'>
                        <Link replace to="/register">Register</Link>
                    </div>
                </div> */}
                <div className="row align-items-center vh-height-94 justify-content-center">
                    <div className="col-sm-10">
                        <div className="signup-col-2">
                            <h2 className="text-center fs-28 fw-5 text-black">Forget Password</h2>
                            {message.text &&
                                <Link to="/login">
                                    <div class={`alert alert-${message.color}`} role="alert">
                                        <Link to="/login" class="alert-link">{message.text}</Link>
                                    </div>
                                </Link>}
                            <div className="sign-up-form">
                                <form className="needs-validation" onSubmit={submitHandler}>
                                    <div className="mb-3">
                                        <label for="email" className="form-label signup-label">Email address</label>
                                        <input type="email" className="form-control sign-up-control" id="email"
                                            aria-describedby="email"
                                            name="email"
                                            value={data.email}
                                            onChange={changeHandler}
                                            placeholder="Please enter your email"
                                            required />
                                        <div className="invalid-feedback">
                                            Please enter your email.
                                        </div>
                                    </div>
                                    <div className="d-grid gap-2">
                                        <button type="submit" className="btn button_color mb-3 btn-lg">Submit</button>
                                    </div>
                                    <div className="grid float-end">
                                        <Link replace to="/login" style={{ color:"#2B4479" }}>Login</Link> |
                                        <Link to="/register" className="text-muted fs-14 fw-4" href="#"> Register</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default ForgetPassword