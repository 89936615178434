import { Doughnut, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
ChartJS.register(ArcElement, Tooltip);

const DemoPie = ({ data, datalength }) => {
  const options = {
    plugins: {
      title: {
        display: true,
        text: 'Doughnut Chart',
        color: 'white',
        font: {
          size: 34,
        },
        padding: {
          top: 10,
          bottom: 10,
        },
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          animateScale: true,
        },
      },
      datalabels: {
        backgroundColor: function (context) {
          return context.dataset.backgroundColor;
        },
        formatter: (val, context) =>
          `${
            (Number(val) * 100) /
            context.chart.data.datasets[context.datasetIndex].data
              .reduce((a, b) => Number(a) + Number(b), 0)
              .toFixed(2)
          }%`,
        //formatter: (val, context) => `${val}%`,
        borderRadius: 25,
        borderWidth: 3,
        color: 'black',
        font: {
          weight: 'bold',
        },
        padding: 6,
      },

      tooltip: {
        callbacks: {
          // label: (ttItem) =>
          //   `${ttItem.label}: ${
          //               (ttItem.parsed * 100) /
          //               ttItem.dataset.data.reduce(
          //                 (a, b) => Number(a) + Number(b),
          //                 0
          //               ).toFixed(2)
          //             }%`
          label: (ttItem) => `${(ttItem.label).slice(0,10)}: ${(ttItem.parsed).toFixed()}%`,
        },
      },
    },
    cutout: '70%',
    elements: {
      arc: {
        borderWidth: 1,
        // borderRadius: 0,
      },
    },
  };

  const plugins = [
    {
      id: 'text',
      beforeDraw: function (chart, a, b) {
        var width = chart.width,
          height = chart.height,
          ctx = chart.ctx;

        ctx.restore();
        ctx.font = '600 25px OpenSans';
        ctx.textBaseline = 'middle';
        ctx.fillStyle = '#767676';

        var text = datalength,
          textX = Math.round((width - ctx.measureText(text).width) / 1.98),
          textY = height / 2;

        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    },
  ];

  return (
    <div>
      <Doughnut data={data} options={options} plugins={plugins} />
    </div>
  );
};

export default DemoPie;
