// import notification from '../../assets/images/notification.svg'
import ChatIcon from '../../assets/images/chat.svg'
import { Link } from 'react-router-dom'
import { FaBeer } from 'react-icons/fa'
import { IoIosNotifications, IoMdNotificationsOutline } from 'react-icons/io'
import { IoChatboxOutline } from 'react-icons/io5'
import { BsFillChatDotsFill } from 'react-icons/bs'
import { style } from '@mui/system'
import styled from 'styled-components'
// import { chatSvg} from '../../assets/icons/Icons'

const Containerw = styled.div`
    background: #FFFFFF;
    box-shadow: -2px -2px 10px rgba(0, 0, 0, 0.15), 2px 2px 10px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    margin-bottom: 1rem;
    padding: .5rem;
`
const Text = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #2B4479;
    @media(max-width:480px){
        font-size: 17px;
        margin-left: 20px;
    }
`
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import MobileSidebar from './MobileSidebar'

const NewHeader = ({ user }) => {
    const logout = () => {
        localStorage.removeItem("token")
        window.location.reload()
    }
    console.log('mainUser', user)
    const sideNav = [false].map((expand) => (
        <Navbar key={expand} expand={expand}>
            <Container fluid>
                <Navbar.Brand>
                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                </Navbar.Brand>
                <Navbar.Offcanvas
                    id={`offcanvasNavbar-expand-${expand}`}
                    aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                    placement="start"
                    className='toggle-nav'
                >
                    <MobileSidebar expand={expand} />
                </Navbar.Offcanvas>
            </Container>
        </Navbar>))
    return (
        <Containerw className='col-12 px-4'>
            {/* <!-- Start XP Row --> */}
            <div class="row align-items-center justify-content-lg-between justify-content-md-between justify-content-sm-center custom-padding postion-relative">
                {/* <!-- Start XP Col --> */}
                <div className='col-auto ipad-toggle'>
                    {sideNav}
                </div>
                <div class="mobile-toggle w-25">
                    {[false].map((expand) => (
                        <Navbar key={expand} expand={expand}>
                            <Container>
                                <Navbar.Brand className='p-0 m-0'>
                                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} className=' p-0 m-0 ' />
                                </Navbar.Brand>
                                <Navbar.Offcanvas
                                    id={`offcanvasNavbar-expand-${expand}`}
                                    aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                                    placement="start"
                                    className='toggle-nav'
                                >
                                    <MobileSidebar expand={expand} />
                                </Navbar.Offcanvas>
                            </Container>
                        </Navbar>))}
                </div>
                <div className='col-lg-6 col-md-5 col-sm-12 self-align-center m-0'>
                    <Text className='text-xl-start text-lg-center text-md-center'>Welcome {user?.company_name}</Text>
                </div>
                <div class="col-auto custom-padding-2">
                    <div class="xp-profilebar">
                        <ul class="list-inline mb-0">
                            <li class="list-inline-item">
                                <div class="xp-notification mr-3">
                                    <a className="btn header-btn " href="#" role="button" id="dropdownMenuLink"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                        <IoChatboxOutline size={26} />
                                        {/* <IoChatboxOutline /> */}
                                        {/* <img src={ChatIcon} alt=""/> */}
                                        {/* <BsFillChatDotsFill size={23} /> */}
                                        {/* <chatSvg /> */}
                                    </a>
                                    <div className="dropdown-menu w-25 p-2" aria-labelledby="dropdownMenuLink">
                                        <ul class="list-unstyled">
                                            <li class="media">
                                                <div class="media-body">
                                                    <h5 class="mt-0 mb-0 text-dark">Messages</h5>
                                                </div>
                                            </li>
                                            <li class="media xp-noti">
                                                <div>
                                                    <a href="#">
                                                        <p class='mb-0'>Someone sent you a message</p>
                                                        <p class='text-muted fs-14 mb-0'>You have recieved new orders</p>
                                                    </a>
                                                </div>
                                                <div>
                                                    <p class="mb-0 fs-12 fw-4 text-muted">1 min ago</p>
                                                </div>
                                            </li>
                                            <li class="media xp-noti">
                                                <div>
                                                    <a href="#">
                                                        <p class='mb-0'>Someone sent you a message</p>
                                                        <p class='text-muted fs-14 mb-0'>You have recieved new orders</p>
                                                    </a>
                                                </div>
                                                <div>
                                                    <p class="mb-0 fs-12 fw-4 text-muted">1 min ago</p>
                                                </div>

                                            </li>
                                            <li class="media xp-noti">
                                                <div>
                                                    <a href="#">
                                                        <p class='mb-0'>Someone sent you a message</p>
                                                        <p class='text-muted fs-14 mb-0'>You have recieved new orders</p>
                                                    </a>
                                                </div>
                                                <div>
                                                    <p class="mb-0 fs-12 fw-4 text-muted">1 min ago</p>
                                                </div>
                                            </li>
                                            <li class="media xp-noti">
                                                <div>
                                                    <a href="#">
                                                        <p class='mb-0'>Someone sent you a message</p>
                                                        <p class='text-muted fs-14 mb-0'>You have recieved new orders</p>
                                                    </a>
                                                </div>
                                                <div>
                                                    <p class="mb-0 fs-12 fw-4 text-muted">1 min ago</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                            <li class="list-inline-item">
                                <div class="xp-notification mr-3">
                                    <a class="btn header-btn " href="#" role="button" id="dropdownMenuLink"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                        <IoMdNotificationsOutline size={30} />
                                        {/* <IoIosNotifications size={26} /> */}
                                        {/* <img src={IoIosNotifications} alt="notification-icon"/> */}
                                        <span class="badge rounded-pill bg-danger xp-badge-up"></span>
                                    </a>
                                    <div class="dropdown-menu w-25 p-1" aria-labelledby="dropdownMenuLink">
                                        <ul class="list-unstyled ">
                                            <li class="media">
                                                <div class="media-body">
                                                    <h5 class="mt-0 mb-0 text-dark">Notifications</h5>
                                                </div>
                                            </li>
                                            <li class="media xp-noti">
                                                <div>
                                                    <a href="#">
                                                        <p class='mb-0'>Invester accept your invite</p>
                                                        <p class='text-muted fs-14 mb-0'>You have recieved new orders</p>
                                                    </a>
                                                </div>
                                                <div>
                                                    <p class="mb-0 fs-12 fw-4 text-muted">1 min ago</p>
                                                </div>
                                            </li>
                                            <li class="media xp-noti">
                                                <div>
                                                    <a href="#">
                                                        <p class='mb-0'>Invester accept your invite</p>
                                                        <p class='text-muted fs-14 mb-0'>You have recieved new orders</p>
                                                    </a>
                                                </div>
                                                <div>
                                                    <p class="mb-0 fs-12 fw-4 text-muted">1 min ago</p>
                                                </div>
                                            </li>
                                            <li class="media xp-noti">
                                                <div>
                                                    <a href="#">
                                                        <p class='mb-0'>Invester accept your invite</p>
                                                        <p class='text-muted fs-14 mb-0'>You have recieved new orders</p>
                                                    </a>
                                                </div>
                                                <div>
                                                    <p class="mb-0 fs-12 fw-4 text-muted">1 min ago</p>
                                                </div>
                                            </li>
                                            <li class="media xp-noti">
                                                <div>
                                                    <a href="#">
                                                        <p class='mb-0'>Invester accept your invite</p>
                                                        <p class='text-muted fs-14 mb-0'>You have recieved new orders</p>
                                                    </a>
                                                </div>
                                                <div>
                                                    <p class="mb-0 fs-12 fw-4 text-muted">1 min ago</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                            <li class="list-inline-item mr-0">
                                <div class="dropdown">
                                    <button class="btn header-btn" type="button" id="dropdownMenuButton1"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                        {user?.user_type === "investor" ?
                                            user?.profile_image.investor_image ?
                                                <img src={user?.profile_image.investor_image} alt="user-profile"
                                                    class="rounded-circle img-fluid" style={{ width: "38px", height: "38px" }} />
                                                : <img class="user-img" src="https://firebasestorage.googleapis.com/v0/b/stack-share-752fe.appspot.com/o/profile%20imahe%2Fempty%20profile%20image.jpg?alt=media&token=d386df41-478a-4497-a252-f22e3ef051c5" alt="" style={{ width: "38px", height: "38px" }} /> : ''
                                        }
                                        {user?.user_type === "sponsor" ?
                                            user?.profile_image.sponsor_image ?
                                                <img src={user?.profile_image.sponsor_image} alt="user-profile"
                                                    class="rounded-circle img-fluid" style={{ width: "38px", height: "38px" }} />
                                                : <img class="user-img" src="https://firebasestorage.googleapis.com/v0/b/stack-share-752fe.appspot.com/o/profile%20imahe%2Fempty%20profile%20image.jpg?alt=media&token=d386df41-478a-4497-a252-f22e3ef051c5" alt="" style={{ width: "38px", height: "38px" }} /> : ''
                                        }
                                        {user?.user_type === "admin" ?
                                            user?.profile_image.admin_image ?
                                                <img src={user?.profile_image.admin_image} alt="user-profile"
                                                    class="rounded-circle img-fluid" style={{ width: "38px", height: "38px" }} />
                                                : <img class="user-img" src="https://firebasestorage.googleapis.com/v0/b/stack-share-752fe.appspot.com/o/profile%20imahe%2Fempty%20profile%20image.jpg?alt=media&token=d386df41-478a-4497-a252-f22e3ef051c5" alt="" style={{ width: "38px", height: "38px" }} /> : ''
                                        }
                                        <span class="xp-user-live" ></span>
                                    </button>
                                    <ul class="dropdown-menu profile-menu" aria-labelledby="dropdownMenuButton1">
                                        <div class="card profile-card">
                                            <img src="https://firebasestorage.googleapis.com/v0/b/stackshare-ebcb9.appspot.com/o/icons%2Fprofile-card-bg.png?alt=media&token=75e9018b-1abb-481e-90f2-253c7fa0afd6" class="card-img-top" alt="..." />
                                            <div class="card-body">
                                                <div class="text-center">
                                                    {/* <img class="profile_card_img" src={user?.user_type === "investor" ? user?.profile_image.investor_image : user?.profile_image.sponsor_image}
                                                        alt="Profile" /> */}
                                                    {user?.user_type === "investor" ?
                                                        user?.profile_image.investor_image ?
                                                            <img src={user?.profile_image.investor_image} alt="user-profile"
                                                                className="profile_card_img" />
                                                            : <img class="user-img" src="https://firebasestorage.googleapis.com/v0/b/stack-share-752fe.appspot.com/o/profile%20imahe%2Fempty%20profile%20image.jpg?alt=media&token=d386df41-478a-4497-a252-f22e3ef051c5" alt="" className="profile_card_img" /> : ''
                                                    }
                                                    {user?.user_type === "sponsor" ?
                                                        user?.profile_image.sponsor_image ?
                                                            <img src={user?.profile_image.sponsor_image} alt="user-profile"
                                                                className="profile_card_img" />
                                                            : <img class="user-img" src="https://firebasestorage.googleapis.com/v0/b/stack-share-752fe.appspot.com/o/profile%20imahe%2Fempty%20profile%20image.jpg?alt=media&token=d386df41-478a-4497-a252-f22e3ef051c5" alt="" className="profile_card_img" /> : ''
                                                    }
                                                    {user?.user_type === "admin" ?
                                                        user?.profile_image.admin_image ?
                                                            <img src={user?.profile_image.admin_image} alt="user-profile"
                                                                className="profile_card_img" />
                                                            : <img src="https://firebasestorage.googleapis.com/v0/b/stack-share-752fe.appspot.com/o/profile%20imahe%2Fempty%20profile%20image.jpg?alt=media&token=d386df41-478a-4497-a252-f22e3ef051c5" alt="" className="profile_card_img" /> : ''
                                                    }
                                                </div>
                                                {user?.user_type === "investor" &&
                                                    <Link class="dropdown-item" to="/dashboard/investor-profile">
                                                        <svg class="me-2" width="16" height="15" viewBox="0 0 16 15" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <g clip-path="url(#clip0_120_892)">
                                                                <path
                                                                    d="M7.99998 0C3.86457 0 0.5 3.36457 0.5 7.49998C0.5 11.6354 3.86457 15 7.99998 15C12.1354 15 15.5 11.6354 15.5 7.49998C15.5 3.36457 12.1354 0 7.99998 0ZM7.99998 14.3478C4.2239 14.3478 1.15216 11.2761 1.15216 7.49998C1.15216 3.7239 4.2239 0.652163 7.99998 0.652163C11.7761 0.652163 14.8478 3.7239 14.8478 7.49998C14.8478 11.2761 11.7761 14.3478 7.99998 14.3478Z"
                                                                    fill="#7E8A9D" />
                                                                <path
                                                                    d="M8.00012 7.17392C5.66272 7.17392 3.76099 9.07566 3.76099 11.4131C3.76099 11.5931 3.90708 11.7392 4.08708 11.7392C4.26708 11.7392 4.41318 11.593 4.41318 11.413C4.41318 9.43499 6.02208 7.82608 8.00012 7.82608C9.97817 7.82608 11.5871 9.43499 11.5871 11.413C11.5871 11.593 11.7332 11.7391 11.9132 11.7391C12.0932 11.7391 12.2393 11.593 12.2393 11.413C12.2393 9.07566 10.3375 7.17392 8.00012 7.17392Z"
                                                                    fill="#7E8A9D" />
                                                                <path
                                                                    d="M8.00002 1.95651C6.74134 1.95651 5.71741 2.98041 5.71741 4.23913C5.71741 5.49784 6.74131 6.52175 8.00002 6.52175C9.25874 6.52175 10.2826 5.49781 10.2826 4.23913C10.2826 2.98045 9.25874 1.95651 8.00002 1.95651ZM8.00002 5.86955C7.10134 5.86955 6.3696 5.13781 6.3696 4.23913C6.3696 3.34045 7.10134 2.60871 8.00002 2.60871C8.89871 2.60871 9.63044 3.34045 9.63044 4.23913C9.63044 5.13781 8.89874 5.86955 8.00002 5.86955Z"
                                                                    fill="#7E8A9D" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_120_892">
                                                                    <rect width="15" height="15" fill="white"
                                                                        transform="translate(0.5)" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                        Profile</Link>}
                                                {user?.user_type === "sponsor" &&
                                                    <div>
                                                        <Link class="dropdown-item" to="/dashboard/sponsor-profile">
                                                            <svg class="me-2" width="16" height="15" viewBox="0 0 16 15" fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <g clip-path="url(#clip0_120_892)">
                                                                    <path
                                                                        d="M7.99998 0C3.86457 0 0.5 3.36457 0.5 7.49998C0.5 11.6354 3.86457 15 7.99998 15C12.1354 15 15.5 11.6354 15.5 7.49998C15.5 3.36457 12.1354 0 7.99998 0ZM7.99998 14.3478C4.2239 14.3478 1.15216 11.2761 1.15216 7.49998C1.15216 3.7239 4.2239 0.652163 7.99998 0.652163C11.7761 0.652163 14.8478 3.7239 14.8478 7.49998C14.8478 11.2761 11.7761 14.3478 7.99998 14.3478Z"
                                                                        fill="#7E8A9D" />
                                                                    <path
                                                                        d="M8.00012 7.17392C5.66272 7.17392 3.76099 9.07566 3.76099 11.4131C3.76099 11.5931 3.90708 11.7392 4.08708 11.7392C4.26708 11.7392 4.41318 11.593 4.41318 11.413C4.41318 9.43499 6.02208 7.82608 8.00012 7.82608C9.97817 7.82608 11.5871 9.43499 11.5871 11.413C11.5871 11.593 11.7332 11.7391 11.9132 11.7391C12.0932 11.7391 12.2393 11.593 12.2393 11.413C12.2393 9.07566 10.3375 7.17392 8.00012 7.17392Z"
                                                                        fill="#7E8A9D" />
                                                                    <path
                                                                        d="M8.00002 1.95651C6.74134 1.95651 5.71741 2.98041 5.71741 4.23913C5.71741 5.49784 6.74131 6.52175 8.00002 6.52175C9.25874 6.52175 10.2826 5.49781 10.2826 4.23913C10.2826 2.98045 9.25874 1.95651 8.00002 1.95651ZM8.00002 5.86955C7.10134 5.86955 6.3696 5.13781 6.3696 4.23913C6.3696 3.34045 7.10134 2.60871 8.00002 2.60871C8.89871 2.60871 9.63044 3.34045 9.63044 4.23913C9.63044 5.13781 8.89874 5.86955 8.00002 5.86955Z"
                                                                        fill="#7E8A9D" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_120_892">
                                                                        <rect width="15" height="15" fill="white"
                                                                            transform="translate(0.5)" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                            Profile</Link>
                                                    </div>}
                                                {user?.user_type === "admin" &&
                                                    <div>
                                                        <Link class="dropdown-item" to="/dashboard/admin-profile">
                                                            <svg class="me-2" width="16" height="15" viewBox="0 0 16 15" fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <g clip-path="url(#clip0_120_892)">
                                                                    <path
                                                                        d="M7.99998 0C3.86457 0 0.5 3.36457 0.5 7.49998C0.5 11.6354 3.86457 15 7.99998 15C12.1354 15 15.5 11.6354 15.5 7.49998C15.5 3.36457 12.1354 0 7.99998 0ZM7.99998 14.3478C4.2239 14.3478 1.15216 11.2761 1.15216 7.49998C1.15216 3.7239 4.2239 0.652163 7.99998 0.652163C11.7761 0.652163 14.8478 3.7239 14.8478 7.49998C14.8478 11.2761 11.7761 14.3478 7.99998 14.3478Z"
                                                                        fill="#7E8A9D" />
                                                                    <path
                                                                        d="M8.00012 7.17392C5.66272 7.17392 3.76099 9.07566 3.76099 11.4131C3.76099 11.5931 3.90708 11.7392 4.08708 11.7392C4.26708 11.7392 4.41318 11.593 4.41318 11.413C4.41318 9.43499 6.02208 7.82608 8.00012 7.82608C9.97817 7.82608 11.5871 9.43499 11.5871 11.413C11.5871 11.593 11.7332 11.7391 11.9132 11.7391C12.0932 11.7391 12.2393 11.593 12.2393 11.413C12.2393 9.07566 10.3375 7.17392 8.00012 7.17392Z"
                                                                        fill="#7E8A9D" />
                                                                    <path
                                                                        d="M8.00002 1.95651C6.74134 1.95651 5.71741 2.98041 5.71741 4.23913C5.71741 5.49784 6.74131 6.52175 8.00002 6.52175C9.25874 6.52175 10.2826 5.49781 10.2826 4.23913C10.2826 2.98045 9.25874 1.95651 8.00002 1.95651ZM8.00002 5.86955C7.10134 5.86955 6.3696 5.13781 6.3696 4.23913C6.3696 3.34045 7.10134 2.60871 8.00002 2.60871C8.89871 2.60871 9.63044 3.34045 9.63044 4.23913C9.63044 5.13781 8.89874 5.86955 8.00002 5.86955Z"
                                                                        fill="#7E8A9D" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_120_892">
                                                                        <rect width="15" height="15" fill="white"
                                                                            transform="translate(0.5)" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                            Profile</Link>
                                                    </div>}
                                                <Link to="/dashboard/change-password" class="dropdown-item">
                                                    <svg class="me-2 out-margin" width="19" height="18" viewBox="0 0 19 18"
                                                        fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clip-path="url(#clip0_120_902)">
                                                            <path
                                                                d="M16.316 3.64451L9.55077 0.039663C9.4504 -0.0139021 9.33012 -0.0131504 9.2305 0.0415425L2.65251 3.64638C2.54463 3.70559 2.47772 3.81873 2.47772 3.94184V8.65069C2.48167 12.5215 4.77501 16.0233 8.32159 17.5741L9.23464 17.972C9.31997 18.0092 9.41714 18.0094 9.50265 17.9723L10.5315 17.528C14.1438 16.0109 16.4939 12.4752 16.4943 8.55728V3.94184C16.4943 3.81742 16.4257 3.70296 16.316 3.64451ZM15.8207 8.55728C15.82 12.2052 13.6313 15.4967 10.2678 16.9084L10.2655 16.9095L9.36959 17.2963L8.59111 16.9567C5.2898 15.5132 3.1549 12.2538 3.15114 8.65069V4.14125L9.39458 0.719471L15.8207 4.14369V8.55728Z"
                                                                fill="#7E8A9D" />
                                                            <path
                                                                d="M6.90991 8.43228C6.78906 8.29075 6.57649 8.27402 6.43497 8.39487C6.29345 8.51554 6.27672 8.7283 6.39757 8.86963L8.17668 10.953C8.29546 11.0921 8.50352 11.1111 8.64523 10.9957L12.7887 7.62466C12.9331 7.50738 12.9549 7.29519 12.8374 7.15084C12.7201 7.00669 12.5079 6.9847 12.3636 7.10217L8.47533 10.2653L6.90991 8.43228Z"
                                                                fill="#7E8A9D" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_120_902">
                                                                <rect width="18" height="18" fill="white"
                                                                    transform="translate(0.5)" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                    Change Password</Link>
                                                <a class="dropdown-item" onClick={logout}>
                                                    <svg class="me-2" width="17" height="14" viewBox="0 0 17 14" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M0.5 2.95022V11.0498C0.5 12.1658 1.41046 13.0763 2.52651 13.0763H9.07271C10.1888 13.0763 11.0992 12.1658 11.0992 11.0498V9.73791C11.0992 9.516 10.9197 9.33652 10.6978 9.33652C10.4759 9.33652 10.2965 9.516 10.2965 9.73791V11.0498C10.2965 11.7253 9.74495 12.2768 9.06945 12.2768H2.52651C1.85101 12.2768 1.29951 11.7253 1.29951 11.0498V2.95022C1.29951 2.27472 1.85101 1.72322 2.52651 1.72322H9.07271C9.74822 1.72322 10.2997 2.27472 10.2997 2.95022V4.26207C10.2997 4.48398 10.4792 4.66346 10.7011 4.66346C10.923 4.66346 11.1025 4.48398 11.1025 4.26207V2.95022C11.1025 1.83417 10.192 0.923706 9.07597 0.923706H2.52651C1.41046 0.923706 0.5 1.83091 0.5 2.95022Z"
                                                            fill="#7E8A9D" />
                                                        <path
                                                            d="M13.0769 10.0186C13.1553 10.0969 13.2564 10.136 13.3609 10.136C13.4653 10.136 13.5664 10.0969 13.6448 10.0186L16.3827 7.28063C16.5393 7.124 16.5393 6.87272 16.3827 6.71608L13.6448 3.97817C13.4881 3.82153 13.2368 3.82153 13.0802 3.97817C12.9236 4.13481 12.9236 4.38608 13.0802 4.54272L15.1361 6.5986H7.63375C7.41184 6.5986 7.23236 6.77808 7.23236 6.99999C7.23236 7.22189 7.41184 7.40138 7.63375 7.40138H15.1328L13.0769 9.45726C12.9203 9.61064 12.9203 9.86517 13.0769 10.0186Z"
                                                            fill="#7E8A9D" />
                                                    </svg>
                                                    Logout</a>
                                            </div>
                                        </div>
                                    </ul>
                                </div>
                            </li>
                        </ul >
                    </div >
                </div >
            </div >
        </Containerw>
    )
}
export default NewHeader
