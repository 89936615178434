import React, { useState } from 'react'
import ProfileImg from '../../assets/images/user-profile.png'
import { Button, Spin, Upload } from 'antd';
import { GrUpdate } from "react-icons/gr"
import profileBackground from '../../assets/images/card-bg.png'

const ProfileImage = ({ user, imageUpload, image, update }) => {
  console.log('imgaeProps', user)
  const [loadings, setLoadings] = useState([]);

  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });}
  return (
    <div class="row mb-3">
      <div class="col-sm-12">
        <div class="profile-card">
          <div id="card-bg"></div>
        </div>
        <div className="profile-data position-relative" >
          <div class="profile-img">
            {update ?
              <div class="user-img d-flex align-items-center justify-content-center"><Spin/></div> :
              <div>
                {image ?
                  <img class="user-img" src={image} alt="" /> :
                  <img class="user-img" src="https://firebasestorage.googleapis.com/v0/b/stack-share-752fe.appspot.com/o/profile%20imahe%2Fempty%20profile%20image.jpg?alt=media&token=d386df41-478a-4497-a252-f22e3ef051c5" alt="" />}
              </div>}
            <h1 class="user-title">{`${user?.first_name} ${user?.last_name}`}</h1>
          </div>
          <label for="inputTag" className='profile_update' >
          <GrUpdate size={"30px"} color="blue"></GrUpdate><div>Update</div>
            <input id="inputTag" type="file" style={{ display: "none" }} onChange={imageUpload}/>
          </label>
          {/* <input type="file" onChange={imageUpload} value=""/> */}
          {/* <label for="inputTag" className='profile_update' >
          <GrUpdate size={"30px"} color="blue"></GrUpdate><div>Update</div>
            <input id="inputTag" type="file" style={{ display: "none" }} onChange={imageUpload}/>
          </label> */}
        </div>
      </div>
    </div>
  )
}

export default ProfileImage