const FinalResultTable = ({ data }) => {
    console.log('final', data)
    return (
        <div>
                {data.tier && <div className="pb-2 fs-14 mt-1">{data.tier}</div>}
                <div className="table-responsive">
                    <table className="table data-table align-middle table-borderless sponser-index-table"
                        id="sponser-table">
                        <thead className="table-white border-bottom border-top" style={{ 'fontSize': '14px', 'fontWeight': '400' }}>
                            <tr>
                                <th className="col-1 align-middle">Classes</th>
                                {data.irr_class_A ?  <th className="col-1 text-center">IRR</th> : <th className="col-1"></th> }
                                {data.investment_class_A ? <th className="col-1 text-center">Investment</th> : <th className="col-1"></th> }
                                {data.class_A_year}
                                { data.projected_cashflow_class_A ? <th className="col-1 text-center">Total</th> : <th></th>}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="col-1">Class A</td>
                                {/* {data.tier==='% Capital Outstanding' ? data.class_A+'%': data.class_A} */}
                                {data.irr_class_A ? <td className="col-1 text-center">{data.irr_class_A} %</td>:<td className="col-1"></td>}
                                {data.investment_class_A ? <td className="col- text-center">{data.investment_class_A}</td>:<td className="col-1"></td>}
                                {data.class_A}
                                { data.projected_cashflow_class_A ? <td className="col-1 text-center">{data.projected_cashflow_class_A}</td> : <td className="col-1"></td>}
                            </tr>
                            { data.investment_class_B &&
                            <tr>
                                <td>Class B</td>
                                {data.irr_class_B && <td className="col-1 text-center">{data.irr_class_B} %</td>}
                                {data.investment_class_B && <td className="col-1 text-center">{data.investment_class_B}</td>}
                                {data.class_B}
                                { data.projected_cashflow_class_B && <td className="text-center">{data.projected_cashflow_class_B}</td>}
                            </tr>}
                            {data.class_C.length > 0 &&
                            <tr>
                                <td>Class C</td>
                                {data.irr_class_C && <td className="col-1 text-center">{data.irr_class_C} %</td>}
                                {data.investment_class_C && <td className="col-1 text-center">{data.investment_class_C}</td>}
                                {data.class_C}
                                { data.projected_cashflow_class_C && <td className="text-center">{data.projected_cashflow_class_C}</td>}
                            </tr>}
                            {data.sponsor ? data.sponsor.length > 0 && 
                            <tr>
                                <td>Sponsor</td>
                                <td></td>
                                <td></td>
                                {data.sponsor}
                                <td className="text-center">{data.total_cashflow_sponsor ? data.total_cashflow_sponsor : 0}</td>
                            </tr>:''}
                          
                            <tr className="border-top">
                                <td className="fw-6">Total</td>
                                <td></td>
                                <td className="col-1 text-center">{data.total_investment}</td>
                                {data.total}
                                <td className="text-center">{data.totalAmount}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
    )
}

export default FinalResultTable