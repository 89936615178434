import { configureStore } from '@reduxjs/toolkit'
import dealSlice from './reducers/DealReducers'
import investorSlice from './reducers/InvestorReducer'
import sponsorSlice from './reducers/SponsorReducer'
import userSlice from './reducers/UserReducer'


const store = configureStore({
    reducer:{
        user: userSlice.reducer,
        deals: dealSlice.reducer,
        investors: investorSlice.reducer,
        sponsors: sponsorSlice.reducer,
    }
})

export default store