import { message, Spin } from "antd"
import { useState } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { DateFormater } from "../../utilities/extrafunction/DateFormater"
import numFormatter from "../../utilities/extrafunction/numberConveter"
import percentage from "../../utilities/extrafunction/Persentage"

const Button = styled.button`
    color: white;
    border-radius: "5px";
    background: ${(props) => props.background};
    &:hover{
        color: white;
    }
`
const Select = styled.select`
border: none;
padding: .2rem;
border-radius: 16px;
background: ${props => props.color};
color: #FFFFFF;
cursor:pointer;
`
const Option = styled.option`
background:  #FFFFFF;
color: #000000;
`

const DealRow = ({ dt, updateHandler, CopyDealHandler, changeUpdate }) => {
    const [loading, setLoading] = useState(false)
    const [coping, setCoping] = useState(false)

    const selectedHandler = (e, id) => {
        setLoading(true)
        console.log('selected value', e.target.value)
        updateHandler(id, e.target.value)
        setTimeout(() => { setLoading(false) }, 1500);
        // setSelected(e.target.value)
    }
    const copyHandler = (id) =>{
        setCoping(true)
        CopyDealHandler(id)
        setTimeout(() => { setCoping(false) }, 1500);
    }
    return (
        <tr key={dt.id}>
            <td>DS{dt.id}</td>
            <td className="text-center">{dt.deal_name}</td>
            {dt?.sponsor &&
                <td className="text-center">{dt?.sponsor}</td>}
            <td className="text-center">{dt.launch_date ? DateFormater(dt.launch_date) : ''}</td>
            <td className="text-center">${numFormatter(dt.total_shares)}</td>
            <td className="text-center ml-2 mr-2">
                <div class="" id="deal-prog" >
                    <div class="progress-bar subscribed-bar border" role="progressbar" style={{ "width": "100%", padding: ".8rem .3rem", background: "#5D91FF", borderRadius: "15px", }}
                        aria-valuenow="60" aria-valuemin="0" aria-valuemax="100">
                        <span class="fs-12" style={{ zIndex: "1", marginTop: ".08rem" }}>{dt.committed_shares > 0 ? percentage(dt.committed_shares, dt.total_shares).toFixed() + "%" : "0%"}</span>
                        <div class="position-absolute" role="progressbar" style={{ height: "1.2rem", "minWidth": dt.committed_shares > 0 && percentage(dt.committed_shares, dt.total_shares).toFixed() + "%", borderRadius: percentage(dt.committed_shares, dt.total_shares).toFixed() > 99 ? "12px" : "12px 0px 0px 12px", background: "#2B4479", color: "#2B4479", marginTop: ".08rem" }}
                            aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                            <span class="fs-12 shares-detail fs-7" style={{ color: "transparent" }}>{dt.committed_shares > 0 && percentage(dt.committed_shares, dt.total_shares).toFixed() + "%"}</span>
                            {/* {dt.committed_shares > 0 || dt.total_shares > 0 &&
                    <span class="fs-12 shares-detail">{percentage(dt.committed_shares, dt.total_shares)}10%</span>} */}
                        </div>
                    </div>
                </div>
            </td>
            <td className="text-center">
                {loading ? <Spin /> :
                    <Select className="pe-0" name="cars" id="cars" onChange={(e) => selectedHandler(e, dt.id)} color={dt.deal_status === "Open" ? "#1B8B41" : dt.deal_status === "Closed" ? "#F84A4A" : "#4F7CDA"}>
                        <Option value="Open" selected={dt.deal_status === "Open" ? "selected" : ""}>Open</Option>
                        <Option value="Pause" selected={dt.deal_status === "Pause" ? "selected" : ""}>Pause</Option>
                        <Option value="Closed" selected={dt.deal_status === "Closed" ? "selected" : ""}>Close</Option>
                    </Select>}
                {/* {dt.deal_status === "Open" &&
                <span class="badge table-badge rounded-pill bg-green fw-4" style={{ cursor: 'pointer', background: "#1B8B41", color: "#FFFFFF" }} onClick={()=>updateHandler(dt.id, "Closed")}>Open</span>}
            {dt.deal_status === "Closed" &&
                <span class="badge table-badge rounded-pill fw-4" style={{ cursor: 'pointer', background: "#F84A4A", color: "#FFFFFF" }} onClick={()=>updateHandler(dt.id, "Open")}>Close</span>}
            {dt.deal_status === "Pause" &&
                <span class="badge table-badge rounded-pill bg-info fw-4" style={{ cursor: 'pointer', background: "#3D61AE", color: "#FFFFFF" }} onClick={()=>updateHandler(dt.id, "Open")}>Pause</span>} */}
            </td>
            {/* <td className="text-center">{parseFloat(dt.total_shares) + parseFloat(dt.committed_shares)}</td> */}
            {/* <td className="text-center">
        <button className="btn action-btn dropdown-toggle" type="button" id="action"
            data-bs-toggle="dropdown" aria-expanded="false">
            <img src={More} alt="" />
        </button>
        <ul className="dropdown-menu" aria-labelledby="action">
            <li>
                <Link to={`/dashboard/sponsor/${dt.id}`}>
                    <a className="dropdown-item" href="./dealDetails.php"> <span> <img
                        src={DetailsSVG} alt="" /> </span>
                        Update Deal</a>
                </Link>
            </li>
            <li>
                <Link to={`/dashboard/investor/`}>
                    <a className="dropdown-item" href="./dealDetails.php"> <span> <img
                        src={InvestorSVG} alt="" /> </span> 
                        View Invester</a>
                </Link>
            </li>

        </ul>
    </td> */}
            <td><Link to={`/dashboard/subscriptions/${dt.id}`}><Button className="btn btn-sm m-2" background="#1D2E52">Subscriptions</Button></Link></td>
            <td><Link to={`/dashboard/deals/${dt.id}`}><Button className="btn btn-sm m-2" background="#2B4479">Deal View</Button></Link></td>
            <td><Link to={`/dashboard/investors/${dt.id}`}><Button className="btn btn-sm m-2" background="#3D61AE">Investor View</Button></Link></td>
            <td>
                {coping ? 
                <Button className="btn btn-sm m-2 btn-primary" >Coping...</Button>:
                <Button className="btn btn-sm m-2" style={{ backgroundColor: "rgb(14 165 233)" }} onClick={() => copyHandler(dt.id)}>Copy Deal</Button>}
            </td>
        </tr>
    )
}

export default DealRow