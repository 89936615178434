import React from 'react';
import numberWithCommas from "../../../utilities/extrafunction/commaseparate";
import "./investorTable.css";

const InvestmentTable = ({
  classValues,
  classAmounts,
  rowData,
  activeButton,
  shares,
  result,
}) => {
  const mapValue = classValues.filter((value) => value !== "");
  const classIndex = ["A", "B", "C", "D"];

  const {
    price_per_unit,
    price_per_sf,
    entry_cap_rate,
    stabilized_noi,
    proforma_noi,
    preferred_return,
    equity,
    profit_share_1,
    avg_return_class_A,
    avg_return_class_B,
    avg_return_class_C,
  } = rowData;

  const invesmentMatixTH = [
    "Price Per Unit",
    "Price Per SF",
    "Entry Cap Rate",
    "Stabilized Cap Rate",
    "Pro Forma Cap Rate",
  ];

  const invesmentTermTH = [
    "Preferred Return",
    "Serviced / Accrued",
    "Hurdle",
    "Equity stack %",
    "Profit Share",
    "Profit Class Allocation %",
  ];

  const projectReturnTH = [
    "Profit Distribution",
    "Average Annual Return",
    "Projected IRR",
    "Equity Multiple",
  ];

  const renderTableHeader = () => {
    const headers = activeButton === "Investment Metrics" ? invesmentMatixTH :
                    activeButton === "Investment Terms" ? invesmentTermTH :
                    projectReturnTH;

    return (
      <tr className="row p-0 m-0 invesment__table__tr">
        <th className="col-3 table__text__black ps-3 sticky-column">Equity Class</th>
        {headers.map((title, index) => (
          <th key={index} className="col text-center table__text__secondary">
            {title}
          </th>
        ))}
      </tr>
    );
  };

  const renderInvestmentMetrics = () => {
    return mapValue.map((cl, index) => (
      <React.Fragment key={index}>
        <div className="ps-2 pr-3 tborder-div">
          <div className="tborder1"></div>
        </div>
        <tr className="row p-0 m-0 invesment__table__tr">
          <td className="col-3 ps-3 sticky-column">
            <p className="table__text__black mb-2 pt-3">
              Class {classIndex[index]} Shares
            </p>
            <p className="table__text__primary_1">
              ${classAmounts[index] && numberWithCommas(classAmounts[index])} | {parseFloat(cl).toFixed()}%
            </p>
          </td>
          <td className="col text-center table__text__primary_2">
            <p className="pt-4">
              ${price_per_unit[index] && numberWithCommas(price_per_unit[index].data[0].value)}
            </p>
          </td>
          <td className="col text-center table__text__primary_2">
            <p className="pt-4">
              ${price_per_sf[index] && parseFloat(price_per_sf[index].data[0].value).toFixed(2)}
            </p>
          </td>
          <td className="col text-center table__text__primary_2">
            <p className="pt-4">
              {entry_cap_rate[index] && parseFloat(entry_cap_rate[index].data[0].value).toFixed(2) + '%'}
            </p>
          </td>
          <td className="col text-center table__text__primary_2">
            <p className="pt-4">
              {stabilized_noi[index] && parseFloat(stabilized_noi[index].data[0].value).toFixed(2) + '%'}
            </p>
          </td>
          <td className="col text-center table__text__primary_2">
            <p className="pt-4">
              {proforma_noi[index] && parseFloat(proforma_noi[index].data[0].value).toFixed(2) + '%'}
            </p>
          </td>
        </tr>
      </React.Fragment>
    ));
  };

  const renderInvestmentTerms = () => {
    return mapValue.map((cl, index) => {
      const LpClassA = shares?.lp[index] ? parseFloat(shares?.lp[index]?.data[0]?.value).toFixed(1) : 0;
      const sponsorClassA = shares?.sponsor[index] ? parseFloat(shares?.sponsor[index]?.data[0]?.value).toFixed(1) : 0;
      const servicedClassA = shares?.serviced[index] ? parseFloat(shares?.serviced[index]?.data[0]?.value).toFixed(1) : 0;
      const accurdClassA = shares?.accrued[index] ? parseFloat(shares?.accrued[index]?.data[0]?.value).toFixed(1) : 0;

      return (
        <React.Fragment key={index}>
          <div className="ps-2 pr-3 tborder-div">
            <div className="tborder1"></div>
          </div>
          <tr className="row p-0 m-0 invesment__table__tr">
            <td className="col-3 ps-3 sticky-column">
              <p className="table__text__black mb-2 pt-3">
                Class {classIndex[index]} Shares
              </p>
              <p className="table__text__primary_1">
                ${classAmounts[index] && numberWithCommas(classAmounts[index])} | {parseFloat(cl).toFixed()}%
              </p>
            </td>
            <td className="col text-center table__text__primary_2">
              <p className="pt-4">
                {preferred_return[index] && parseFloat(preferred_return[index].data[0].value).toFixed(1) + '%'}
              </p>
            </td>
            <td className="col text-center table__text__primary_2">
              <p className="pt-4">
                {preferred_return[index] && `${servicedClassA}% / ${accurdClassA}%`}
              </p>
            </td>
            <td className="col text-center table__text__primary_2">
              <p className="pt-4"></p>
            </td>
            <td className="col text-center table__text__primary_2">
              <p className="pt-4">
                {equity[index] && parseFloat(equity[index].data[0].value).toFixed(1)}%
              </p>
            </td>
            <td className="col text-center table__text__primary_2">
              <p className="pt-4">
                {profit_share_1[index] && numberWithCommas(profit_share_1[index].data[0].value)}%
              </p>
            </td>
            <td className="col text-center table__text__primary_2">
              <p className="pt-4">
                {index === 0 && `${LpClassA}% / ${sponsorClassA}%`}
                {index === 2 && `${LpClassA}% / ${sponsorClassA}%`}
                {index === 1 && `${LpClassA}%`}
                {index === 3 && `${LpClassA}%`}
              </p>
            </td>
          </tr>
        </React.Fragment>
      );
    });
  };

  const renderProjectedReturns = () => {
    return mapValue.map((cl, index) => (
      <React.Fragment key={index}>
        <div className="ps-2 pr-3 tborder-div">
          <div className="tborder1"></div>
        </div>
        <tr className="row p-0 m-0 invesment__table__tr">
          <td className="col-3 ps-3 sticky-column">
            <p className="table__text__black mb-2 pt-3">
              Class {classIndex[index]} Shares
            </p>
            <p className="table__text__primary_1">
              ${classAmounts[index] && numberWithCommas(classAmounts[index])} | {parseFloat(cl).toFixed()}%
            </p>
          </td>
          <td className="col text-center table__text__primary_2">
            <p className="pt-4">
              ${result && result[`total_cashflow_class_${classIndex[index]}`] &&
                numberWithCommas(parseInt(result[`total_cashflow_class_${classIndex[index]}`][0].value).toFixed())}
            </p>
          </td>
          <td className="col text-center table__text__primary_2">
            <p className="pt-4">
              {result && index === 0 && result[`total_cashflow_class_${classIndex[index]}`] && avg_return_class_A}
              {result && index === 1 && result[`total_cashflow_class_${classIndex[index]}`] && avg_return_class_B}
              {result && index === 2 && result[`total_cashflow_class_${classIndex[index]}`] && avg_return_class_C} %
            </p>
          </td>
          <td className="col text-center table__text__primary_2">
            <p className="pt-4">
              {result?.[`irr_class_${classIndex[index]}`][0] && result[`irr_class_${classIndex[index]}`][0].value}%
            </p>
          </td>
          <td className="col text-center table__text__primary_2">
            <p className="pt-4">
              {result?.[`equity_multiple_class_${classIndex[index]}`][0] && (
                <span className={`c_values_class_${classIndex[index]}`}>
                  {result[`equity_multiple_class_${classIndex[index]}`][0].value} X
                </span>
              )}
            </p>
          </td>
        </tr>
      </React.Fragment>
    ));
  };

  return (
    <div className="invesment__table__wapper">
      <table className="invesment__table w-100">
        <thead>
          {renderTableHeader()}
        </thead>
        <tbody>
          {activeButton === "Investment Metrics" && renderInvestmentMetrics()}
          {activeButton === "Investment Terms" && renderInvestmentTerms()}
          {activeButton === "Projected Returns" && renderProjectedReturns()}
        </tbody>
      </table>
    </div>
  );
};

export default InvestmentTable;