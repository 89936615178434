import { useEffect } from "react"
import numberWithCommas from "../../utilities/extrafunction/commaseparate"
import MainClient from "../../utilities/MainClient"
import CapitalResultTable from "../sponsor/CapitalResultTable"
import FinalResultTable from "../sponsor/FinalResultTable"
import ResultTable from "../sponsor/ResultTable"

const FinalResult = ({ data }) => {
    // console.log('final result', data)
    const mapData = data.sum_final_dist_summary.map(dt => dt.value)
    const total = mapData.reduce((a, b) => parseInt(a) + parseInt(b), 0)
    const totalAmount = numberWithCommas(total)
    // console.log('total', total) 
    // final result
    const final_distribution_class_A_year = data.final_dist_summary[0].data.length > 0 ? data.final_dist_summary[0].data.map((dt) =>
        <th className="text-center fw-6 col-1">Year {dt.year}</th>) : ''

    const final_distribution_class_A = data.final_dist_summary[0].data.length > 0 ? data.final_dist_summary[0].data.map((dt) =>
        <td className="text-center col-1">{numberWithCommas(dt.value)}</td>) : ''

    const final_distribution_class_B = data.final_dist_summary[1].data.length > 0 ? data.final_dist_summary[1].data.map((dt) =>
        <td className="text-center col-1">{numberWithCommas(dt.value)}</td>) : ''

    const final_distribution_class_C = data.final_dist_summary[2].data.length > 0 ? data.final_dist_summary[2].data.map((dt) =>
        <td className="text-center col-1">{numberWithCommas(dt.value)}</td>) : ''

    const final_sum_distribution = data.sum_final_dist_summary ? data.sum_final_dist_summary.map((dt) =>
        <td className="text-center col-1">{numberWithCommas(dt.value)}</td>) : ''

    const final_distribution_sponsor = data.final_dist_sponsor_summary ? data.final_dist_sponsor_summary.map((dt) =>
        <td className="text-center col-1">{numberWithCommas(dt.value)}</td>) : ''

    const total_cashflow_sponsor = data.total_cashflow_sponsor[0].value ? numberWithCommas((parseInt(data.total_cashflow_sponsor[0].value).toFixed())) : ''
    const investment_class_A = (data.investment_amount_class_A.length > 0 ? numberWithCommas(data.investment_amount_class_A[0].value) : '')
    const investment_class_B = (data.investment_amount_class_B.length > 0 ? numberWithCommas(data.investment_amount_class_B[0].value) : '')
    const investment_class_C = (data.investment_amount_class_C.length > 0 ? numberWithCommas(data.investment_amount_class_C[0].value) : '')

    const irr_class_A = data.irr_class_A.length > 0 ? data.irr_class_A[0].value : ''
    const irr_class_B = data.irr_class_B.length > 0 ? data.irr_class_B[0].value : ''
    const irr_class_C = data.irr_class_C.length > 0 ? data.irr_class_C[0].value : ''

    const projected_cashflow_class_A = data.projected_cashflow_class_A.length > 0 ? numberWithCommas(data.projected_cashflow_class_A[0].value) : ''
    const projected_cashflow_class_B = data.projected_cashflow_class_B.length > 0 ? numberWithCommas(data.projected_cashflow_class_B[0].value) : ''
    const projected_cashflow_class_C = data.projected_cashflow_class_C.length > 0 ? numberWithCommas(data.projected_cashflow_class_C[0].value) : ''

    const sum_total_investment = data.sum_total_investment.length > 0 ? numberWithCommas(data.sum_total_investment[0].value) : ''

    const final_data = {
        "class_A_year": final_distribution_class_A_year,
        "class_A": final_distribution_class_A,
        "class_B": final_distribution_class_B,
        "class_C": final_distribution_class_C,
        "sponsor": final_distribution_sponsor,
        "total_cashflow_sponsor": total_cashflow_sponsor,
        "total": final_sum_distribution,
        "irr_class_A": irr_class_A,
        "irr_class_B": irr_class_B,
        "irr_class_C": irr_class_C,
        "investment_class_A": investment_class_A,
        "investment_class_B": investment_class_B,
        "investment_class_C": investment_class_C,
        "projected_cashflow_class_A": projected_cashflow_class_A,
        "projected_cashflow_class_B": projected_cashflow_class_B,
        "projected_cashflow_class_C": projected_cashflow_class_C,
        "total_investment": sum_total_investment,
        "totalAmount": totalAmount
    }

    return (
        <div className="mb-3">
            <div className="col-12">
                <div className="card ">
                    <div className="card-body">
                        <div className='data-header'>
                            <div className="d-flex justify-content-between align-items-center">
                                <h2 className='mb-0 fs-18 fw-6 text-black'>Distributions</h2>
                                {/* <h4 className="mb-0 fs-14 ">Total Capitalization : <span
                                    className="text-black fw-bold">${totalCapital ? numberWithCommas(totalCapital) : 0}</span> </h4> */}

                            </div>
                        </div>
                        <div className="data-body sponser-data-body mt-3" style={{ fontFamily: "Poppins" }}>
                            {/* <div className="pb-2 fs-16 fw-6">Pref Return</div> */}
                            <FinalResultTable data={final_data} />
                            {/* {toString(data.result1)} */}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default FinalResult