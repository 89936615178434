import { useEffect, useState } from "react"
import addTotal from "../../utilities/extrafunction/addTotal"
import numberWithCommas from "../../utilities/extrafunction/commaseparate"
import MainClient from "../../utilities/MainClient"
import ResultTable from "../sponsor/ResultTable"
// import ResultTable from "../sponsor/ResultTable"

const Result = ({ data, opData }) => {
    const [len, setLen] = useState(1)
    console.log('resultData', data)
    console.log('OP Data is..', opData)

    // useEffect(()=>{
    //     // let dataLen = data.prefered_return_distribution_class_A.length
    //     // if(dataLen == 5){
    //     //     setLen(3)
    //     // }
    //     // else{
    //     //     setLen(((10 -  dataLen)/dataLen).toFixed())
    //     // }
    // },[])
    useEffect(() => {
        let dataLen = data.tier_1_distribution[0].data.length
        if (dataLen < 5) {
            setLen(3)
        }
        else if ((dataLen < 8)) {
            setLen(2)
        }
        else {
            setLen(1)
        }
    }, []);

    const tier1 = opData[0]?.tier
    const tier2 = opData[1]?.tier
    const tier3 = opData[2]?.tier

    // pref return
    const pref_ret_class_A_year = data.tier_1_distribution[0].data.map((dt) =>
        <th className={`text-center col-1`}>Year {dt.year}</th>)
    const pref_ret_class_A_value = data.tier_1_distribution[0].data.map((dt) =>
        <td className={`text-center col-1`}>{numberWithCommas(dt.value)}</td>)

    const pref_ret_class_B_value = data.tier_1_distribution[1].data.map((dt) =>
        <td className={`text-center col-1`}>{numberWithCommas(dt.value)}</td>)

    const pref_ret_class_C_value = data.tier_1_distribution[2].data.map((dt) =>
        <td className={`text-center col-1`}>{numberWithCommas(dt.value)}</td>)

    const total_tier_1_distribution = data.total_tier_1_distribution_op.map((dt) =>
        <td className={`text-center col-1`}>{numberWithCommas(dt.value)}</td>)

    const cashflow_rem_dist_tier_1 = data.cashflow_remaining_tier_1_op.map((dt) =>
        <td className={`text-center col-1`}>{numberWithCommas(dt.value)}</td>)

    const classAPref = data?.prefered_percentage_class_A[0] ? data?.prefered_percentage_class_A[0].value : 0
    const classBPref = data?.prefered_percentage_class_B[0] ? data?.prefered_percentage_class_B[0].value : 0
    const classCPref = data?.prefered_percentage_class_C[0] ? data?.prefered_percentage_class_C[0].value : 0
    const totalPref = (parseFloat(classAPref) + parseFloat(classBPref) + parseFloat(classCPref)).toFixed(1)

    const pref_ret_data = {
        "tier": tier1,
        "class_A_year": pref_ret_class_A_year,
        "class_A": pref_ret_class_A_value,
        "class_B": pref_ret_class_B_value,
        "class_C": pref_ret_class_C_value,
        "total": total_tier_1_distribution,
        "rem": cashflow_rem_dist_tier_1,
        'class_A_Total': addTotal(data.tier_1_distribution[0].data),
        'class_B_Total': addTotal(data.tier_1_distribution[1].data),
        'class_C_Total': addTotal(data.tier_1_distribution[2].data),
        'tTotal': addTotal(data.total_tier_1_distribution_op),
        'remTotal': addTotal(data.cashflow_remaining_tier_1_op),
        'prefered_percentage_class_A': data?.prefered_percentage_class_A[0] ? data?.prefered_percentage_class_A[0].value : '',
        'prefered_percentage_class_B': data?.prefered_percentage_class_B[0] ? data?.prefered_percentage_class_B[0].value : '',
        'prefered_percentage_class_C': data?.prefered_percentage_class_C[0] ? data?.prefered_percentage_class_C[0].value : '',
        'totalPref': totalPref
    }

    // return of Capital Outstanding
    const return_capital_outstanding_class_A = data.tier_2_distribution[0] ? data.tier_2_distribution[0].data.map((dt) =>
        <td className={`text-center col-1`}>{numberWithCommas(dt.value)}</td>) : ''
    const return_capital_outstanding_class_B = data.tier_2_distribution[1] ? data.tier_2_distribution[1].data.map((dt) =>
        <td className={`text-center col-1`}>{numberWithCommas(dt.value)}</td>) : ''

    const return_capital_outstanding_class_C = data.tier_2_distribution[2] ? data.tier_2_distribution[2].data.map((dt) =>
        <td className={`text-center col-1`}>{numberWithCommas(dt.value)}</td>) : ''

    const total_return_capital_outstanding = data.total_tier_2_distribution_op ? data.total_tier_2_distribution_op.map((dt) =>
        <td className={`text-center col-1`}>{numberWithCommas(dt.value)}</td>) : ''

    const cashflow_rem_dist_tier_2 = data.cashflow_remaining_tier_2_op ? data.cashflow_remaining_tier_2_op.map((dt) =>
        <td className={`text-center col-1`}>{numberWithCommas(dt.value)}</td>) : ''

    const tier_2_sponsor_op = data.sponsor_tier_2_op ? data.sponsor_tier_2_op.map((dt) =>
        <td className={`text-center col-1`}>{numberWithCommas(dt.value)}</td>) : ''

    const return_capital_outstanding = {
        "tier": tier2,
        "class_A_year": pref_ret_class_A_year,
        "class_A": return_capital_outstanding_class_A,
        "class_B": return_capital_outstanding_class_B,
        "class_C": return_capital_outstanding_class_C,
        "total": total_return_capital_outstanding,
        "rem": cashflow_rem_dist_tier_2,
        "sponsor": tier_2_sponsor_op,
        'class_A_Total': addTotal(data.tier_2_distribution[0].data),
        'class_B_Total': addTotal(data.tier_2_distribution[1].data),
        'class_C_Total': addTotal(data.tier_2_distribution[2].data),
        'tTotal': addTotal(data.total_tier_2_distribution_op),
        'remTotal': addTotal(data.cashflow_remaining_tier_2_op)
    }

    // Pro Rata Split
    const tier_3_dist__class_A = data.tier_3_distribution ? data.tier_3_distribution[0].data.map((dt) =>
        <td className={`text-center col-1`}>{numberWithCommas(dt.value)}</td>) : ''

    const tier_3_dist__class_B = data.tier_3_distribution ? data.tier_3_distribution[1].data.map((dt) =>
        <td className={`text-center col-1`}>{numberWithCommas(dt.value)}</td>) : ''

    const tier_3_dist__class_C = data.tier_3_distribution ? data.tier_3_distribution[2].data.map((dt) =>
        <td className={`text-center col-1`}>{numberWithCommas(dt.value)}</td>) : ''

    const total_tier_3_distribution = data.total_tier_3_distribution_op ? data.total_tier_3_distribution_op.map((dt) =>
        <td className={`text-center col-1`}>{numberWithCommas(dt.value)}</td>) : ''
    const tier_3_sponsor = data.sponsor_tier_3_op ? data.sponsor_tier_3_op.map((dt) =>
        <td className={`text-center col-1`}>{numberWithCommas(dt.value)}</td>) : ''

    const equity_percentage_class_A = data.equity_percentage_class_A_op.length > 0 ? data.equity_percentage_class_A_op[0].value : ''
    const equity_percentage_class_B = data.equity_percentage_class_B_op.length > 0 ? data.equity_percentage_class_B_op[0].value : ''
    const equity_percentage_class_C = data.equity_percentage_class_C_op.length > 0 ? data.equity_percentage_class_C_op[0].value : ''

    const tier_3_dist = {
        "tier": tier3,
        "class_A_year": pref_ret_class_A_year,
        "class_A": tier_3_dist__class_A,
        "class_B": tier_3_dist__class_B,
        "class_C": tier_3_dist__class_C,
        "total": total_tier_3_distribution,
        "sponsor": tier_3_sponsor,
        'class_A_Total': data.tier_3_distribution ? addTotal(data.tier_3_distribution[0].data) : 0,
        'class_B_Total': data.tier_3_distribution ? addTotal(data.tier_3_distribution[1].data) : 0,
        'class_C_Total': data.tier_3_distribution ? addTotal(data.tier_3_distribution[2].data) : 0,
        'tTotal': data.total_tier_3_distribution_op ? addTotal(data.total_tier_3_distribution_op) : 0,
        'sponsorTotal': data.total_tier_3_distribution_op ? addTotal(data.sponsor_tier_3_op) : 0,
        "class_A_equity": equity_percentage_class_A,
        "class_B_equity": equity_percentage_class_B,
        "class_C_equity": equity_percentage_class_C,
        "sponsor_profit_share":  data.sponsor_profit_share[0] ? data.sponsor_profit_share[0].value : '' 
    }

    // Total Distribution

    const total_dist_case_class_A = data.total_distribution_case_op[0] ? data.total_distribution_case_op[0].data.map((dt) =>
        <td className={`text-center col-1`}>{numberWithCommas(dt.value)}</td>) : ''

    const total_dist_case_class_B = data.total_distribution_case_op[1] ? data.total_distribution_case_op[1].data.map((dt) =>
        <td className={`text-center col-1`}>{numberWithCommas(dt.value)}</td>) : ''

    const total_dist_case_class_C = data.total_distribution_case_op[2] ? data.total_distribution_case_op[2].data.map((dt) =>
        <td className={`text-center col-1`}>{numberWithCommas(dt.value)}</td>) : ''

    const total_dist_case_sponsor = data.total_dist_case_sponsor_op.map((dt) =>
        <td className={`text-center col-1`}>{numberWithCommas(dt.value)}</td>)

    const sum_total_dist_case = data.sum_total_dist_case_op.map((dt) =>
        <td className={`text-center col-1`}>{numberWithCommas(dt.value)}</td>)

    const total_dist_case = {
        "tier": "",
        "class_A_year": pref_ret_class_A_year,
        "class_A": total_dist_case_class_A,
        "class_B": total_dist_case_class_B,
        "class_C": total_dist_case_class_C,
        "total": sum_total_dist_case,
        "sponsor": total_dist_case_sponsor,
        'class_A_Total': addTotal(data.total_distribution_case_op[0].data),
        'class_B_Total': addTotal(data.total_distribution_case_op[1].data),
        'class_C_Total': addTotal(data.total_distribution_case_op[2].data),
        'tTotal': addTotal(data.sum_total_dist_case_op),
        'sponsorTotal': addTotal(data.total_dist_case_sponsor_op)
    }

    return (
        <div className="mb-3" style={{ fontFamily: "Poppins", color: "#404756" }}>
            <div className="col-12">
                <div className="">
                    <div className="card-body">
                        <div className=''>
                            <div className="d-flex justify-content-between align-items-center">
                                <h2 className='mb-0 fs-18 fw-6 text-black'>Operating Cash Flow</h2>
                                {/* <h4 className="mb-0 fs-14 ">Total Capitalization : <span
                                    className="text-black fw-bold">${totalCapital ? numberWithCommas(totalCapital) : 0}</span> </h4> */}
                            </div>
                        </div>
                        <div className="data-body sponser-data-body mt-3">
                            <div className="pb-2 fs-16 fw-4">{tier1}</div>
                            <ResultTable data={pref_ret_data} />
                            {data.tier_2_distribution &&
                                <div>
                                    <div className="pb-2 fs-16 fw-4 mt-3 mb-2">{tier2}</div>
                                    {/* <ResultTable data={capital_outstanding} /> */}
                                    {/* <ResultTable data={percentage_capital_outstanding} /> */}
                                    <ResultTable data={return_capital_outstanding} />
                                </div>}
                            {data.tier_3_distribution &&
                                <div className="pb-2 fs-16 fw-4 mt-3 mb-2">{tier3}</div>}
                            {/* {data.tier_2_dist_class_A && <ResultTable data={tier_2_dist} />} */}
                            {data.tier_3_distribution && <ResultTable data={tier_3_dist} />}
                            <div className="pb-2 fs-16 fw-4 mt-3 mb-2">Total Cash Flow</div>
                            <ResultTable data={total_dist_case} />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Result