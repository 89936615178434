import { useEffect, useState } from 'react';
import { NavLink, useParams, matchRoutes, useLocation } from 'react-router-dom';
import MenuIcon from '../../assets/images/menu.svg';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import DashboardWhite from '../../assets/icons/dashboardWhite.png';
import DashboardBlack from '../../assets/icons/dashboardBlack.png';
import CreateDealWhite from '../../assets/icons/craeteDealWhite.png';
import CreateDealBlack from '../../assets/icons/createDealBlack.png';
import InvestorWhite from '../../assets/icons/investorIconWhite.png';
import InvestorBlack from '../../assets/icons/investorIconBlack.png';
import DealWhite from '../../assets/icons/dealIconWhite.png';
import DealBlack from '../../assets/icons/dealIconBlack.png';
import SponsorWhite from '../../assets/images/SponsorWhite.png';
import SponsorBlack from '../../assets/images/SponsorBlack.png';
import MblueLogo from '../../assets/logos/Mobile Icon blue.png';
import IpLg from '../../assets/logos/Ip Logo.png';
import MbLg from '../../assets/logos/Logo Mb.png';
import { RiDashboardFill } from 'react-icons/ri';
import GridViewIcon from '@mui/icons-material/GridView';
import styled from 'styled-components';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useSelector } from 'react-redux';
import NavElement from './NavElement';

const Ul = styled.ul`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  // padding-right: 0.2rem;
  margin-top: 20px;
`;

const MobileSidebar = ({ expand }) => {
  const user = useSelector(state => state.user.userData)
  return (
    <>
      <Offcanvas.Header closeButton className='me-2 pb-1 pt-1 mt-3'>
        <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
          <NavLink to='/'>
            <div class='xp-logo'>
              {/* {tg ? ( */}
              {/* <img src={MblueLogo} style={{ maxWidth: "30px" }} /> */}
             
              <div className='ms-1 ipad-logo'>
                <img src="https://firebasestorage.googleapis.com/v0/b/stack-share-752fe.appspot.com/o/icon%2Fstackshare%20logo.png?alt=media&token=23fea7a6-8ab0-44e8-b7cc-3d77c7a27a79" style={{ width: '94%' }} />
              </div>
              <div className='mobile-logo p-0 text-center mt-0 pt-0'>
                <img src="https://firebasestorage.googleapis.com/v0/b/stack-share-752fe.appspot.com/o/icon%2FlogoMb.png?alt=media&token=2be46fe0-a55d-4c4b-9608-2bd4bd278b2f" style={{ maxWidth: "40%" }} />
              </div>
              {/* <span class="toggle-span">ackShares <sup>TM</sup></span> */}
            </div>
          </NavLink>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <div>
        <Ul>
          {/* <div style={{ height:"100px" }}> */}
          {(user?.user_type === "investor" || user?.user_type == "admin") && (
            <NavElement url='/dashboard/main' iconBlack={DashboardBlack} iconWhite={DashboardWhite} title="Dashboard" />
          )}
          {/* </div> */}
          {(user?.user_type === "sponsor" || user?.user_type == "admin") && (
            <NavElement url='/dashboard/add-new-deal' iconBlack={CreateDealBlack} iconWhite={CreateDealWhite} title="Add New Deal" />

          )}
          {(user?.user_type === "sponsor" || user?.user_type == "admin") && (
            <NavElement url='/dashboard/my-investors' iconBlack={InvestorBlack} iconWhite={InvestorWhite} title="Investors" />
          )}
          {(user?.user_type == "admin") && (
            <NavElement url='/dashboard/sponsors' iconBlack={SponsorBlack} iconWhite={SponsorWhite} title="Sponsors" />
          )}
          {(user?.user_type === "sponsor" || user?.user_type == "admin") && (
            <NavElement url='/dashboard/deals' iconBlack={DealBlack} iconWhite={DealWhite} title="Deals" />
          )}
        </Ul>
      </div>
    </>
  );
};

export default MobileSidebar;
