import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from "use-places-autocomplete";
import {
    Combobox,
    ComboboxInput,
    ComboboxPopover,
    ComboboxList,
    ComboboxOption,
} from "@reach/combobox";
import styled from "styled-components";

const LiList = styled(ComboboxList)`
    list-style-type: none;
    width: 100%;
    border: ${props => props.len > 1 ? "1px solid #ddd" : ''};
    padding: 0;
    background : #FFFFFF;
    box-shadow: 1px 2px 2px  #ddd;
`
const LiOption = styled(ComboboxOption)`
    padding: 5px 10px;
    cursor: pointer;
    &:hover{
        background: #ddd;
    }
`

function Search({ panTo, property, setProperty,  getLocation }) {
    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            location: { lat: () => 43.6532, lng: () => -79.3832 },
            radius: 100 * 1000,
        },
    });

    const handleInput = (e) => {
        setValue(e.target.value);
        setProperty({ ...property, address: e.target.value })
    };

    const handleSelect = async (address) => {
        // setValue(address, false);
        const addList = address.split(',')
        const len = addList.length
        let sliceAdd = ''
        let city = ''
        let state = ''
        let addSrting = ''
        if (len == 3) {
            sliceAdd = addList[0]
            city = addList[0]
            state = addList[1]
            addSrting = sliceAdd.toString()

        } else {
            sliceAdd = addList.slice(0, len - 3)
            city = addList[len - 3]
            state = addList[len - 2]
            addSrting = sliceAdd.toString()
        }
        console.log('address0', addList)
        console.log('slice', addList, sliceAdd, addList, addSrting)
        getLocation(address,city)
        setProperty({ ...property, state: state, city: city, address: addSrting })
        clearSuggestions();
    };

    return (
        <div className="search">
            <Combobox onSelect={handleSelect}>
                <ComboboxInput
                    value={property.address}
                    onChange={handleInput}
                    disabled={!ready}
                    placeholder="Enter Property Address"
                    className="form-control"
                />
                <ComboboxPopover>
                    <LiList len={data?.length}>
                        {status === "OK" &&
                            data.map(({ id, description }) => (
                                <LiOption key={id} value={description} />
                            ))}
                    </LiList>
                </ComboboxPopover>
            </Combobox>
        </div>
    );
}

export default Search